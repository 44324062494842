<template>
  <div>
    <MessageDisplay
      ref="messageComponent"
    />
    <ReusableModal
      :isVisible="showModalBox"
      @confirm="deleteRecord"
      @cancel="cancelDelete"
      :Modaltext= "modaltext"
    >
    </ReusableModal>

    <opht-list
      @ShowOphtVisitForm="ActiveOphtPhysicianVisit"
      v-if="ShowPatientList"
       @ShowMsgBox="showMsgBox" @ShowModal="ShowModal" :confirmDel="confirmDelete" @resetDelete="resetConfirmDel"
    ></opht-list>
    <opht-physician-visit
      v-if="ShowOphtPhysicianVisit"
      @PatientListForm="ActivePatientList"
    ></opht-physician-visit>
  </div>
</template>

<script>
import OphtPhysicianVisit from "./OphtPysicianVisit.vue";
import OphtList from "./OphtList.vue";

import ReusableModal from "../../../components/UI/TheModal.vue";

export default {
  components: {
    ReusableModal,
    OphtList,
    OphtPhysicianVisit,
  },
  data() {
    return {
      ShowPatientList: true,
      ShowOphtPhysicianVisit: false,

      showModalBox: false,
      confirmDelete: false,
      isVisible: false,
      modaltext: 'اطلاعات ویزیت حذف خواهد شد!',
    };
  },
  methods: {
    showMsgBox(payload) {
      if (payload == true) {
        this.$refs.messageComponent.showMessage(204)
      } else {
        this.$refs.messageComponent.showMessage(400)
      }
    },

    ActivePatientList() {
      this.ShowPatientList = true;
      this.ShowOphtPhysicianVisit = false;
    },
    ActiveOphtPhysicianVisit() {
      this.ShowPatientList = false;
      this.ShowOphtPhysicianVisit = true;
    },

    ShowModal() {
      this.showModalBox = true;
    },

    deleteRecord() {
      this.confirmDelete = true;
      this.showModalBox = false;
    },
    resetConfirmDel() {
      this.confirmDelete = false;
    },
    cancelDelete() {
      this.showModalBox = false;
    },
  },
};
</script>
