<template>
  <Message-Display ref="messageComponent" />
  <the-snippet v-if="showSnippet" />
  <div v-else class="content slide-in">
    <Patient-Info />
    <div>
      <div class="data-entry-form">
        <div class="section-lbl">
          <label>OD-ROP</label>
          <div class="form-group">
            <label class="toggle-switchy" for="od-eye" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="red">
              <input checked type="checkbox" id="od-eye" v-model="formData.ODRop" />
              <span class="toggle">
                <span class="switch"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="data-section slide-in" v-if="formData.ODRop">
          <div class="RowDir">
            <div class="form-group">
              <label for="OD-Stage">Stage:</label>
              <select id="OD-Stage" v-model="formData.ODStage">
                <option v-for="(option, index) in options.StageOpts" :value="option" :key="index">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="OD-Zone">Zone:</label>
              <select id="OD-Zone" v-model="formData.ODZone">
                <option v-for="option in options.ZoneOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="OD-plus-disease"
                data-size="md"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="red">
                <input type="checkbox" id="OD-plus-disease" v-model="Select.ODPlusDisease" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">Plus disease:</span>
              </label>
              <select
                v-if="Select.ODPlusDisease"
                id="OD-plus-disease"
                v-model="formData.ODPlusDisease"
                @click="removeErrorMsg('ODPlusDisease')"
                :class="{ errorBorder: messages.errors.ODPlusDisease }">
                <option value="" disabled>انتخاب کنید</option>
                <option v-for="option in options.PlusDiseasesOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.ODPlusDisease">{{ messages.errors.ODPlusDisease }}</span>
            </div>

            <div class="form-group">
              <label
                class="toggle-switchy"
                for="OD-threshold-disease"
                data-size="md"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="blue">
                <input type="checkbox" id="OD-threshold-disease" v-model="Select.ODThresholdDisease" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">Threshold disease:</span>
              </label>
              <select
                v-if="Select.ODThresholdDisease"
                id="OD-threshold-disease"
                v-model="formData.ODThresholdDisease"
                @click="removeErrorMsg('ODThresholdDisease')"
                :class="{ errorBorder: messages.errors.ODThresholdDisease }">
                <option value="" disabled>انتخاب کنید</option>
                <option v-for="option in options.ThresholdDiseasesOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.ODThresholdDisease">{{ messages.errors.ODThresholdDisease }}</span>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="OD-ConjusctivitiesIn"
                data-size="md"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="purple">
                <input type="checkbox" id="OD-ConjusctivitiesIn" v-model="Select.ODConjusctivitiesIn" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">Conjusctivities in:</span>
              </label>
              <select
                v-if="Select.ODConjusctivitiesIn"
                id="OD-ConjusctivitiesIn"
                v-model="formData.ODConjusctivitiesIn"
                @click="removeErrorMsg('ODConjusctivitiesIn')"
                :class="{ errorBorder: messages.errors.ODConjusctivitiesIn }">
                <option value="" disabled>انتخاب کنید</option>
                <option v-for="option in options.ConjusctivitiesInsOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.ODConjusctivitiesIn">{{ messages.errors.ODConjusctivitiesIn }}</span>
            </div>

            <div class="form-group">
              <label
                class="toggle-switchy"
                for="OD-ConjusctivitiesOut"
                data-size="md"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="gray">
                <input type="checkbox" id="OD-ConjusctivitiesOut" v-model="Select.ODConjusctivitiesOut" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">Conjusctivities-out:</span>
              </label>
              <select
                v-if="Select.ODConjusctivitiesOut"
                id="OD-ConjusctivitiesOut"
                v-model="formData.ODConjusctivitiesOut"
                @click="removeErrorMsg('ODConjusctivitiesOut')"
                :class="{ errorBorder: messages.errors.ODConjusctivitiesOut }">
                <option value="" disabled>انتخاب کنید</option>
                <option v-for="option in options.ConjusctivitiesOutsOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.ODConjusctivitiesOut">{{ messages.errors.ODConjusctivitiesOut }}</span>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <label for="OD-recommendation">Recommendation:</label>
              <select id="OD-recommendation" v-model="formData.ODRecommendations">
                <option v-for="option in options.RecommendationsOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="Other Findings">Other Findings:</label>
              <textarea id="ODOtherFindings" class="Findings" v-model="formData.ODOtherFindings" />
              <!-- <button class="btn btn-outline-primary btn-sm me-2">+</button> -->
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="data-entry-form">
        <div class="section-lbl">
          <label>OS-ROP</label>
          <div class="form-group">
            <label class="toggle-switchy" for="os-eye" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="purple">
              <input checked type="checkbox" id="os-eye" v-model="formData.OSRop" />
              <span class="toggle">
                <span class="switch"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="data-section slide-in" v-if="formData.OSRop">
          <div class="data-section">
            <div class="RowDir">
              <div class="form-group">
                <label for="OS-Stage">Stage:</label>
                <select id="OS-Stage" v-model="formData.OSStage">
                  <option v-for="option in options.StageOpts" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="OS-Zone">Zone:</label>
                <select id="OS-Zone" v-model="formData.OSZone">
                  <option v-for="option in options.ZoneOpts" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>

            <div class="RowDir">
              <div class="form-group">
                <label
                  class="toggle-switchy"
                  for="OS-plus-disease"
                  data-size="md"
                  data-style="Rounded"
                  data-text="true"
                  data-label="left"
                  data-color="red">
                  <input type="checkbox" id="OS-plus-disease" v-model="Select.OSPlusDisease" />
                  <span class="toggle">
                    <span class="switch"></span>
                  </span>
                  <span class="label">Plus disease:</span>
                </label>
                <select
                  v-if="Select.OSPlusDisease"
                  id="OS-plus-disease"
                  v-model="formData.OSPlusDisease"
                  @click="removeErrorMsg('OSPlusDisease')"
                  :class="{ errorBorder: messages.errors.OSPlusDisease }">
                  <option value="" disabled>انتخاب کنید</option>
                  <option v-for="option in options.PlusDiseasesOpts" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
                <span class="error-message" v-if="messages.errors.OSPlusDisease">{{ messages.errors.OSPlusDisease }}</span>
              </div>

              <div class="form-group">
                <label
                  class="toggle-switchy"
                  for="OS-threshold-disease"
                  data-size="md"
                  data-style="Rounded"
                  data-text="true"
                  data-label="left"
                  data-color="blue">
                  <input type="checkbox" id="OS-threshold-disease" v-model="Select.OSThresholdDisease" />
                  <span class="toggle">
                    <span class="switch"></span>
                  </span>
                  <span class="label">Threshold Disease:</span>
                </label>
                <select
                  v-if="Select.OSThresholdDisease"
                  id="OS-threshold-disease"
                  v-model="formData.OSThresholdDisease"
                  @click="removeErrorMsg('ODThresholdDisease')"
                  :class="{ errorBorder: messages.errors.OSThresholdDisease }">
                  <option value="" disabled>انتخاب کنید</option>
                  <option v-for="option in options.ThresholdDiseasesOpts" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
                <span class="error-message" v-if="messages.errors.OSThresholdDisease">{{ messages.errors.OSThresholdDisease }}</span>
              </div>
            </div>

            <div class="RowDir">
              <div class="form-group">
                <label
                  class="toggle-switchy"
                  for="OS-ConjusctivitiesIn"
                  data-size="md"
                  data-style="Rounded"
                  data-text="true"
                  data-label="left"
                  data-color="purple">
                  <input type="checkbox" id="OS-ConjusctivitiesIn" v-model="Select.OSConjusctivitiesIn" />
                  <span class="toggle">
                    <span class="switch"></span>
                  </span>
                  <span class="label">Conjusctivities-in:</span>
                </label>
                <select
                  v-if="Select.OSConjusctivitiesIn"
                  id="OS-ConjusctivitiesIn"
                  v-model="formData.OSConjusctivitiesIn"
                  @click="removeErrorMsg('OSConjusctivitiesIn')"
                  :class="{ errorBorder: messages.errors.OSConjusctivitiesIn }">
                  <option value="" disabled>انتخاب کنید</option>
                  <option v-for="option in options.ConjusctivitiesInsOpts" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
                <span class="error-message" v-if="messages.errors.OSConjusctivitiesIn">{{ messages.errors.OSConjusctivitiesIn }}</span>
              </div>

              <div class="form-group">
                <label
                  class="toggle-switchy"
                  for="OS-ConjusctivitiesOut"
                  data-size="md"
                  data-style="Rounded"
                  data-text="true"
                  data-label="left"
                  data-color="gray">
                  <input type="checkbox" id="OS-ConjusctivitiesOut" v-model="Select.OSConjusctivitiesOut" />
                  <span class="toggle">
                    <span class="switch"></span>
                  </span>
                  <span class="label">Conjusctivities-out:</span>
                </label>
                <select
                  v-if="Select.OSConjusctivitiesOut"
                  id="OS-ConjusctivitiesOut"
                  v-model="formData.OSConjusctivitiesOut"
                  @click="removeErrorMsg('OSConjusctivitiesOut')"
                  :class="{ errorBorder: messages.errors.OSConjusctivitiesOut }">
                  <option value="" disabled>انتخاب کنید</option>
                  <option v-for="option in options.ConjusctivitiesOutsOpts" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
                <span class="error-message" v-if="messages.errors.OSConjusctivitiesOut">{{ messages.errors.OSConjusctivitiesOut }}</span>
              </div>
            </div>
            <div class="RowDir">
              <div class="form-group">
                <label for="OS-recommendation">Recommendation:</label>
                <select id="OS-recommendation" v-model="formData.OSRecommendations">
                  <option v-for="option in options.RecommendationsOpts" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="Other Findings">Other Findings:</label>
                <textarea id="OSOtherFindings" class="Findings" v-model="formData.OSOtherFindings" />
                <!-- <button class="btn btn-outline-primary btn-sm me-2">+</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="data-entry-form">
        <lable class="section-lbl">نوبت</lable>
        <div class="data-section">
          <div class="form-group">
            <label for="Next-visit">مراجعه بعدی:</label>
            <select id="Next-visit" v-model="formData.AppointmentLabel" style="width: 150px" @change="updateDateTime">
              <option v-for="option in options.NextVisit" :value="option.value" :key="option">
                {{ option.label }}
              </option>
            </select>
            <span @click="clearDate" v-show="formData.AppointmentLabel && !DayDate" style="margin-right: -40px; opacity: 0.5; font-size: 7pt">✖</span>

            <label class="me-5">
              روز: <span style="font-weight: bold; color: blue; font-size: 12pt">{{ `${DayLabl} - ` }} </span>
            </label>
            <label for="discharge-date">تاریخ:</label>
            <Persian-Date
              :auto-submit="false"
              id="discharge-date"
              v-model="formData.AppointmentDateTime"
              :class="{ errorBorder: messages.errors.AppointmentDateTime }"
              @click="messages.errors.AppointmentDateTime = ''" />
            <span class="error-message" v-if="messages.errors.AppointmentDateTime"> {{ messages.errors.AppointmentDateTime }}</span>
            <button @click="clearDate" class="btn btn-sm btn-outline-danger me-2" v-if="formData.AppointmentDateTime">حذف</button>
          </div>
        </div>
      </div>
      <hr />
      <div class="DataEntryButtons">
        <button type="button" class="btn btn-success me-2" v-if="ShowSubmitBtn" @click="submitForm">ثبت</button>
        <button type="button" class="btn btn-primary me-2" v-if="ShowEditBtn && hasAccess('EditVisitOpht')" @click="editForm">ویرایش</button>
        <button type="button" @click="resetForm" class="btn btn-danger me-2">پاک کردن</button>
        <router-link to="/DataEntry/Ophthalmology" type="button" @click="PatientList" class="btn btn-warning me-2"> لیست بیماران </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../../../Api/TheApi.js";
import jalaali from "jalaali-js";
import permissionMixin from "@/Mixin/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    const initialFormData = () => ({
      ODRop: null,
      ODStage: null,
      ODZone: null,
      ODPlusDisease: null,
      ODThresholdDisease: null,
      ODConjusctivitiesIn: null,
      ODConjusctivitiesOut: null,
      ODRecommendations: null,
      ODOtherFindings: null,
      OSRop: null,
      OSStage: null,
      OSZone: null,
      OSPlusDisease: null,
      OSThresholdDisease: null,
      OSConjusctivitiesIn: null,
      OSConjusctivitiesOut: null,
      OSRecommendations: null,
      OSOtherFindings: null,
      OpthPhysician: null,
      AppointmentLabel: null,
      AppointmentDateTime: null,
      RegisterUser: null,
      UpdateUser: null,
    });

    const initialSelectData = () => ({
      ODPlusDisease: false,
      ODThresholdDisease: false,
      ODConjusctivitiesIn: false,
      ODConjusctivitiesOut: false,
      OSPlusDisease: false,
      OSThresholdDisease: false,
      OSConjusctivitiesIn: false,
      OSConjusctivitiesOut: false
    });

    return {
      formData: { ...initialFormData },
      initialFormData,
      Select: { ...initialSelectData },
      initialSelectData,
      options: {
        StageOpts: [],
        ZoneOpts: [],
        PlusDiseasesOpts: [],
        ThresholdDiseasesOpts: [],
        ConjusctivitiesInsOpts: [],
        ConjusctivitiesOutsOpts: [],
        RecommendationsOpts: [],
        NextVisit: [
          { label: "یک هفته", value: 7 },
          { label: "دو هفته", value: 14 },
          { label: "سه هفته", value: 21 },
          { label: "چهار هفته", value: 28 },
          { label: "یک ماه", value: 30 },
          { label: "دو ماه", value: 60 },
          { label: "سه ماه", value: 90 },
          { label: "شش ماه", value: 180 },
          { label: "یک سال", value: 365 },
          { label: "دو سال", value: 730 }
        ]
      },
      showSnippet: true,
      NextDateLbl: null,
      DayLabl: "",
      DayDate: "",
      messages: { errors: {}, errorsTimesMsg: "انتخاب نشده است" },
      ShowSubmitBtn: true,
      ShowEditBtn: false
    };
  },
  computed: {
    ...mapGetters(["SelectedPatient","userID"])
  },
  mounted() {
    this.fetchItemOptions();
    this.fetchUserInfo();
  },
  methods: {
    PatientList() {
      this.$emit("PatientListForm");
    },

    fetchItemOptions() {
      api
        .get("Ophthalmology/")
        .then(({ data }) => {
          this.options = { ...this.options, ...data };
          this.showSnippet = false;
        })
        .catch(error => {
          this.showSnippet = false;
          this.handleApiError(error);
        });
    },

    async fetchUserInfo() {
      if (!this.SelectedPatient.admissions.AdmissionCode) return;
      this.resetForm();

      try {
        this.showSnippet = true;
        const { data } = await api.get(`ophthalmology-detail/${this.SelectedPatient.admissions.AdmissionCode}/`);

        if (data.AppointmentDateTime && data.AppointmentDateTime != null) {
          this.formData.AppointmentLabel = null;
          this.formData.AppointmentDateTime = null;
          const [jy, jm, jd] = data.AppointmentDateTime.split("-").map(Number);
          const dayDate = `${jy}-${jm}-${jd}`;
          this.DayLabl = this.getDayLabelFromDate(dayDate);
          this.DayDate = `${jd}-${jm}-${jy}`;
        }

        Object.keys(this.Select).forEach(key => {
          this.Select[key] = Boolean(data[`${key}`]);
        });

        Object.keys(this.Select).forEach(key => {
          this.Select[key] = Boolean(data[`${key}`]);
        });
        Object.assign(this.formData, data);
        this.ShowSubmitBtn = false;
        this.ShowEditBtn = true;
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        this.ShowSubmitBtn = true;
        this.ShowEditBtn = false;
      }
    },

    async submitForm() {
      this.resetMessages();
      this.checkTimesErrors();

      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.RegisterUser = this.userID
          this.formData.OpthPhysician = this.SelectedPatient.admissions.opht_physician_name;          
          const { status } = await api.post(`ophthalmology-detail/${this.SelectedPatient.admissions.AdmissionCode}/`, {
            ...this.formData
          });
          this.showSnippet = false;
          this.$refs.messageComponent.showMessage(status);
        } catch (error) {
          this.showSnippet = false;
          this.handleApiError(error);
        }
      }
    },

    async editForm() {
      this.resetMessages();
      this.checkTimesErrors();

      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.UpdateUser = this.userID
          this.formData.OpthPhysician = this.SelectedPatient.admissions.opht_physician_name;
          const { status } = await api.put(`ophthalmology-detail/${this.SelectedPatient.admissions.AdmissionCode}/`, { ...this.formData });
          this.showSnippet = false;
          this.$refs.messageComponent.showMessage(status);
        } catch (error) {
          this.showSnippet = false;
          this.handleApiError(error);
        }
      }
    },

    updateDateTime() {
      const daysToAdd = this.formData.AppointmentLabel;
      if (daysToAdd !== null) {
        const today = jalaali.toJalaali(new Date());
        this.NextDateLbl = this.addDaysToJalaliDate(today, daysToAdd);
      } else {
        this.NextDateLbl = null;
      }
    },
    showMessage(type, state, message) {
      this.messages[`show${type}`] = state;
      this.messages[type] = message;
    },

    checkTimesErrors() {
      this.errors = {};
      const fields = [
        "ODPlusDisease",
        "ODThresholdDisease",
        "ODConjusctivitiesOut",
        "ODConjusctivitiesIn",
        "OSPlusDisease",
        "OSThresholdDisease",
        "OSConjusctivitiesOut",
        "OSConjusctivitiesIn"
      ];
      fields.forEach(field => {
        if (this.Select[field] && !this.formData[field]) {
          this.messages.errors[field] = this.messages.errorsTimesMsg;
        }
      });
      return Object.keys(this.messages.errors).length === 0;
    },

    removeErrorMsg(field) {
      this.messages.errors[field] = "";
    },

    clearFieldIfEmpty(newVal, field) {
      if (!newVal) this.formData[field] = null;
    },
    clearDate() {
      this.formData.AppointmentLabel = null;
      this.formData.AppointmentDateTime = null;
      this.DayDate = null;
      this.DayLabl = null;
    },

    resetForm() {
      this.formData = this.initialFormData();
      this.Select = this.initialSelectData();
      this.clearDate();
      this.resetMessages();
    },
    resetMessages() {
      this.messages.errors = {};
    },
    handleApiError(error) {
      if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
    },
    addDaysToJalaliDate(date, daysToAdd) {
      // Convert Jalali date to Gregorian date
      const gregorianDate = jalaali.toGregorian(date.jy, date.jm, date.jd);
      const gregorianDateObj = new Date(gregorianDate.gy, gregorianDate.gm - 1, gregorianDate.gd);

      // Add days to the Gregorian date
      gregorianDateObj.setDate(gregorianDateObj.getDate() + daysToAdd);

      // Convert back to Jalali date
      const newJalaliDate = jalaali.toJalaali(gregorianDateObj.getFullYear(), gregorianDateObj.getMonth() + 1, gregorianDateObj.getDate());

      return {
        ...newJalaliDate,
        dayName: this.getJalaliDayName(gregorianDateObj.getDay())
      };
    },
    getJalaliDayName(dayIndex) {
      const jalaliDayNames = ["یکشنبه", "دوشنبه", "سه‌شنبه", "چهارشنبه", "پنجشنبه", "جمعه", "شنبه"];
      return jalaliDayNames[dayIndex];
    },
    getDayLabelFromDate(newVal) {
      const [jy, jm, jd] = newVal.split("-").map(Number);
      const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
      const gregorianDate = new Date(gy, gm - 1, gd);
      const dayIndex = gregorianDate.getDay();
      const dayName = this.getJalaliDayName(dayIndex);
      return dayName;
    }
  },

  watch: {
    "formData.ODRop": function (newVal) {
      if (newVal === false) {
        this.formData.ODStage = null;
        this.formData.ODZone = null;
        this.formData.ODPlusDisease = null;
        this.formData.ODThresholdDisease = null;
        this.formData.ODConjusctivitiesIn = null;
        this.formData.ODConjusctivitiesOut = null;
        this.formData.ODRecommendations = null;
        this.formData.ODOtherFindings = null;
        this.Select.ODPlusDisease = false;
        this.Select.ODThresholdDisease = false;
        this.Select.ODConjusctivitiesIn = false;
        this.Select.ODConjusctivitiesOut = false;
      }
    },
    "formData.OSRop": function (newVal) {
      if (newVal === false) {
        this.formData.OSStage = null;
        this.formData.OSZone = null;
        this.formData.OSPlusDisease = null;
        this.formData.OSThresholdDisease = null;
        this.formData.OSConjusctivitiesIn = null;
        this.formData.OSConjusctivitiesOut = null;
        this.formData.OSRecommendations = null;
        this.formData.OSOtherFindings = null;
        this.Select.OSPlusDisease = false;
        this.Select.OSThresholdDisease = false;
        this.Select.OSConjusctivitiesIn = false;
        this.Select.OSConjusctivitiesOut = false;
      }
    },
    "Select.ODPlusDisease": function (newVal) {
      this.clearFieldIfEmpty(newVal, "ODPlusDisease");
    },
    "Select.ODThresholdDisease": function (newVal) {
      this.clearFieldIfEmpty(newVal, "ODThresholdDisease");
    },
    "Select.ODConjusctivitiesIn": function (newVal) {
      this.clearFieldIfEmpty(newVal, "ODConjusctivitiesIn");
    },
    "Select.ODConjusctivitiesOut": function (newVal) {
      this.clearFieldIfEmpty(newVal, "ODConjusctivitiesOut");
    },
    "Select.OSPlusDisease": function (newVal) {
      this.clearFieldIfEmpty(newVal, "OSPlusDisease");
    },
    "Select.OSThresholdDisease": function (newVal) {
      this.clearFieldIfEmpty(newVal, "OSThresholdDisease");
    },
    "Select.OSConjusctivitiesIn": function (newVal) {
      this.clearFieldIfEmpty(newVal, "OSConjusctivitiesIn");
    },
    "Select.OSConjusctivitiesOut": function (newVal) {
      this.clearFieldIfEmpty(newVal, "OSConjusctivitiesOut");
    },
    NextDateLbl: {
      handler(newVal) {
        if (newVal) {
          this.DayLabl = this.NextDateLbl.dayName;
          this.formData.AppointmentDateTime = `${this.NextDateLbl.jy}-${this.NextDateLbl.jm}-${this.NextDateLbl.jd}`;
        }
      }
    },
    "formData.AppointmentDateTime": function (newVal) {
      if (newVal) {
        this.DayLabl = this.getDayLabelFromDate(newVal);
      }
    }
  }
};
</script>

<style scoped>
hr {
  margin: 5px;
  color: blue;
}
.data-entry-form {
  display: flex;
  width: 90vw;
}
.data-section {
  width: 100%;
}
.section-lbl {
  display: flex;
  flex-direction: column;
  min-width: 80px;
  justify-content: center;
  align-items: center;
  color: #0065fd;
  font-size: 10pt;
  font-weight: bold;
  border-left: 1px solid #ccc;
}
.RowDir {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 4px;
  justify-content: right;
  gap: 50px;
}

.form-group {
  display: flex;
  align-items: center;
  text-align: right;
}

label {
  margin-right: 5px;
  word-wrap: break-word;
  text-align: left;
  font-size: 10pt;
}

input,
select {
  min-width: calc(50px + 2em);
  height: 30px;
  padding: 0 5px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  background: #fff;
}
select:focus {
  border: 2px solid #3a47b8bb;
}

.DataEntryButtons {
  display: flex;
  margin-top: 5px;
}
.btn {
  width: 100px;
  padding: 3px;
}
.btn-sm {
  width: 50px;
}
.Findings {
  margin-top: 20px;
  width: 500px;
  height: 60px;
  word-wrap: wrap;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.AppointLabel {
  color: blue;
  font-weight: bold;
  margin-right: 5px;
}
.toggle-switchy > .label {
  min-width: 110px !important;
}
.error-message {
  color: red;
  font-size: 10pt;
  font-weight: bold;
}

.errorBorder {
  border: 2px solid red;
}
</style>
