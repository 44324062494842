// src/mixins/permissionMixin.js

import CryptoJS from "crypto-js";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(['AccessLvl']),
  },
  methods: {
    hashPermission(permission) {
      return CryptoJS.SHA256(permission).toString(CryptoJS.enc.Hex);
    },
    hasAccess(permission) {
      const hashedPermission = this.hashPermission(permission);
      return this.AccessLvl.includes(hashedPermission);
    },
  },
};
