<template>
  <Message-Display ref="messageComponent"/>
  <div class="slide-in">
    <div class="searchBox ">
      <div class="form-group">
        <label>پست سازمانی:</label>
        <input v-model="searchRole" list="rolesList" />
        <datalist id="rolesList">
          <option v-for="role in roleList" :key="role.id" :value="role"></option>
        </datalist>
      </div>
      <button class="btn btn-primary searchBtn" @click="SearchRole">جستجو</button>
    </div>
    <hr />
    <div class="form-group">
      <div class="row">
        <div class="col">
          <label>سطح دسترسی:</label>
          <span v-if="RoleName"
            ><strong class="ms-5"> {{ RoleName }} </strong> <label> برابر است با: </label></span
          >
          <div v-for="(role, index) in AccessLevelList" :key="index" class="radioOption">
            <input type="radio" :id="role" name="role" :value="role" v-model="AccessLvl" />
            <label :for="role">{{ role }}</label>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="newRole">
      <div class="form-group">
        <label>تعریف پست جدید :</label>
        <input v-model="NewRole" />
        <div class="form-group">
          <label class="me-5">سطح دسترسی :</label>
        <input v-model="NewAccessLvl" />  
        </div>    
      </div>
    </div>
    <hr />
    <div class="OperationBtn">
      <button class="btn btn-success" @click="CreateRole">ثبت</button>
      <button class="btn btn-primary" @click="UpdateRole">ویرایش</button>
      <button class="btn btn-danger" @click="DeleteRole">حذف</button>
    </div>
  </div>
</template>

<script>
import api from "../../Api/TheApi.js";

export default {
  data() {
    return {
      searchRole: "",
      RoleName: "",
      NewRole: "",
      NewAccessLvl: "",
      AccessLvl: "",
      roleId: null,
      roleList: [],
      AccessLevelList: [],
    };
  },
  mounted() {
    this.fetchAccessLvlList();
  },
  methods: {
    async fetchAccessLvlList() {
      try {
        const response = await api.get(`/roles`);
        this.roleList = response.data.map((role) => role.role_name);
        const accessLevelNames = new Set(response.data.map((role) => role.access_level_name));
        this.AccessLevelList = Array.from(accessLevelNames);
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async SearchRole() {
      const data = {
        role_name: this.searchRole,
      };
      try {
        const response = await api.get(`/roles`, { params: data });
        if (response.data.length > 0) {
          const role = response.data[0];
          this.roleId = role.id;
          this.RoleName = role.role_name;
          this.AccessLvl = role.access_level_name;
        }
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status)
      }
    },
    async CreateRole() {
      const data = {
        role_name: this.NewRole,
        access_level_name: this.NewAccessLvl,
      };
      try {
        const { status} = await api.post(`/roles`, data);
        if (status===201) {
          this.$refs.messageComponent.showMessage(status)
          this.ResetFields();
        }
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status)
      }
    },
    async UpdateRole() {
      const data = {
        role_id: this.roleId,
        roleName: this.RoleName,
        AccessLvlValue: this.NewAccessLvl,
      };
      try {
        const { status } = await api.put(`/roles`, data);
        this.$refs.messageComponent.showMessage(status);

        this.ResetFields();
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async DeleteRole() {
      const data = {
        role_id: this.roleId,
      };
      try {
        const { status } = await api.delete(`/roles`, { params: data });
        if (status) {
          this.$refs.messageComponent.showMessage(status);
          this.fetchAccessLvlList();
        }

        this.ResetFields();
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    ResetFields() {
      this.RoleName = "";
      this.selectedRole = null;
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
}
.searchBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-group {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
input {
  border-radius: 5px;
  width: 200px;
  height: 40px;
}
label {
  margin-left: 10px;
}
button {
  width: 100px;
  height: 40px;
  margin-left: 10px;
}
.OperationBtn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
h5 {
  margin: 0 50px 0 50px;
}
.col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px;
}
.radioOption input[type="radio"] {
  display: none;
}
.radioOption label {
  width: 120px;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: center;
  text-align: center;
}
.radioOption label:hover {
  background-color: #f0f0f0;
}
.radioOption input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
.radioOption input[type="radio"]:checked + label:hover {
  background-color: #0056b3;
}
</style>
