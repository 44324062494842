<template>
  <Message-Display ref="messageComponent" />
  <the-snippet v-if="showSnippet" />
  <div v-else class="slide-in">
    <Patient-Info />
    <div class="RowDir">
      <div class="form-group">
        <label for="NextAdmission" v-if="!DayDate">نوبت‌دهی:</label>
        <select id="Next-visit" v-model="formData.AppointmentLabel" style="width: 120px" v-if="!DayDate" :class="{ errorBorder: errors.AppointmentDateTime }" @change="updateDateTime">
          <option v-for="option in options.NextVisit" :value="option.value" :key="option">
            {{ option.label }}
          </option>
        </select>
        <span class="error-message" v-if="errors.AppointmentLabel"> {{ errors.AppointmentLabel }}</span>
        <span @click="clearDate" v-show="formData.AppointmentLabel && !DayDate" style="margin-right: -30px; opacity: 0.5">✖</span>
        <div class="form-group">
          <label class="me-5">
            روز: <span>{{ DayLabl }}، {{ DayDate }} </span>
          </label>
          <div class="form-group me-4" v-if="!DayDate">
            <label for="discharge-date">تاریخ:</label>
            <Persian-Date
              :auto-submit="false"
              id="discharge-date"
              v-model="formData.AppointmentDateTime"
              :class="{ errorBorder: errors.AppointmentDateTime }"
              @click="errors.AppointmentDateTime = ''" />
            <span class="error-message" v-if="errors.AppointmentDateTime"> {{ errors.AppointmentDateTime }}</span>
          </div>
          <button type="button" class="btn btn-outline-danger btn-sm me-4" v-if="DayDate" @click="clearDate">تغییر</button>

          <label class="me-5" v-if="formData.Status"> وضعیت نوبت: {{ formData.Status }} </label>
        </div>
      </div>
    </div>
    <hr />
    <div class="RowDir">
      <div class="form-group">
        <label for="opht-physician-id">چشم پزشک:</label>
        <input
          class="physicianId"
          id="opht-physician-id"
          v-model="formData.OphtPhysician"
          list="opht-physician-id-list"
          @input="FillOphtPhysician('id', $event)"
          style="width: 40px" />
        <datalist id="opht-physician-id-list">
          <option v-for="(code, index) in options.OphtPhysicianOpts" :key="index" :value="code['id']">
            {{ code["id"] }}
          </option>
        </datalist>
        <input
          id="opht-physician-name"
          v-model="formData.OphtPhys"
          list="opht-physician-name-list"
          @input="FillOphtPhysician('name', $event)"
          :class="{ errorBorder: errors.OphtPhys }"
          @click="errors.OphtPhys = ''" />
        <datalist class="physicianName" id="opht-physician-name-list">
          <option v-for="(option, index) in options.OphtPhysicianOpts" :key="index" :value="option['Name']">
            {{ option["Name"] }}
          </option>
        </datalist>
        <span class="error-message" v-if="errors.OphtPhys">{{ errors.OphtPhys }}</span>
        <span @click="clearPhys" v-show="formData.OphtPhysician" style="margin-right: -20px; opacity: 0.5">✖</span>
      </div>
      <div class="form-group">
        <label> تخصص:</label>
        <label style="width: 250px"> {{ formData.OphtPhysSpecialty }}</label>
      </div>
    </div>
    <hr />
    <div>
      <button type="button" class="btn btn-primary OperationBtn" @click="editForm" v-if="hasAccess('EditAppointment')">ویرایش</button>
      <button type="button" class="btn btn-danger OperationBtn" @click="resetForm">پاک&zwnj;کردن</button>
      <button type="button" class="btn btn-warning OperationBtn" @click="PatientListForm" >لیست&zwnj;بیماران</button>
    </div>
  </div>
</template>

<script>
import api from "../../../Api/TheApi.js";
import { mapGetters } from "vuex";
import jalaali from "jalaali-js";
import permissionMixin from "@/Mixin/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      formData: {
        AppointmentLabel: null,
        AdmissionCode: null,
        AdmissionDate: null,
        AppointmentDateTime: null,
        Status: null,

        OphtPhysician: null,
        OphtPhys: null,
        OphtPhysSpecialty: null,
        RegisterUser: null,
        UpdateUser: null,
      },
      options: {
        OphtPhysicianOpts: [],
        NextVisit: [
          { label: "یک هفته", value: 7 },
          { label: "دو هفته", value: 14 },
          { label: "سه هفته", value: 21 },
          { label: "چهار هفته", value: 28 },
          { label: "یک ماه", value: 30 },
          { label: "دو ماه", value: 60 },
          { label: "سه ماه", value: 90 },
          { label: "شش ماه", value: 180 },
          { label: "یک سال", value: 365 },
          { label: "دو سال", value: 730 }
        ]
      },
      showSnippet: true,
      NextDateLbl: null,
      DayLabl: null,
      DayDate: null,
      errors: {},
      isDisabled: false
    };
  },
  computed: {
    ...mapGetters(["SelectedPatient","userID"])
  },
  mounted() {
    this.fetchItemOptions();
    if (this.SelectedPatient.id) this.fetchUserInfo();
  },

  methods: {
    async fetchItemOptions() {
      try {
        const response = await api.get("Admission/");
        this.options = { ...this.options, ...response.data };
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    FillPhysician(type, event, list, formFields) {
      const selectedValue = event.target.value;
      const selectedPhysician = list.find(physician =>
        type === "id" ? String(physician.id) === selectedValue : physician.Name === selectedValue
      ) || { id: null, Name: null, specialty_name: null };
      Object.assign(this.formData, {
        [formFields.id]: selectedPhysician.id,
        [formFields.name]: selectedPhysician.Name,
        [formFields.specialty]: selectedPhysician.specialty_name
      });
    },

    FillOphtPhysician(type, event) {
      this.FillPhysician(type, event, this.options.OphtPhysicianOpts, {
        id: "OphtPhysician",
        name: "OphtPhys",
        specialty: "OphtPhysSpecialty"
      });
    },

    async fetchUserInfo() {
      if (!this.SelectedPatient.id) return;
      this.resetForm();

      try {
        this.showSnippet = true;
        const { data, status } = await api.get(`appointment-detail/${this.SelectedPatient.admissions.AdmissionCode}/`);
        
        if (status === 200) {
          this.formData = { ...this.formData, ...data };
          this.formData.AppointmentLabel = null;
          this.formData.AppointmentDateTime = null;
          const [jy, jm, jd] = data.AppointmentDateTime.split("-").map(Number);
          const [ljy, ljm, ljd] = data.admission_date.split("-").map(Number);
          const dayDate = `${jy}-${jm}-${jd}`;
          this.formData.AdmissionDate = `${ljd}-${ljm}-${ljy}`;
          this.DayLabl = this.getDayLabelFromDate(dayDate);
          this.DayDate = `${jd}-${jm}-${jy}`;
          this.showSnippet = false;
        }
      } catch (error) {
        this.showSnippet = false;
        this.ShowEditBtn = false;
      }
    },

    async editForm() {
      this.errors = {};
      this.validateForm();

      if (Object.keys(this.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.UpdateUser = this.userID
          const { status } = await api.put(`appointment-detail/${this.SelectedPatient.admissions.AdmissionCode}/`, { ...this.formData });
          this.showSnippet = false;
          this.$refs.messageComponent.showMessage(status);
        } catch (error) {
          this.showSnippet = false;
          if (error.response) {
            this.$refs.messageComponent.showMessage(error.response.status);
          }
        }
      }
    },
    validateForm() {
      this.errors = {};
     
      if (!this.formData.OphtPhys) this.errors.OphtPhys = "پزشک را انتخاب کنید";
      if (!this.formData.AppointmentLabel && !this.DayDate) this.errors.AppointmentLabel = "نوبت را انتخاب کنید";
      if (!this.formData.AppointmentDateTime && !this.DayDate) this.errors.AppointmentDateTime = "تاریخ را انتخاب کنید";
      if (!this.formData.OphtPhys) this.errors.OphtPhys = "پزشک را انتخاب کنید";
      
      return Object.keys(this.errors).length === 0;
    },

    resetForm() {
      this.AppointmentLabel = null;
    },
    clearPhys() {
      this.formData.OphtPhysician = '';
      this.formData.OphtPhys = '';
      this.formData.OphtPhysSpecialty = '';
    },
    clearDate() {
      this.formData.AppointmentLabel = null;
      this.formData.AppointmentDateTime = null;
      this.DayDate = null;
      this.DayLabl = null;
    },
    removeErrorMsg(field) {
      this.errors[field] = "";
    },

    PatientListForm() {
      this.$emit("ShowAppointmentListForm");
    },
    updateDateTime() {
      const daysToAdd = this.formData.AppointmentLabel;
      if (daysToAdd !== null) {
        const today = jalaali.toJalaali(new Date());
        this.NextDateLbl = this.addDaysToJalaliDate(today, daysToAdd);
      } else {
        this.NextDateLbl = null;
      }
    },
    addDaysToJalaliDate(date, daysToAdd) {
      // Convert Jalali date to Gregorian date
      const gregorianDate = jalaali.toGregorian(date.jy, date.jm, date.jd);
      const gregorianDateObj = new Date(gregorianDate.gy, gregorianDate.gm - 1, gregorianDate.gd);

      // Add days to the Gregorian date
      gregorianDateObj.setDate(gregorianDateObj.getDate() + daysToAdd);

      // Convert back to Jalali date
      const newJalaliDate = jalaali.toJalaali(gregorianDateObj.getFullYear(), gregorianDateObj.getMonth() + 1, gregorianDateObj.getDate());

      return {
        ...newJalaliDate,
        dayName: this.getJalaliDayName(gregorianDateObj.getDay())
      };
    },
    getJalaliDayName(dayIndex) {
      const jalaliDayNames = ["یکشنبه", "دوشنبه", "سه‌شنبه", "چهارشنبه", "پنجشنبه", "جمعه", "شنبه" ];
      return jalaliDayNames[dayIndex];
    },
    getDayLabelFromDate(newVal) { 
      const [jy, jm, jd] = newVal.split("-").map(Number);
      const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
      const gregorianDate = new Date(gy, gm - 1, gd);
      const dayIndex = gregorianDate.getDay();
      const dayName = this.getJalaliDayName(dayIndex);
      return dayName;
    }
  },
  watch: {
    NextDateLbl: {
      handler(newVal) {
        if (newVal) {
          this.DayLabl = this.NextDateLbl.dayName;
          this.formData.AppointmentDateTime = `${this.NextDateLbl.jy}-${this.NextDateLbl.jm}-${this.NextDateLbl.jd}`;
        }
      }
    },
    "formData.AppointmentDateTime": function (newVal) {
      if (newVal) {        
        this.DayLabl = this.getDayLabelFromDate(newVal);
      }
    }
  }
};
</script>

<style scoped>

.OperationBtn {
  width: 90px;
  text-align: center;
  margin:0 10px 0 10px;
  font-size: 10pt;
  font-weight: bold;
}
.form-group {
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 5px 0 10px;
}

.RowDir {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

input,
select,
datalist {
  height: 30px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  padding: 0;
}
.error-message {
  color: red;
  font-size: 12px;
  font-weight: bold
}

.errorBorder {
  border: 2px solid red;
}
</style>
