<template>
  <div class="card text-center SecondSidebar">
    <div class="card-body">
      <SidebarCard
        title="شاخص"
      >
        <Indicator-Filter :IndReset="IndReset" @selected="handleSelectedIndicator"></Indicator-Filter>
      </SidebarCard>
      <SidebarCard
        title="جمعیت استاندارد"
        v-show="this.ShowStdPop"
        :EmptyAlert="EmptyStdPop"
      >
      <Stdpop-Filter @selected="handleSelectedStdPop" :StdPopReset="StdPopReset"></Stdpop-Filter>
      </SidebarCard>
      <SidebarCard title="مکان" >
        <Location-Filter @selected="handleSelectedLocation" :RemoveLocations="LocReset" @RemoveLocation="ResetLocValue"></Location-Filter>
      </SidebarCard>
      <SidebarCard title="سال" >
        <Year-Filter @selected="handleSelectedYear" :ResetYr="YearReset" @ResetYear="ResetYearValue"></Year-Filter>
      </SidebarCard>
      <SidebarCard title="جنسیت">
        <Sex-Filter @selected="handleSelectedSex" :SexReset="SexReset"></Sex-Filter>
      </SidebarCard>
      <SidebarCard title="گروه سنی" >
        <AgeGroupFilter @selected="handleSelectedAgeGroup" :ResetAge="AgeReset" @ResetAgeGroups="ResetAgeValues"></AgeGroupFilter>
      </SidebarCard>
      <SidebarCard title="stage" >
        <OrganSystem-Filter @selected="handleSelectedOrganSystem" :RemoveOrganSys="OrganSysReset" @RemoveOrganSystems="ResetOrganSystem"></OrganSystem-Filter>
      </SidebarCard>
      <SidebarCard title="zone" >
        <Organ-Filter @selected="handleSelectedOrgan" :RemoveOrgs="OrganReset" @RemoveOrgans="ResetOrgans"></Organ-Filter>
      </SidebarCard>
    </div>
    <div class="card-footer text-body-secondary">
      <div class="d-flex btnSideBar">
        <button @click="submitFilters" class="btn btn-primary btn-block btn-sm custom-button"><i class="bi bi-filter ms-2"></i>ثبت</button>
        <button @click="resetForm" class="btn btn-danger btn-block btn-sm me-4 custom-button"><i class="bi bi-arrow-counterclockwise ms-2"></i>ریست</button>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarCard from "../../../../UI/SecondRightsidecard.vue";
import IndicatorFilter from "./filters/IndicatorFilter.vue";
import StdpopFilter from "./filters/StdpopFilter.vue";
import LocationFilter from "./filters/LocationSelection.vue";
import AgeGroupFilter from "./filters/AgeGroupFilter.vue";
import SexFilter from "./filters/SexFilter.vue";
import YearFilter from "./filters/YearFilter.vue";

import { mapActions, mapGetters } from "vuex";

import api from "../../../../../Api/TheApi.js";

export default {
  components: {
    SidebarCard,
    IndicatorFilter,
    StdpopFilter,
    LocationFilter,
    AgeGroupFilter,
    SexFilter,
    YearFilter,
  },
  computed: {
    ...mapGetters(["RSideActiveBtn", "selectedLocations", "NavActiveBtn", "NavActiveTab", "ResetFilters"]),
  },
  data() {
    return {
      SelectedNavActiveBtn: "",
      SelectedTab: "",
      SelectedIndicator: "",
      SelectedStdPop: "",
      SelectedLocs: [],
      SelectedCounty: [],
      SelectedFactorTypes: [],
      SelectedFactors: [],
      SelectedAgeGroup: [],
      SelectedSex: "",
      SelectedYear: [],
      SelectedPredYear: "",
      SelectedOrganSystem: [],
      SelectedOrgan: [],
      SelectedOrganPart: [],
      SelectedMorphology: [],

      IndReset: false,
      StdPopReset: false,
      SexReset: false,
      LocReset: false,
      CountyReset: false,
      FactorTypesReset: false,
      FactorsReset: false,
      AgeReset: false,
      YearReset: false,
      PredYearReset: false,
      OrganSysReset: false,
      OrganReset: false,
      OrganFilterReset: false,
      MorphologyReset: false,

      ShowStdPop: false,

      EmptyStdPop: false,
    };
  },
  methods: {
    ...mapActions([
      "addSelectedLocations",
      "clearSelectedLocations",
      "ResetValuesFlag",
      "SetResetFilters",
      "SetLoading",
      "setTodayInGlance",
      "setTodayByLocation",
      "setTodayByCancer",
      "setTodayAgePyramid",
      "setTodayCancerDistPerc",
      "setTodayProvinceDist",
      "setTodayGeoMap",
      "setTrendInGlance",
      "setTrendByLocation",
      "setTrendByCancer",
      "setTrendAgePyramid",
      "setTrendCancerDistPerc",
      "setTrendProvinceDist",
      "setTrendGeoMap",
      "setTrendChangeTrend",
      "setFutureInGlance",
      "setFutureByLocation",
      "setFutureByCancer",
      "setFutureAgePyramid",
      "setFutureCancerDistPerc",
      "setFutureProvinceDist",
      "setFutureGeoMap",
      "setFutureIsoType",
      "setRiskFactorInGlance",
      "setRiskFactorGeoMap",
      "setRiskFactorChangeTrend",
      "setRiskFactorCorrelation",
      "setRiskFactorRegression",
      "setStatAnalysisInGlance",
      "setStatAnalysisByLocation",
      "setStatAnalysisByCancer",
      "setStatAnalysisAgePyramid",
      "setStatAnalysisCancerDistPerc",
      "setStatAnalysisProvinceDist",
      "setStatAnalysisGeoMap",
      "setStatAnalysisIqr",
      "setSpatialAnalysisAutoCorr",
      "setSpatialAnalysisHotColdSpots",
      "setSpatialAnalysisCluster",
      "clearTodayInGlance",
      "clearTodayByLocation",
      "clearTodayByCancer",
      "clearTodayAgePyramid",
      "clearTodayCancerDistPerc",
      "clearTodayProvinceDist",
      "clearTodayGeoMap",
      "clearTrendByLocation",
      "clearTrendByCancer",
      "clearTrendAgePyramid",
      "clearTrendCancerDistPerc",
      "clearTrendProvinceDist",
      "clearTrendGeoMap",
      "clearTrendChangeTrend",
      "clearFutureByLocation",
      "clearFutureByCancer",
      "clearFutureAgePyramid",
      "clearFutureCancerDistPerc",
      "clearFutureProvinceDist",
      "clearFutureGeoMap",
      "clearFutureIsoType",
      "clearStatAnalysisByLocation",
      "clearStatAnalysisByCancer",
      "clearStatAnalysisAgePyramid",
      "clearStatAnalysisCancerDistPerc",
      "clearStatAnalysisProvinceDist",
      "clearStatAnalysisGeoMap",
      "clearStatAnalysisIqr",
      "clearSpatialAnalysisAutoCorr",
      "clearSpatialAnalysisHotColdSpots",
      "clearSpatialAnalysisCluster",
      "setResultFilters",
      "SetAlarmMsg"
    ]),

    handleSelectedIndicator(payload) {
      this.IndReset = false;
      this.SelectedIndicator = payload;
      if (this.SelectedIndicator == "Number") {
        this.EmptyStdPop = false;
        this.ShowStdPop = false;
        this.StdPopReset = true;
        this.SelectedStdPop = "";
      } else if (this.SelectedIndicator != "Number") {
        this.EmptyStdPop = false;
        this.ShowStdPop = true;
        this.StdPopReset = false;
      }
    },

    handleSelectedStdPop(payload) {
      this.EmptyStdPop = false;
      this.SelectedStdPop = payload;
    },

    handleSelectedLocation(payload) {
      this.SelectedLocs = payload.value;
    },

    handleSelectedCounty(payload) {
      this.SelectedCounty = payload.value;
    },

    handleSelectedFactorTypes(payload) {
      this.SelectedFactorTypes = payload.value;
    },

    handleSelectedFactors(payload) {
      this.SelectedFactors = payload.value;
    },
    handleSelectedAgeGroup(payload) {
      this.SelectedAgeGroup = payload;
    },
    handleSelectedSex(payload) {
      this.SexReset = false;
      this.SelectedSex = payload;
    },
    handleSelectedYear(payload) {
      this.SelectedYear = payload;
    },
    handleSelectedPredYear(payload) {
      this.SelectedPredYear = false;
      this.SelectedPredYear = payload;
    },
    handleSelectedOrganSystem(payload) {
      this.SelectedOrganSystem = payload;
    },
    handleSelectedOrgan(payload) {
      this.SelectedOrgan = payload;
    },
    handleSelectedOrganPart(payload) {
      this.SelectedOrganPart = payload;
    },
    handleSelectedMorphology(payload) {
      this.SelectedMorphology = payload;
    },

    async submitFilters() {
      this.SetResetFilters(false);
      this.SetLoading(true);
      this.SetAlarmMsg(false);

      if (this.SelectedIndicator) {
        if (!this.SelectedStdPop) {
          this.EmptyStdPop = true;
        }
      }
      try {
        const data = {
          SelectedNavActiveBtn: this.NavActiveBtn,
          SelectedTab: this.NavActiveTab,
          Indicator: this.SelectedIndicator,
          StdPop: this.SelectedStdPop,
          Province: this.SelectedLocs,
          County: this.SelectedCounty,
          RiskFactorTypes: this.SelectedFactorTypes,
          RiskFactors: this.SelectedFactors,
          AgeGroup: this.SelectedAgeGroup,
          Sex: this.SelectedSex,
          Year: this.SelectedYear,
          PredYear: this.SelectedPredYear,
          OrganSystem: this.SelectedOrganSystem,
          Organ: this.SelectedOrgan,
          OrganPart: this.SelectedOrganPart,
          Morphology: this.SelectedMorphology,
        };

        this.setResultFilters(data);
        console.log(data);
        const queryString = Object.keys(data)
          .map((key) => `${key}=${encodeURIComponent(data[key])}`)
          .join("&");
        const response = await api.get(`${this.RSideActiveBtn}/FiltersData?${queryString}`, {
          params: data,
        });
        
        if (this.NavActiveBtn === "Today") {
          if (response.data!="Not Found") {
            console.log("Founded");
            this.setTabData(response.data, {
              InGlance: this.setTodayInGlance,
              ByLocation: this.setTodayByLocation,
              ByCancer: this.setTodayByCancer,
              AgePyramid: this.setTodayAgePyramid,
              DistributionPercentage: this.setTodayCancerDistPerc,
              SpatialDistribution: this.setTodayProvinceDist,
              GeographicMap: this.setTodayGeoMap,
            });
          } else {
            this.SetAlarmMsg(true);
            this.clearTabData({
              ByLocation: this.clearTodayByLocation,
              ByCancer: this.clearTodayByCancer,
              AgePyramid: this.clearTodayAgePyramid,
              DistributionPercentage: this.clearTodayCancerDistPerc,
              SpatialDistribution: this.clearTodayProvinceDist,
              GeographicMap: this.clearTodayGeoMap,
            });
          }
        } else if (this.NavActiveBtn === "Trend") {
          if (response.data!="Not Found") {
            this.setTabData(response.data, {
              InGlance: this.setTrendInGlance,
              ByLocation: this.setTrendByLocation,
              ByCancer: this.setTrendByCancer,
              AgePyramid: this.setTrendAgePyramid,
              DistributionPercentage: this.setTrendCancerDistPerc,
              SpatialDistribution: this.setTrendProvinceDist,
              GeographicMap: this.setTrendGeoMap,
              ChangeTrend: this.setTrendChangeTrend,
            });
          } else {
            this.SetAlarmMsg(true);
            this.clearTabData({
              ByLocation: this.clearTrendByLocation,
              ByCancer: this.clearTrendByCancer,
              AgePyramid: this.clearTrendAgePyramid,
              DistributionPercentage: this.clearTrendCancerDistPerc,
              SpatialDistribution: this.clearTrendProvinceDist,
              GeographicMap: this.clearTrendGeoMap,
              ChangeTrend: this.clearTrendChangeTrend,
            });
          }
        } else if (this.NavActiveBtn === "Future") {
          if (response.data!="Not Found") {
            this.setTabData(response.data, {
              InGlance: this.setFutureInGlance,
              ByLocation: this.setFutureByLocation,
              ByCancer: this.setFutureByCancer,
              AgePyramid: this.setFutureAgePyramid,
              DistributionPercentage: this.setFutureCancerDistPerc,
              SpatialDistribution: this.setFutureProvinceDist,
              GeographicMap: this.setFutureGeoMap,
              IsoType: this.setFutureIsoType,
            });
          } else {
            this.SetAlarmMsg(true);
            this.clearTabData({
              ByLocation: this.clearFutureByLocation,
              ByCancer: this.clearFutureByCancer,
              AgePyramid: this.clearFutureAgePyramid,
              DistributionPercentage: this.clearFutureCancerDistPerc,
              SpatialDistribution: this.clearFutureProvinceDist,
              GeographicMap: this.clearFutureGeoMap,
              IsoType: this.clearFutureIsoType,
            });
          }
        } else if (this.NavActiveBtn === "RiskFactors") {
          this.setTabData(response.data, {
            InGlance: this.setRiskFactorInGlance,
            GeographicMap: this.setRiskFactorGeoMap,
            ChangeTrend: this.setRiskFactorChangeTrend,
            Correlation: this.setRiskFactorCorrelation,
            Regression: this.setRiskFactorRegression,
          });
        } else if (this.NavActiveBtn === "StatAnalysis") {
          if (response.data!="Not Found") {
            console.log("1111", response.data);
            this.setTabData(response.data, {
              InGlance: this.setStatAnalysisInGlance,
              AvgLocation: this.setStatAnalysisByLocation,
              AvgCancer: this.setStatAnalysisByCancer,
              AvgAgePyramid: this.setStatAnalysisAgePyramid,
              DistributionPercentage: this.setStatAnalysisCancerDistPerc,
              SpatialDistribution: this.setStatAnalysisProvinceDist,
              AvgGeographicMap: this.setStatAnalysisGeoMap,
              Iqr: this.setStatAnalysisIqr,
              RelativeRisk: this.setStatAnalysisByLocation,
            });
          } else {
            this.SetAlarmMsg(true);
            this.clearTabData({
              AvgLocation: this.clearStatAnalysisByLocation,
              AvgCancer: this.clearStatAnalysisByCancer,
              AvgAgePyramid: this.clearStatAnalysisAgePyramid,
              DistributionPercentage: this.clearStatAnalysisCancerDistPerc,
              SpatialDistribution: this.clearStatAnalysisProvinceDist,
              AvgGeographicMap: this.clearStatAnalysisGeoMap,
              Iqr: this.clearStatAnalysisIqr,
              RelativeRisk: this.clearStatAnalysisByLocation,
            });
          }
        } else if (this.NavActiveBtn === "SpatialAnalysis") {
          if (response.data!="Not Found") {
            if (this.NavActiveTab === "AutoCorrelation") {
              this.setSpatialAnalysisAutoCorr(response.data);
              this.SetLoading(false);
              this.SetResetFilters(false);
            } else if (this.NavActiveTab === "HotColdSpots") {
              this.setSpatialAnalysisHotColdSpots(response.data);
              this.SetLoading(false);
              this.SetResetFilters(false);
            } else if (this.NavActiveTab === "ClusterOutliers") {
              this.setSpatialAnalysisCluster(response.data);
              this.SetLoading(false);
              this.SetResetFilters(false);
            }
          } else {
            if (this.NavActiveTab === "AutoCorrelation") {
              this.clearSpatialAnalysisAutoCorr();
              this.SetLoading(false);
              this.SetResetFilters(false);
            } else if (this.NavActiveTab === "HotColdSpots") {
              this.clearSpatialAnalysisHotColdSpots();
              this.SetLoading(false);
              this.SetResetFilters(false);
            } else if (this.NavActiveTab === "ClusterOutliers") {
              this.clearSpatialAnalysisCluster();
              this.SetLoading(false);
              this.SetResetFilters(false);
            }
          }
        }
      } catch (error) {
        this.SetAlarmMsg(true);
        console.error("Error:", error);
      }
    },
    setTabData(data, handlers) {
      for (const tab in handlers) {
        if (this.NavActiveTab === tab) {
          this.SetLoading(false);
          handlers[tab](data);
          this.SetResetFilters(false);
          break;
        }
      }
    },
    clearTabData(handlers) {
      for (const tab in handlers) {
        if (this.NavActiveTab === tab) {
          this.SetLoading(false);
          handlers[tab]();
          this.SetResetFilters(false);
          break;
        }
      }
    },

    resetForm() {
      this.SelectedIndicator = [];
      this.SelectedStdPop = "";
      this.SelectedLocs = [];
      this.SelectedCounty = [];
      this.SelectedFactorTypes = [];
      this.SelectedFactors = [];
      this.SelectedAgeGroup = [];
      this.SelectedSex = "";
      this.SelectedYear = [];
      this.SelectedPredYear = "";
      this.SelectedOrganSystem = [];
      this.SelectedOrgan = [];
      this.SelectedOrganPart = [];
      this.SelectedMorphology = [];

      this.IndReset = true;
      this.StdPopReset = true;
      this.SexReset = true;
      this.LocReset = true;
      this.CountyReset = true;
      this.FactorTypesReset = true;
      this.FactorsReset = true;
      this.AgeReset = true;
      this.YearReset = true;
      this.PredYearReset = true;
      this.OrganSysReset = true;
      this.OrganReset = true;
      this.OrganFilterReset = true;
      this.MorphologyReset = true;
    },

    ResetLocValue() {
      this.LocReset = false;
    },
    ResetCountyValue() {
      this.CountyReset = false;
    },
    ResetFactorTypesValue() {
      this.FactorTypesReset = false;
    },
    ResetFactorsValue() {
      this.FactorsReset = false;
    },

    ResetOrganSystem() {
      this.OrganSysReset = false;
    },
    ResetOrgans() {
      this.OrganReset = false;
    },
    ResetOrganParts() {
      this.OrganFilterReset = false;
    },
    ResetMorphology() {
      this.MorphologyReset = false;
    },
    ResetAgeValues() {
      this.AgeReset = false;
    },
    ResetYearValue() {
      this.YearReset = false;
    },
  },
  // watch: {
  //   ResetFilters: {
  //     handler(newValue) {
  //       // Check if the value has changed
  //       if (newValue) {
  //         this.resetForm();
  //       }
  //     },
  //     deep: true,
  //   },
  // },
};
</script>

<style scoped>
.card {
  margin:0;
}
.card-header {
  padding: 5px;
  font-size: 10pt;
  font-weight: 500;
}

.card-body {
  padding: 2px;
  margin: 3px;
}

.SecondSidebar .card-body {
  overflow-y: auto;
}

.btnSideBar {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  
}

.custom-button {
  height: 40px;
  width: 100px;
  font-size: 12pt;
}

</style>
