<template>
  <div class="content">
    <the-snippet v-show="loading"></the-snippet>
    <canvas v-show="!loading && !ShowMsgBox" class="age-pyramid" ref="resultChart"></canvas>
    <alert-message v-show="ShowMsgBox" @click="this.ShowMsgBox = false"></alert-message>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Chart from "chart.js/auto";
import chroma from "chroma-js"; // Import chroma library
import TheSnippet from "../../../components/UI/TheSnippet.vue";

export default {
  components: { TheSnippet },
  setup() {
    const ShowMsgBox = ref(false);
    const resultChart = ref(null);
    const loading = ref(false); // Simulated loading state
    let chartInstance = null;

    // Temporary data for age pyramid
    const tempData = {
      male: [100, 80, 60, 50, 40, 30, 25, 20, 15, 10, 10, 5, 5, 3, 2, 1], // Example male data
      female: [-90, -70, -55, -45, -35, -30, -20, -18, -12, -9, -7, -4, -3, -2, -1, -1] // Example female data (negative for chart stacking)
    };

    const updateChart = () => {
      const ageGroups = [
        "0-4",
        "5-9",
        "10-14",
        "15-19",
        "20-24",
        "25-29",
        "30-34",
        "35-39",
        "40-44",
        "45-49",
        "50-54",
        "55-59",
        "60-64",
        "65-69",
        "70-74",
        "75+"
      ];

      const maleData = tempData.male;
      const femaleData = tempData.female;

      Chart.defaults.font.family = "Vazirmatn";
      Chart.defaults.font.weight = "bold";

      if (chartInstance) {
        addData(chartInstance, maleData, femaleData);
      } else {
        const ctx = resultChart.value.getContext("2d");
        chartInstance = new Chart(ctx, {
          type: "bar",
          data: {
            labels: ageGroups,
            datasets: [
              {
                label: "مرد",
                backgroundColor: generateColors(ageGroups.length, "#FF4C43"), // Red for males
                borderWidth: 1.2,
                data: maleData
              },
              {
                label: "زن",
                backgroundColor: generateColors(ageGroups.length, "#3659FF"), // Blue for females
                borderWidth: 1.2,
                data: femaleData
              }
            ]
          },
          options: {
            indexAxis: "y",
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
                ticks: {
                  callback: function (value) {
                    return Math.abs(value); // Display absolute values
                  }
                }
              },
              y: {
                stacked: true,
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                position: "bottom",
                maxWidth: 200,
                labels: {
                  font: {
                    size: 14
                  },
                  boxWidth: 20
                }
              },
              tooltip: {
                callbacks: {
                  label: c => {
                    const value = Math.abs(Number(c.raw));
                    return `${c.dataset.label}: ${value}`;
                  }
                }
              }
            }
          }
        });
      }
    };

    onMounted(() => {
      // Simulating loading and chart update
      setTimeout(() => {
        loading.value = false;
        updateChart();
      }, 1000); // Simulated loading time
    });

    return { loading, ShowMsgBox, resultChart };
  }
};

// Function to generate gradient colors
function generateColors(count, baseColor) {
  const scale = chroma
    .scale([baseColor, chroma(baseColor).brighten(1)])
    .mode("lch")
    .colors(count);
  return scale.map(color => chroma(color).alpha(0.8).css());
}

// Function to update chart data
function addData(chart, maleData, femaleData) {
  chart.data.datasets.forEach((dataset, index) => {
    if (index === 0) {
      dataset.data = maleData;
    } else if (index === 1) {
      dataset.data = femaleData;
    }
  });
  chart.update();
}
</script>

<style scoped>
.content {
  height: 87vh;
  width: 66vw; 
}
</style>
