<template>
  <div class="card text-center export-buttons-card">
    <div class="card-header">
      <div class="mb-0 text-white">دریافت فایل</div>
    </div>
    <div class="card-body">
      <div class="export-button" v-for="button in buttons" :key="button.type">
        <button @click="handleExport(button.type)" :class="button.class"><i :class="button.icon"></i> {{ button.label }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// import { Document, Packer, Paragraph, ImageRun } from "docx";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    buttons: {
      type: Array,
      default: () => [
        { type: "pdf", label: "PDF", class: "btn btn-danger btn-lg", icon: "bi bi-file-earmark-pdf h3" },
        { type: "png", label: "PNG", class: "btn btn-primary btn-lg", icon: "bi bi-file-earmark-image h3" },
        { type: "jpeg", label: "JPEG", class: "btn btn-success btn-lg", icon: "bi bi-file-earmark-spreadsheet h3" },
        { type: "xlsx", label: "Excel", class: "btn btn-warning btn-lg", icon: "bi bi-file-earmark-excel h3" },
        // { type: "word", label: "Word", class: "btn btn-primary btn-lg", icon: "bi bi-file-earmark-word h3" },
      ],
    },
  },
  data() {
    return {
      ExportType: "",
    };
  },
  computed: {
    ...mapGetters(["ChartValue", "exportExe", "NavActiveTab", "NavActiveBtn"]),
  },
  watch: {
    exportExe(newVal) {
      console.log("exportExe", newVal);
      if (newVal) {
        switch (this.ExportType) {
          case "png":
            this.exportToPNG();
            break;
          case "pdf":
            this.exportToPDF();
            break;
          case "word":
            this.exportToWord();
            break;
          case "jpeg":
            this.exportToJPEG();
            break;
          case "xlsx":
            this.exportToXLSX();
            break;
          default:
            console.error(`Unsupported export type: ${this.ExportType}`);
        }
      }
    },
  },
  methods: {
    ...mapActions(["setChartExportFlag", "setExportExe"]),

    handleExport(type) {
      this.ExportType = type;
      this.setChartExportFlag(true);
    },
    exportToPNG() {
      const canvas = this.ChartValue;
      if (!canvas) {
        console.error("Chart reference is not defined.");
        this.setChartExportFlag(false);
        this.setExportExe(false);
        return;
      }
      const options = {
        scale: 1.5,
        logging: false,
      };
      html2canvas(canvas, options).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "Result.png";
        link.click();
        this.setChartExportFlag(false);
        this.setExportExe(false);
      });
    },
    exportToPDF() {
      const canvas = this.ChartValue;
      html2canvas(canvas).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10);
        pdf.save("chart.pdf");
        this.setChartExportFlag(false);
        this.setExportExe(false);
      });
    },

    exportToJPEG() {
      const canvas = this.ChartValue;
      html2canvas(canvas, {
        backgroundColor: "#ffffff",
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "chart.jpeg";
        link.click();
        this.setChartExportFlag(false);
        this.setExportExe(false);
      });
    },
    exportToXLSX() {
      let data = null;
      let formattedData = null;
      if (this.NavActiveTab == "ByLocation" || this.NavActiveTab == "AvgLocation") {
        if (this.NavActiveBtn == "Today") data = this.$store.getters.TodayByLocation;
        else if (this.NavActiveBtn == "Trend") data = this.$store.getters.TrendByLocation;
        else if (this.NavActiveBtn == "Future") data = this.$store.getters.FutureByLocation;
        else if (this.NavActiveBtn == "StatAnalysis") data = this.$store.getters.StatAnalysisByLocation;
        formattedData = Object.values(data).map((item) => ({
          استان: item.Province_Name,
          مقدار: item.total_count || item.crude_asr_inc || item.Iran_asr || item.Segi_asr || item.who_asr,
        }));
      } else if (this.NavActiveTab == "ByCancer" || this.NavActiveTab == "AvgCancer") {
        if (this.NavActiveBtn == "Today") data = this.$store.getters.TodayByCancer;
        else if (this.NavActiveBtn == "Trend") data = this.$store.getters.TrendByCancer;
        else if (this.NavActiveBtn == "Future") data = this.$store.getters.FutureByCancer;
        else if (this.NavActiveBtn == "StatAnalysis") data = this.$store.getters.StatAnalysisByCancer;
        formattedData = Object.values(data).map((item) => ({
          سرطان: item.icd_term,
          مقدار: item.total_count || item.crude_asr_inc || item.Iran_asr || item.Segi_asr || item.who_asr,
        }));
      }  else if (this.NavActiveTab == "AgePyramid" || this.NavActiveTab == "AvgAgePyramid") {
        if (this.NavActiveBtn == "Today") data = this.$store.getters.TodayAgePyramid;
        else if (this.NavActiveBtn == "Trend") data = this.$store.getters.TrendAgePyramid;
        else if (this.NavActiveBtn == "Future") data = this.$store.getters.FutureAgePyramid;
        else if (this.NavActiveBtn == "StatAnalysis") data = this.$store.getters.StatAnalysisAgePyramid;
         
        formattedData = Object.keys(data.Male_Crude_inc).map((ageGroup) => ({
          گروه_سنی: ageGroup,
          مردان: data.Male_Crude_inc[ageGroup].who_asr,
          زنان: data.Female_Crude_inc[ageGroup].who_asr,
        }));
      } else if (this.NavActiveTab == "DistributionPercentage") {
        if (this.NavActiveBtn == "Today") data = this.$store.getters.TodayCancerDistPerc;
        else if (this.NavActiveBtn == "Trend") data = this.$store.getters.TrendCancerDistPerc;        
        formattedData = Object.entries(data).map(([cancerName, asrValue]) => ({
          "نام سرطان": cancerName,
          درصد: `${asrValue} %`,
        }));
      } else if (this.NavActiveTab == "SpatialDistribution") {
        if (this.NavActiveBtn == "Today") data = this.$store.getters.TodayProvinceDist;
        else if (this.NavActiveBtn == "Trend") data = this.$store.getters.TrendProvinceDist;
        
        formattedData = Object.values(data.province_proportion).map(([provinceName, asrValue]) => ({
          "نام استان": provinceName,
          "درصد توزیع": asrValue,
        }));
      } else if (this.NavActiveTab == "GeographicMap" || this.NavActiveTab == "AvgGeographicMap") {
        if (this.NavActiveBtn == "Today") data = this.$store.getters.TodayGeoMap;
        else if (this.NavActiveBtn == "Trend") data = this.$store.getters.TrendGeoMap;
        else if (this.NavActiveBtn == "Future") data = this.$store.getters.FutureGeoMap;
        else if (this.NavActiveBtn == "StatAnalysis") data = this.$store.getters.StatAnalysisGeoMap;
        
        formattedData = Object.values(data).map((item) => ({
          سرطان: item.Province_Name,
          مقدار: item.total_count || item.crude_asr_inc || item.Iran_asr || item.Segi_asr || item.who_asr,
        }));
      }
      if (!data) {
        console.error("data is undefined");
        this.setChartExportFlag(false);
        this.setExportExe(false);
        return;
      }
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Results");
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "Results.xlsx");
      this.setChartExportFlag(false);
      this.setExportExe(false);
    },
  },
};
</script>

<style scoped>
.content {
  margin: 10px;
  height: 83vh;
  width: 54vw;
}
.export-buttons-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.export-button {
  margin: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card {
  max-height: 90vh;
}

.card-header {
  background-color: #007bff;
  color: white;
  border-radius: 10px 10px 0 0;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.btn-lg {
  font-size: 8pt;
  padding: 10px;
  height: 50px;
  padding: 2px;
}

@media screen and (min-width: 1365px) and (max-width: 1919px) {
  .card {
    font-size: 10pt;
    padding: 0;
  }

  .card-header {
    font-size: 10pt;
    font-weight: bold;
    padding: 5px;
  }
}

@media screen and (min-width: 1920px) {
  .card {
    max-height: 70vh;
  }
}
</style>
