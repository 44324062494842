<template>
  <Message-Display ref="messageComponent" />
  <div class="slide-in">
    <div class="searchBox ">
      <div class="form-group">
        <label>نام نقش:</label>
        <input v-model="RoleName" list="role-name" />
        <datalist id="role-name">
          <option v-for="role in roleList" :key="role.id" :value="role.role_name"></option>
        </datalist>
      </div>
      <button class="btn btn-primary" @click="SearchRole">جستجو</button>
    </div>

    <hr />

    <div v-for="(Name, MainPart) in groupedPermissions" :key="Name" class="form-group">
      <h5>{{ MainPart }}:</h5>
      <SegmentedCheckbox
        InputType="checkbox"
        :options="Name"
        @inputData="UpdateValues"
        :value="SelectedPermissions"
        v-model="SelectedPermissions"
        class="Segmented" />
    </div>

    <hr />

    <div class="OperationBtn">
      <button class="btn btn-success" v-if="ShowSubmitBtn" @click="handlePermission('post')">ثبت</button>
      <button class="btn btn-primary" v-if="ShowEditBtn" @click="handlePermission('put')">ویرایش</button>
      <button class="btn btn-danger" @click="ResetFields">پاک کردن</button>
    </div>
  </div>
</template>

<script>
import api from "../../Api/TheApi.js";
import SegmentedCheckbox from "../../components/UI/SegmentedCheckbox.vue";

export default {
  components: { SegmentedCheckbox },
  data() {
    return {
      roleList: [],
      groupedPermissions: {},
      RoleName: null,
      SelectedPermissions: [],

      ShowSubmitBtn: true,
      ShowEditBtn: false
    };
  },
  mounted() {
    this.fetchSections();
    this.fetchRoles();
  },
  methods: {
    UpdateValues(newVal) {
      this.SelectedPermissions = newVal;
    },

    async fetchSections() {
      try {
        const { data } = await api.get("/ProgramArea");
        this.groupedPermissions = data.reduce((acc, item) => {
          acc[item.MainPart] = acc[item.MainPart] || [];
          acc[item.MainPart].push(item.Name);
          return acc;
        }, {});
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async fetchRoles(query) {
      try {
        const { data } = await api.get("/roles", { params: { role_name: query } });
        this.roleList = data;
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async SearchRole() {
      try {
        this.SelectedPermissions = [];
        const { data } = await api.get("/permissions", { params: { role_name: this.RoleName } });
        if (Array.isArray(data) && data.length > 0) {
          this.SelectedPermissions = data.map(permission => permission.program_area);
          this.ShowEditBtn = true;
          this.ShowSubmitBtn = false;
        } else {
          this.ShowEditBtn = false;
          this.ShowSubmitBtn = true;
        }
      } catch (error) {
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async handlePermission(method) {
      try {
        const { status } = await api[method]("/permissions", {
          role_name: this.RoleName,
          permission_list: this.SelectedPermissions
        });
        this.$refs.messageComponent.showMessage(status);
        this.ResetFields();
      } catch (error) {
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    ResetFields() {
      this.RoleName = null,
      this.SelectedPermissions = [];
    }
  }
};
</script>

<style scoped>
.searchBox,
.OperationBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.form-group {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

h5 {
  width: 100px;
  font-size: 12pt;
  color: rgb(41, 41, 247);
  text-align: right;
}
button {
  width: 90px;
}

.Segmented > * {
  font-size: 8pt;
  min-width: 10%;
}
</style>
