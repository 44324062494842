<template>
  <div class="row" style="padding: 5px">
    <div class="column">
      <div class="radioOption">
        <input
          type="radio"
          :id="'Number'"
          :name="'Indicators'"
          :value="'Number'"
          @change="EmitSelected"
          :checked="selectedIndRadio === 'Number'"
        />
        <label for="Number">تعداد</label>
      </div>
      <div class="radioOption">
        <input
          type="radio"
          :id="'Incidence'"
          :name="'Indicators'"
          :value="'Incidence'"
          @change="EmitSelected"
          :checked="selectedIndRadio === 'Incidence'"
        />
        <label for="Incidence">بروز</label>
      </div>
      <div class="radioOption">
        <input
          type="radio"
          :id="'Prevalence'"
          :name="'Indicators'"
          :value="'Prevalence'"
          @change="EmitSelected"
          :checked="selectedIndRadio === 'Prevalence'"
        />
        <label for="Prevalence">شیوع</label>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ["IndReset"],
  data() {
    return {
      selectedIndRadio: null,
    };
  },
  methods: {
    EmitSelected(event) {
      this.selectedIndRadio = event.target.value;
      this.$emit("selected", event.target.value);
    },
  },
  watch: {
    IndReset: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        this.selectedIndRadio = null;
      }
    },
  },
};
</script>

<style scoped>
.column {
  display: flex;
  justify-content: space-around;
}
.radioOption {
  margin: 2px;
}
.radioOption input[type="radio"] {
  display: none;
}

.radioOption label {
  width: 50px;
  font-size: 11pt;
  cursor: pointer;
  padding: 2px 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: center;
}

.radioOption label:hover {
  background-color: #f0f0f0;
}

.radioOption input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.radioOption input[type="radio"]:checked + label:hover {
  background-color: #0056b3;
}
</style>
