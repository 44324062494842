<template>
    <div class="content">
      <TheSnippet v-show="loading"></TheSnippet>
      <canvas v-show="!loading && !ShowMsgBox" class="result-plot" ref="resultChart"></canvas>
      <alert-message v-show="ShowMsgBox" @click="this.ShowMsgBox=false"></alert-message>
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from "vue";
  import Chart from "chart.js/auto";
  import chroma from "chroma-js";
  import TheSnippet from "../../../components/UI/TheSnippet.vue";
  
  export default {
    components: { TheSnippet },
    setup() {
      const ShowMsgBox = ref(false);
      const resultChart = ref(null);
      const loading = ref(false); // Replaced with static loading state
      let chartInstance = null;
  
      // Temporary data for cancers
      const cancers = [
        { icd_term: "Cancer 1", total_count: 120 },
        { icd_term: "Cancer 2", total_count: 95 },
        { icd_term: "Cancer 3", total_count: 78 },
        { icd_term: "Cancer 4", total_count: 50 },
        { icd_term: "Cancer 5", total_count: 35 },
      ];
  
      const updateChart = () => {
        cancers.sort((a, b) => b.total_count - a.total_count);
  
        const labels = cancers.map(c => c.icd_term);
        const whoASR = cancers.map(c => c.total_count);
  
        const backgroundColors = generateColors(cancers.length);
        Chart.defaults.font.family = "Vazirmatn";
        Chart.defaults.font.weight = "bold";
  
        if (chartInstance) {
          addData(chartInstance, labels, whoASR);
        } else {
          const ctx = resultChart.value.getContext("2d");
          chartInstance = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Values",
                  backgroundColor: backgroundColors,
                  borderColor: backgroundColors.map(color => chroma(color).alpha(1).css()), 
                  borderWidth: 1.2,
                  data: whoASR,
                },
              ],
            },
            options: {
              indexAxis: "y",
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: "bottom",
                  maxWidth: 200,
                  labels: {
                    font: {
                      size: 11,
                    },
                    boxWidth: 20,
                  },
                },
              },
              scales: {
                y: {
                  maxWidth: 100,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              },
            },
          });
        }
      };
  
      onMounted(() => {
        updateChart(); // Use temporary data on mount
      });
  
      return { loading, ShowMsgBox, resultChart };
    },
  };
  
  // Helper functions
  function generateColors(count) {
    const scale = chroma.scale(["red", "orange", "green", "blue"]);
    const colors = scale.colors(count);
    return colors.map(color => chroma(color).alpha(0.4).css());
  }
  
  function addData(chart, labels, currentData) {
    chart.data.labels = labels;
    chart.data.datasets.forEach(dataset => {
      dataset.data = currentData;
    });
    chart.update();
  }
  </script>
  
  <style scoped>
  .content {
    height: 87vh;
    width: 66vw; 
  }
  </style>
  