<template>
  <div class="content">
    <med-record-list v-if="ShowPatientList" @ShowMedicalRecordDetailsForm="ActiveAdmHisForm" :selectedForm="activeForm"></med-record-list>
    <med-record-patient v-if="ShowMedicalRecordDetails" @ShowPatientListForm="ActivePatientList" :selectedForm="activeForm"></med-record-patient>
  </div>
</template>

<script>
import MedRecordList from './MedRecordList.vue';
import MedRecordPatient from './MedRecordPatient.vue';


export default {
  components: {
    MedRecordList,
    MedRecordPatient,
  },
  data() {
    return {
      ShowPatientList: true,
      ShowMedicalRecordDetails: false,
    };
  },
  methods: {
    ActivePatientList() {
      this.ShowPatientList= true;
      this.ShowMedicalRecordDetails= false;
    },
    ActiveAdmHisForm() {
      this.ShowPatientList= false;
      this.ShowMedicalRecordDetails= true;
    }
  },
};
</script>
<style scoped>
hr {
  margin: 5px;
}
</style>