<template>
  <div>
    <Main-Navbar></Main-Navbar>
    <div class="content slide-in">
      <div class="sidebar">
        <ul>
          <li 
            :class="{ active: currentComponent === 'UserManagement' }" 
            @click="currentComponent = 'UserManagement'"
            v-if="hasAccess('UserManagement')"
          >
            مدیریت کاربران
          </li>
          <li 
            :class="{ active: currentComponent === 'RoleManagement' }" 
            @click="currentComponent = 'RoleManagement'"
            v-if="hasAccess('RoleManagement')"
          >
            مدیریت نقش ها
          </li>
          <li 
            :class="{ active: currentComponent === 'PermissionManagement' }" 
            @click="currentComponent = 'PermissionManagement'"
            v-if="hasAccess('PermissionManagement')"
          >
            مدیریت مجوز ها
          </li>
        </ul>
      </div>
      <div class="main-content">
        <component :is="currentComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import UserManagement from "./UserManagement.vue";
import RoleManagement from "./RoleManagement.vue";
import PermissionManagement from "./PermissionManagement.vue";
import permissionMixin from "@/Mixin/permissionMixin";

export default {
  mixins: [permissionMixin],
  components: {
    UserManagement,
    RoleManagement,
    PermissionManagement,
  },
  data() {
    return {
      currentComponent: "UserManagement",
    };
  },
};
</script>

<style scoped>

.content {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
}

.sidebar {

  height: 90vh;
  width: 150px;
  background-color: #f4f4f4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
  height: 15%;
  width: 150px;
  border: 1px solid rgb(185, 185, 172);
  cursor: pointer;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.sidebar li:hover {
  background-color: #ddd;
}

.sidebar li.active {
  background-color: lightblue;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}
</style>
