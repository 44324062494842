<template>
  <div class="patient-info">
    <div v-if="SelectedPatient" class="info-container">
      <div class="info-row">
        <div class="info-group">
          <label class="section-title">اطلاعات پرونده:</label>
          <template v-if="RecordCode">
            <label class="info-label">شماره پرونده:</label>
            <span class="info-value">{{ RecordCode }}</span>
          </template>
          <template v-if="AdmissionCode">
            <label class="info-label">کد پذیرش:</label>
            <span class="info-value">{{ AdmissionCode }}</span>
          </template>
          <template v-if="AdmissionDate">
            <label class="info-label">تاریخ پذیرش:</label>
            <span class="info-value">{{ AdmissionDate }}</span>
          </template>
          <template v-if="NicuPhysician">
            <label class="info-label">پزشک معالج:</label>
            <span class="info-value">{{ NicuPhysician }}</span>
          </template>
          <template v-if="OpthPhysician">
            <label class="info-label">چشم پزشک:</label>
            <span class="info-value">{{ OpthPhysician }}</span>
          </template>
        </div>
        <div class="info-group">
          <label class="section-title">اطلاعات هویتی:</label>
          <template v-if="nationalCode">
            <label class="info-label">کد ملی:</label>
            <span class="info-value">{{ nationalCode }}</span>
          </template>
          <template v-if="firstName">
            <label class="info-label">نام:</label>
            <span class="info-value">{{ firstName }}</span>
          </template>
          <template v-if="lastName">
            <label class="info-label">نام خانوادگی:</label>
            <span class="info-value">{{ lastName }}</span>
          </template>
          <template v-if="birthdate">
            <label class="info-label">تاریخ تولد:</label>
            <span class="info-value">{{ birthdate }}</span>
          </template>
          <template v-if="fatherName">
            <label class="info-label">نام پدر:</label>
            <span class="info-value">{{ fatherName }}</span>
          </template>
          <template v-if="gender">
            <label class="info-label">جنسیت:</label>
            <span class="info-value">{{ gender }}</span>
          </template>
          <template v-if="WeightatBirth">
            <label class="info-label">وزن هنگام تولد:</label>
            <span class="info-value">{{ WeightatBirth }} گرم</span>
          </template>
          <template v-if="insuranceType">
            <label class="info-label">بیمه:</label>
            <span class="info-value">{{ insuranceType }}</span>
          </template>
          <template v-if="province">
            <label class="info-label">استان:</label>
            <span class="info-value">{{ province }}</span>
          </template>
          <template v-if="county">
            <label class="info-label">شهرستان:</label>
            <span class="info-value">{{ county }}</span>
          </template>
          <template v-if="mobile">
            <label class="info-label">تلفن:</label>
            <span class="info-value">{{ mobile }}</span>
          </template>
        </div>
      </div>
      <div class="info-row">
        <div class="info-group">
          <label class="section-title">اطلاعات بالینی:</label>
          <template v-if="TerminationWeeks">
            <label class="info-label">سن ختم بارداری:</label>
            <span class="info-value">{{ TerminationWeeks }}</span>
          </template>
          <template v-if="AgeAdmission >= 0">
            <label class="info-label">سن هنگام پذیرش:</label>
            <span class="info-value">{{ AgeAdmission }} هفته</span>
          </template>
          <template v-if="WeightAdmission">
            <label class="info-label">وزن هنگام پذیرش:</label>
            <span class="info-value">{{ WeightAdmission }} گرم</span>
          </template>
          <template v-if="PregnancyType">
            <label class="info-label">نوع حاملگی:</label>
            <span class="info-value">{{ PregnancyType }}</span>
          </template>
          <template v-if="DeliveryType">
            <label class="info-label">نوع زایمان:</label>
            <span class="info-value">{{ DeliveryType }}</span>
          </template>
        </div>
      </div>
      <div class="info-row">
        <div class="info-group">
          <template v-if="AdmissionTimes">
            <label class="info-label">نوبت مراجعه:</label>
            <span class="info-value">{{ AdmissionTimes }}</span>
          </template>
        </div>
      </div>
    </div>
    <div v-else class="no-info">
      <p>اطلاعات بیمار موجود نیست</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["SelectedPatient", "RSideActiveBtn"])
  },
  data() {
    return this.defaultData();
  },
  mounted() {
    if (this.SelectedPatient) this.updatePatientInfo(this.SelectedPatient);
  },
  methods: {
    defaultData() {
      return {
        AdmissionDate: "", AdmissionTimes: "", RecordCode: "", AdmissionCode: "",
        nationalCode: "", firstName: "", lastName: "", fatherName: "", gender: "",WeightatBirth: "",
        religion: "", insuranceType: "", province: "", county: "", mobile: "",
        TerminationWeeks: "", DeliveryType: "", AgeAdmission: "", WeightAdmission: "",
        ReferFrom: "", NicuPhysician: "", OpthPhysician: "", PregnancyType: ""
      };
    },
    updatePatientInfo(patient) {
      this.resetData();
      Object.assign(this, {
        RecordCode: patient.id,
        nationalCode: patient.NewbornNationalCode,
        firstName: patient.NewbornFirstName,
        lastName: patient.NewbornLastName,
        birthdate: patient.NewbornBirthDate,
        fatherName: patient.FatherFirstName,
        gender: patient.sex_name,
        religion: patient.ReligionType,
        province: patient.province_name,
        county: patient.county_name,
        mobile: patient.Mobile,
        AgeAdmission: patient.AdmissionAge,
        ...(patient.admissions && {
          AdmissionCode: patient.admissions.AdmissionCode,
          AgeAdmission: patient.admissions.AdmissionAge,
          insuranceType: patient.admissions.insurance_type_name,
          AdmissionDate: patient.admissions.AdmissionDate,
          TerminationWeeks: patient.admissions.TerminationWeek,
          WeightAdmission: patient.admissions.AdmissionNewbornWeight,
          AdmissionTimes: patient.admissions.AdmissionTimes,
          NicuPhysician: patient.admissions.nicu_physician_name,
          OpthPhysician: patient.admissions.opht_physician_name
        }),
        ...(patient.MedHistory && {
          WeightatBirth: patient.MedHistory.WeightatBirth,
          TerminationWeeks: patient.MedHistory.PregnancyTermination,
          DeliveryType: patient.MedHistory.delivery_type,
          PregnancyType: patient.MedHistory.pregnancy_type
        })
      });
    },
    resetData() {
      Object.assign(this, this.defaultData());
    }
  },
  watch: {
    SelectedPatient(newValue) {
      if (newValue) this.updatePatientInfo(newValue);
    }
  }
};
</script>


<style scoped>
.patient-info {
  display: flex;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin: 5px 0 20px 0;
  min-width: 90vw;
}


.info-row {
  display: flex;
  flex-direction: column;
}

.info-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3px;
}

.section-title {
  font-weight: 600;
  color: #061492;
  margin-right: 10px;
  font-size: 0.9rem;
  width: 91px;
}
.info-label {
  font-weight: 400;
  color: #666;
  margin-right: 10px;
  font-size: 0.7rem;
}

.info-value {
  font-size: 0.8rem;
  font-weight: 400;
  color: #003cbd;
  background: #f0f0f0ce;
  border-radius: 5px;
  padding: 1px 3px;
  display: inline-block;
}

.no-info {
  text-align: center;
  font-style: italic;
  color: #999;
}

@media (max-width: 768px) {
  .info-container {
    grid-template-columns: 1fr;
  }
}
</style>
