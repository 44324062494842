<template>
  <div class="row">
    <div class="col">
      <div v-for="(option, index) in options" :key="index" class="radioOption">
        <input
          type="radio"
          :id="option.id"
          :name="option.name"
          :value="option.value"
          @change="EmitSelected(option.value)"
          :checked="selectedSexRadio === option.value"
        />
        <label :for="option.id">{{ option.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["SexReset"],
  data() {
    return {
      options: [
        { id: "MaleSex", name: "Sex", value: "Male", label: "پسر" },
        {
          id: "FemaleSex",
          name: "Sex",
          value: "Female",
          label: "دختر",
        },
        { id: "BothSex", name: "Sex", value: "Both", label: "هردو" },
      ],
      selectedSexRadio: null,
    };
  },
  watch: {
    SexReset: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        console.log(newVal);
        this.selectedSexRadio = null;
      }
    },
  },
  methods: {
    EmitSelected(payload) {
      this.selectedSexRadio = payload;
      console.log(payload);
      this.$emit("selected", payload);
    },
  },
};
</script>

<style scoped>
.col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px;
}
.radioOption {
  width: 22%;
}
.radioOption input[type="radio"] {
  display: none;
}

.radioOption label {
  width: 100%;
  font-size: 10pt;
  cursor: pointer;
  padding: 5px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: center;
}

.radioOption label:hover {
  background-color: #f0f0f0;
}

.radioOption input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.radioOption input[type="radio"]:checked + label:hover {
  background-color: #0056b3;
}
</style>
