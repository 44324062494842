import TheDischarge from "../../../pages/DataEntry/Discharge/TheDischarge.vue";
import DischargePatient from "../../../pages/DataEntry/Discharge/DischargePatient.vue";
import store from '../../../Store/store'; // Import your Vuex store

export default [
    {
      path: "/DataEntry/Discharge",
      name: "Discharge",
      component: TheDischarge,
      beforeEnter: (to, from, next) => {
        store.dispatch('SetRSideActiveBtn', 'Discharge');
        next();
      },
      children: [
        {
          path: "DischargePatient",
          name: "DischargePatient",
          component: DischargePatient,
        },
      ],
    },
  ];
  