<template>
  <Year-RangeSlider
    dir="ltr"
    :type="SliderType"
    :min="SliderMin"
    :max="SliderMax"
    :step="SliderStep"
    :skin="sliderSkin"
    @RangeSlider="YearGroups"
    :ResetRange="ResetYr"
    @RemoveRange="RemoveYear"
  ></Year-RangeSlider>
</template>

<script>
import YearRangeSlider from "../../../../../UI/DualRangSlider.vue";

export default {
  components: {
    YearRangeSlider,
  },
  props: ["ResetYr"],
  data() {
    return {
      SliderType: "double",
      SliderMin: "1393",
      SliderMax: "1397",
      SliderStep: "1",
      sliderSkin: "big",
    };
  },
  methods: {
    YearGroups(values) {
      this.$emit("selected", values);
    },
    RemoveYear() {
      this.$emit("ResetYear");
    },
  },
};
</script>
