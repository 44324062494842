import DistPercent from "../../../pages/Reporting/charts/DistPercent.vue";
import store from "../../../Store/store"; // Import your Vuex store

export default [
  {
    path: "/Dashboard/DistPercent",
    name: "DistPercent",
    component: DistPercent,
    beforeEnter: (to, from, next) => {
      store.dispatch("SetRSideActiveBtn", "DistPercent");
      next();
    },
  },
];
