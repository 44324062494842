<template>
  <Message-Display ref="messageComponent" />
  <the-snippet v-if="showSnippet" />
  <div v-else class="follow-up-container slide-in">
      <div class="card demographic-card">
        <div class="card-header">اطلاعات هویتی</div>
        <div class="card-body">
          <div v-for="(value, label) in demographicInfo" :key="label" class="info-item">
            <span class="field-label">{{ label }}:</span>
            <span class="field-value">{{ value }}</span>
          </div>
        </div>
      </div>
      
      <div class="card follow-up-card">
        <div class="card-header">اطلاعات پیگیری</div>
        <div class="card-body">
          <div v-for="(value, label) in followUpInfo" :key="label" class="info-item">
            <span class="field-label">{{ label }}:</span>
            <span class="field-value">{{ value }}</span>
          </div>
          <div class="info-item">
            <span class="field-label">پیگیری:</span>
            <div class="field-value">
              <span v-if="!isEditing">{{ AuditedStatus }}</span>
              <select v-else v-model="AuditedStatus">
                <option>پیگیری نشده</option>
                <option>پیگیری شده</option>
                <option>عدم پاسخ</option>
                <option>مشخصات اشتباه</option>
                <option>عدم مراجعه</option>
              </select>
            </div>
            <button class="btn btn-outline-danger btn-sm" @click="toggleEdit"  v-if="hasAccess('ChangeFollowUp')">{{ isEditing ? "تایید" : "تغییر" }}</button>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success btn-sm OperationBtn" @click="submitAudit">ثبت</button>
          <button class="btn btn-warning btn-sm OperationBtn" @click="emitPatientList">لیست بیماران</button>
        </div>
      </div>
      
      <!-- Admission History Card -->
      <div class="card admission-card">
        <div class="card-header">سابقه مراجعه</div>
        <div class="card-body">
          <table>
            <thead>
              <tr>
                <th v-for="column in columns" :key="column.key" @click="sortTable(column.key)">
                  {{ column.label }}
                  <span :class="getSortClass(column.key)"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="admission in paginatedAdmissions" :key="admission.AdmissionCode">
                <td v-for="column in columns" :key="column.key">{{ admission[column.key] }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-controls">
            <button @click="changePage(-1)" :disabled="currentPage === 1" class="btn btn-primary btn-sm">قبلی</button>
            <span>صفحه {{ currentPage }} از {{ totalPages }}</span>
            <button @click="changePage(1)" :disabled="currentPage === totalPages" class="btn btn-success btn-sm">بعدی</button>
            <div class="items-per-page">
              <label>تعداد:</label>
              <input type="number" v-model="itemsPerPage" min="1" @change="updatePagination" />
              <span>نمایش {{ paginatedAdmissions.length }} از {{ patientAdmissions.length }}</span>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../../Api/TheApi.js";
import permissionMixin from "@/Mixin/permissionMixin.js";

export default {
  mixins:[permissionMixin],
  data() {
    return {
      patient: {},
      currentPage: 1,
      itemsPerPage: 10,
      sortKey: "",
      sortOrder: "asc",
      AuditedStatus: null,
      isEditing: false,
      showSnippet: true,
    };
  },
  computed: {
    ...mapGetters(["SelectedPatient","userID"]),
    demographicInfo() {
      return {
        "کد ملی": this.patient.NewbornNationalCode,
        "کد پرونده": this.patient.id,
        "نام و نام خانوادگی": `${this.patient.NewbornFirstName} ${this.patient.NewbornLastName}`,
        "نام پدر": this.patient.FatherFirstName,
        "جنسیت": this.patient.sex_name,
        "سن": `${this.patient.age_in_weeks} هفته`,
        "استان": this.patient.province_name,
        "شهرستان": this.patient.county_name,
        "آدرس": this.patient.Address,
        "تلفن": this.patient.Mobile,
        "موبایل": this.patient.HomePhone,
      };
    },
    followUpInfo() {
      return {
        "تاریخ آخرین پذیرش": this.patient.last_admission,
        "نوبت بعدی": this.patient.last_appointment_lbl,
        "تاریخ": this.patient.last_appointment,
        "وضعیت نوبت": this.patient.last_appointment_status,
      };
    },
    columns() {
      return [
        { label: "کد", key: "AdmissionCode" },
        { label: "تاریخ", key: "AdmissionDate" },
        { label: "نوع", key: "admission_type" },
        { label: "بخش", key: "admission_place" },
        { label: "بیمه", key: "insurance_type_name" },
        { label: "پزشک NICU", key: "nicu_physician_name" },
        { label: "چشم پزشک", key: "opht_physician_name" },
      ];
    },
    patientAdmissions() {
      return this.patient?.admissions || [];
    },
    sortedAdmissions() {
      return [...this.patientAdmissions].sort((a, b) => {
        if (!this.sortKey) return 0;
        const isAscending = this.sortOrder === "asc";
        return isAscending ? a[this.sortKey] - b[this.sortKey] : b[this.sortKey] - a[this.sortKey];
      });
    },
    paginatedAdmissions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.sortedAdmissions.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.patientAdmissions.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.fetchUserInfo();
  },
  methods: {
    async fetchUserInfo() {
      try {
        this.showSnippet = true;
        const code = this.SelectedPatient.NewbornNationalCode ? this.SelectedPatient.NewbornNationalCode : this.SelectedPatient.id;
        const type = this.SelectedPatient.NewbornNationalCode ? 'national-code' : 'record-code';
        const { data } = await api.get(`follow-up-detail/${code}/${type}/`);
        this.patient = data;
        this.AuditedStatus = data.last_appointment_audit || this.AuditedStatus;
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    changePage(direction) {
      this.currentPage += direction;
    },
    sortTable(key) {
      this.sortOrder = this.sortKey === key && this.sortOrder === "asc" ? "desc" : "asc";
      this.sortKey = key;
    },
    getSortClass(key) {
      return this.sortKey === key ? this.sortOrder : "";
    },
    updatePagination() {
      this.currentPage = Math.min(this.currentPage, this.totalPages);
    },
    async submitAudit() {
      try {
        this.showSnippet = true;
        const { status } = await api.put(`follow-up-detail/${this.SelectedPatient.admissions.AdmissionCode}/admission-code/`, { AuditedStatus: this.AuditedStatus });
        this.showSnippet = false;
        this.$refs.messageComponent.showMessage(status);
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    emitPatientList() {
      this.$emit("ShowFollowUpListForm");
    },
  },
};
</script>

<style scoped>
.follow-up-container {
  display: flex;
  background-color: #f4f6f9;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap:3px;
}

.card-header {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  text-align: center;
}

.card-body {
  padding: 15px;
  flex: 1;
}

.info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.field-label {
  font-weight: bold;
  color: #333;
}

.field-value {
  color: #666;
}

.card-footer {
  display: flex;
  padding: 10px;
  background-color: #f8f9fa;
  text-align: right;
  justify-content: center;
  align-items: center;
}

.admission-card {
  flex-grow: 2;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 4px;
  border: 1px solid #ddd;
  text-align: center;
}

th {
  background-color: #f1f1f1;
  cursor: pointer;
}

th span.asc::after {
  content: "▲";
  margin-left: 5px;
}

th span.desc::after {
  content: "▼";
  margin-left: 5px;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.items-per-page {
  display: flex;
  align-items: center;
}

.items-per-page input {
  width: 50px;
  margin-left: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.OperationBtn {
  width: 100px;
  text-align: center;
  font-weight: bold;
  margin-left:10px;
}

/* Define the keyframes for the slide-in animation */
@keyframes slide-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(-20px); /* Starts 20px below its original position */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Ends at its original position */
  }
}

/* Apply the animation to elements with the class 'slide-in' */
.slide-in {
  animation: slide-in-from-bottom 0.5s ease-out;
}
</style>
