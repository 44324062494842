<template>
  <div>
    <Message-Display ref="messageComponent" />
    <the-snippet v-if="showSnippet" />
    <div v-else class="content slide-in">
      <Patient-Info />
      <div>
        <div class="data-entry-form">
          <div class="RowDir">
            <div class="form-group">
              <label for="admission-date">تاریخ پذیرش:</label>
              <Persian-Date
                id="admission-date"
                class="PersianDate"
                
                v-model="formData.AdmissionDate"
                popover="bottom-right"
                />
            </div>
            <div class="form-group">
              <label for="Admission-type">بخش:</label>
              <select
                id="Admission-place"
                v-model="formData.AdmissionPlace"
                :class="{ errorBorder: messages.errors.AdmissionPlace }"
                @click="this.messages.errors.AdmissionPlace = ''">
                <option v-if="hasAccess('NicuAdmission')"  value="NICU">NICU</option>
                <option v-if="hasAccess('ClinicAdmission')"  value="درمانگاه">درمانگاه</option>
              </select>
              <span class="error-message" v-if="messages.errors.AdmissionPlace">{{ messages.errors.AdmissionPlace }}</span>
            </div>
            <div class="form-group">
              <label for="Admission-type">پذیرش:</label>
              <input
                disabled
                id="Admission-type"
                v-model="formData.AdmissionType"
                :class="{ errorBorder: messages.errors.AdmissionType }"
                @click="this.messages.errors.AdmissionType = ''" />
              <span class="error-message" v-if="messages.errors.AdmissionType">{{ messages.errors.AdmissionType }}</span>
            </div>
            <div class="form-group">
              <label class="toggle-switchy" for="IsReferral" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="">
                <input checked type="checkbox" id="IsReferral" v-model="formData.IsReferral" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">ارجاع از سایر مراکز:</span>
              </label>
            </div>
            <span class="error-message" v-if="messages.errors.IsReferral">{{ messages.errors.IsReferral }}</span>
          </div>
          <div v-if="formData.IsReferral" class="RowDir">
            <div class="form-group">
              <label for="referral-center">دانشگاه:</label>
              <input
                id="referral-center"
                v-model="formData.ReferralUni"
                list="referral-center-list"
                :class="{ errorBorder: messages.errors.ReferralUni }"
                @click="this.messages.errors.ReferralUni = ''" />
              <datalist id="referral-center-list">
                <option v-for="(option, index) in options.MedicalUniversityOpts" :key="index" :value="option">
                  {{ option }}
                </option>
              </datalist>
              <span class="error-message" v-if="messages.errors.ReferralUni">{{ messages.errors.ReferralUni }}</span>
            </div>
            <div class="form-group">
              <label for="referral-center">مرکز:</label>
              <input
                id="referral-center"
                type="text"
                v-model="formData.ReferralCenter"
                placeholder="نام مرکز"
                :class="{ errorBorder: messages.errors.ReferralCenter }"
                @click="this.messages.errors.ReferralCenter = ''" />
              <span class="error-message" v-if="messages.errors.ReferralCenter">{{ messages.errors.ReferralCenter }}</span>
            </div>
            <div class="form-group">
              <label for="physician-first-name">پزشک:</label>
              <input
                id="physician-first-name"
                type="text"
                v-model="formData.ReferralPhysicianName"
                placeholder="نام پزشک"
                :class="{ errorBorder: messages.errors.ReferralPhysicianName }"
                @click="this.messages.errors.ReferralPhysicianName = ''" />
              <span class="error-message" v-if="messages.errors.ReferralPhysicianName">{{ messages.errors.ReferralPhysicianName }}</span>
            </div>
            <div class="form-group">
              <label for="physician-specialty">تخصص:</label>
              <input
                id="physician-specialty"
                placeholder="تخصص پزشک"
                v-model="formData.ReferralPhysicianSpecialty"
                list="physician-specialty-list"
                :class="{ errorBorder: messages.errors.ReferralPhysicianSpecialty }"
                @click="this.messages.errors.ReferralPhysicianSpecialty = ''" />
              <datalist id="physician-specialty-list">
                <option v-for="(option, index) in options.PhysicianSpecialtyOpts" :key="index" :value="option">
                  {{ option }}
                </option>
              </datalist>
              <span class="error-message" v-if="messages.errors.ReferralPhysicianSpecialty">{{ messages.errors.ReferralPhysicianSpecialty }}</span>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <label for="nicu_physician-id">پزشک NICU:</label>
              <input
                id="nicu_physician-id"
                class="physicianId"
                v-model="formData.NicuPhysician"
                list="nicu-physician-id-list"
                @input="FillNicuPhysician('id', $event)" />
              <datalist id="nicu-physician-id-list">
                <option v-for="(code, index) in options.NicuPhysicianOpts" :key="index" :value="code['id']">
                  {{ code["id"] }}
                </option>
              </datalist>
              <input
                id="nicu-physician-name"
                v-model="formData.NicuPhys"
                list="nicu-physician-name-list"
                @input="FillNicuPhysician('name', $event)"
                :class="{ errorBorder: messages.errors.NicuPhys }"
                @click="this.messages.errors.NicuPhys = ''" />
              <datalist id="nicu-physician-name-list" class="physicianName">
                <option v-for="(option, index) in options.NicuPhysicianOpts" :key="index" :value="option['Name']">
                  {{ option["Name"] }}
                </option>
              </datalist>
              <span class="error-message" v-if="messages.errors.NicuPhys">{{ messages.errors.NicuPhys }}</span>
            </div>
            <div class="form-group">
              <label> تخصص:</label>
              <label style="width: 250px"> {{ formData.NicuPhysSpecialty }}</label>
            </div>

            <div class="form-group">
              <label for="opht-physician-id">چشم پزشک:</label>
              <input
                class="physicianId"
                id="opht-physician-id"
                v-model="formData.OphtPhysician"
                list="opht-physician-id-list"
                @input="FillOphtPhysician('id', $event)" />
              <datalist id="opht-physician-id-list">
                <option v-for="(code, index) in options.OphtPhysicianOpts" :key="index" :value="code['id']">
                  {{ code["id"] }}
                </option>
              </datalist>
              <input
                id="opht-physician-name"
                v-model="formData.OphtPhys"
                list="opht-physician-name-list"
                @input="FillOphtPhysician('name', $event)"
                :class="{ errorBorder: messages.errors.OphtPhys }"
                @click="this.messages.errors.OphtPhys = ''" />
              <datalist class="physicianName" id="opht-physician-name-list">
                <option v-for="(option, index) in options.OphtPhysicianOpts" :key="index" :value="option['Name']">
                  {{ option["Name"] }}
                </option>
              </datalist>
              <span class="error-message" v-if="messages.errors.OphtPhys">{{ messages.errors.OphtPhys }}</span>
            </div>
            <div class="form-group">
              <label> تخصص:</label>
              <label style="width: 250px"> {{ formData.OphtPhysSpecialty }}</label>
            </div>
          </div>

          <div class="RowDir">
            <div class="form-group">
              <label for="insurance-type">بیمه:</label>
              <select
                id="insurance-type"
                v-model="formData.InsuranceType"
                :class="{ errorBorder: messages.errors.InsuranceType }"
                @click="this.messages.errors.InsuranceType = ''">
                <option v-for="(option, index) in options.InsuranceTypeOpts" :key="index" :value="option">
                  {{ option }}
                </option>
                >
              </select>
              <span class="error-message" v-if="messages.errors.InsuranceType">{{ messages.errors.InsuranceType }}</span>
            </div>
            <div class="form-group">
              <label for="extra-insurance-type">تکمیلی:</label>
              <select
                id="extra-insurance-type"
                v-model="formData.ExtraInsuranceType"
                :class="{ errorBorder: messages.errors.ExtraInsuranceType }"
                @click="this.messages.errors.ExtraInsuranceType = ''">
                <option v-for="(option, index) in options.ExtraInsuranceOpts" :key="index" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.ExtraInsuranceType">{{ messages.errors.ExtraInsuranceType }}</span>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <label for="admission-reason-type">علت مراجعه:</label>
              <select
                id="admission-reason-type"
                v-model="formData.AdmissionReasonType"
                :class="{ errorBorder: messages.errors.AdmissionReasonType }"
                @click="this.messages.errors.AdmissionReasonType = ''">
                <option v-for="(option, index) in options.AdmissionReasonOpts" :key="index" :value="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.AdmissionReasonType">{{ messages.errors.AdmissionReasonType }}</span>
            </div>
            <div class="form-group">
              <label for="admission-weight">وزن:</label>
              <input
                type="number"
                id="admission-weight"
                v-model="formData.AdmissionNewbornWeight"
                placeholder="گرم"
                :class="{ errorBorder: messages.errors.AdmissionNewbornWeight }"
                @click="this.messages.errors.AdmissionNewbornWeight = ''" />
              <span class="error-message" v-if="messages.errors.AdmissionNewbornWeight">{{ messages.errors.AdmissionNewbornWeight }}</span>
            </div>
          </div>
        </div>
        <hr />
        <div class="DataEntryButtons">
          <router-link :to="{ name: 'MedicalHistoryPage' }" type="button" v-if="ShowSubmitBtn" @click="submitForm" class="btn btn-success">
            پذیرش
          </router-link>
          <button type="button" class="btn btn-primary" v-if="ShowEditBtn && hasAccess('EditAdmission')" @click="EditForm">ویرایش</button>
          <button type="button" class="btn btn-danger" @click="resetForm">پاک کردن</button>

          <router-link to="/DataEntry/Admission" type="button" @click="PateintlistForm" class="btn btn-warning"> لیست بیماران </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../Api/TheApi.js";
import { mapActions, mapGetters } from "vuex";
import "../../../assets/css/toggle-switchy.css";
import permissionMixin from "@/Mixin/permissionMixin.js";
import jalaali from 'jalaali-js';

export default {
  mixins: [permissionMixin],
  computed: {
    ...mapGetters(["SelectedPatient","userID"]),
  },
  data() {
    return {
      formData: {
        AdmissionDate: null,
        RecordCode: null,
        AdmissionCode: null,
        AdmissionPlace: null,
        AdmissionType: null,
        IsReferral: false,
        ReferralUni: null,
        ReferralCenter: null,
        ReferralPhysicianName: null,
        ReferralPhysicianSpecialty: null,
        NicuPhysician: null,
        NicuPhys: null,
        NicuPhysSpecialty: null,
        OphtPhysician: null,
        OphtPhys: null,
        OphtPhysSpecialty: null,
        InsuranceType: null,
        ExtraInsuranceType: null,
        AdmissionReasonType: null,
        AdmissionNewbornWeight: null,
        RegisterUser: null,
        UpdateUser: null,
      },
      options: {
        AdmissionPlaceOpts: [],
        MedicalUniversityOpts: [],
        ReferralCenterOpts: [],
        ReferralPhysOpts: [],
        PhysicianSpecialtyOpts: [],
        NicuPhysicianOpts: [],
        OphtPhysicianOpts: [],
        InsuranceTypeOpts: [],
        ExtraInsuranceOpts: [
          { label: "بلی", value: "Yes" },
          { label: "خیر", value: "No" }
        ],
        AdmissionReasonOpts: []
      },

      messages: {
        errors: {}
      },
      showSnippet: true,
      ShowSubmitBtn: true,
      ShowEditBtn: false
    };
  },
  mounted() {
    this.fetchItemOptions();
    if (this.SelectedPatient.admissions) this.fetchAdmissionInfo();
    const today = new Date();
    const jalaliDate = jalaali.toJalaali(today.getFullYear(), today.getMonth() + 1, today.getDate());
    this.formData.AdmissionDate = `${jalaliDate.jy}-${String(jalaliDate.jm).padStart(2, '0')}-${String(jalaliDate.jd).padStart(2, '0')}`;
  },
  methods: {
    ...mapActions(["setSelectedPatient", "SetRSideActiveBtn"]),
    async fetchItemOptions() {
      try {
        const response = await api.get("Admission/");
        this.options = { ...this.options, ...response.data };
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },

    FillPhysician(type, event, list, formFields) {
      const selectedValue = event.target.value;
      const selectedPhysician = list.find(physician =>
        type === "id" ? String(physician.id) === selectedValue : physician.Name === selectedValue
      ) || { id: null, Name: null, specialty_name: null };
      Object.assign(this.formData, {
        [formFields.id]: selectedPhysician.id,
        [formFields.name]: selectedPhysician.Name,
        [formFields.specialty]: selectedPhysician.specialty_name
      });
    },
    FillNicuPhysician(type, event) {
      this.FillPhysician(type, event, this.options.NicuPhysicianOpts, {
        id: "NicuPhysician",
        name: "NicuPhys",
        specialty: "NicuPhysSpecialty"
      });
    },
    FillOphtPhysician(type, event) {
      this.FillPhysician(type, event, this.options.OphtPhysicianOpts, {
        id: "OphtPhysician",
        name: "OphtPhys",
        specialty: "OphtPhysSpecialty"
      });
    },

    async fetchAdmissionInfo() {
      try {
        this.showSnippet = true;
        const { data } = await api.get(`/admission-detail/${this.SelectedPatient.admissions.AdmissionCode}/`);
        this.formData = { ...this.formData, ...data };
        this.showSnippet = false;
        this.ShowSubmitBtn = false;
        this.ShowEditBtn = true;
        this.showSnippet = false;
      } catch {
        this.showSnippet = false;
        this.ShowSubmitBtn = true;
        this.ShowEditBtn = false;
      }
    },

    async submitForm() {
      // if (!this.validateForm()) return;
      try {
        this.showSnippet = true;
        const { NewbornNationalCode, id } = this.SelectedPatient || {};
        if (NewbornNationalCode) this.formData.SelectedNewbornNationalCode = NewbornNationalCode;
        if (id) this.formData.SelectedRecordCode = id;
        this.formData.RegisterUser = this.userID
        const { status, data } = await api.post(`/admission-detail/${id}/`, { ...this.formData });
        this.showSnippet = false;
        this.$refs.messageComponent.showMessage(status);
        if (status === 201) {
          this.resetForm();
          this.showSnippet = true;
          const newAdmission = await api.get(`patient-info/${data.AdmissionCode}/admission-code/`);
          if (newAdmission.status === 200) {
            this.setSelectedPatient(newAdmission.data);
            this.$emit("ShowMedHistForm");
          }
        }
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
        return false;
      }
    },

    async EditForm() {
      // if (!this.validateForm()) return;
      try {
        this.showSnippet = true;
        const id = this.SelectedPatient.admissions.AdmissionCode;
        this.formData.UpdateUser = this.userID
        const { status } = await api.put(`/admission-detail/${id}/`, { ...this.formData });
        if (status === 200) {
          this.showSnippet = false;
          this.$refs.messageComponent.showMessage(status);
          this.resetForm();
          this.showSnippet = true;
          const newAdmission = await api.get(`patient-info/${id}/admission-code`);
          if (newAdmission.status === 200) {
            this.setSelectedPatient(newAdmission.data);
            this.$emit("ShowMedHistForm");
          }
        }
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
        return false;
      }
    },

    validateForm() {
      this.messages.errors = {};
      const fields = [
        { field: "AdmissionPlace", message: "بخش را انتخاب کنید" },
        { field: "AdmissionType", message: "پذیرش را انتخاب کنید" },
        { field: "InsuranceType", message: "بیمه را انتخاب کنید" },
        { field: "ExtraInsuranceType", message: "بیمه تکمیلی را انتخاب کنید" },
        { field: "AdmissionReasonType", message: "علت مراجعه را انتخاب کنید" },
        { field: "AdmissionNewbornWeight", message: "وزن نوزاد را وارد کنید" }
      ];
      fields.forEach(({ field, message }) => {
        if (this.formData.AdmissionPlace === 'درمانگاه' && !this.formData.OphtPhys) this.messages.errors.OphtPhys =  "چشم پزشک را انتخاب کنید";
        if (this.formData.AdmissionPlace === 'NICU' && !this.formData.NicuPhys) {this.messages.errors.NicuPhys =  "پزشک NICU را انتخاب کنید"; this.messages.errors.OphtPhys =  "چشم پزشک را انتخاب کنید";}
        if (!this.formData[field]) this.messages.errors[field] = message;
      });
      if (this.formData.IsReferral) {
        ["ReferralUni", "ReferralCenter", "ReferralPhysicianName", "ReferralPhysicianSpecialty"].forEach(field => {
          if (!this.formData[field]) this.messages.errors[field] = "این فیلد را پر کنید";
        });
      }
      return Object.keys(this.messages.errors).length === 0;
    },
    resetForm() {
      Object.keys(this.formData).forEach(key => (this.formData[key] = null));
      this.formData.AdmissionNewbornWeight = "";
      this.messages.errors = {};
    },
    PateintlistForm() {
      this.$emit("showPateintlist");
    }
  },
  watch: {
    "formData.AdmissionPlace"(newVal) {
      if (newVal === "درمانگاه") this.formData.AdmissionType = "سرپایی";
      else if (newVal === "NICU") this.formData.AdmissionType = "بستری";
    }
  }
};
</script>

<style scoped>
.btn {
  text-align: center;
  align-content: center;
  padding: 5px;
  min-width: 100px;
  height: 40px;
  margin-left: 10px;
  font-size: 10pt;
  font-weight: bold;
}
.data-entry-form {
  margin-bottom: 20px;
}

.form-group {
  width: 23%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 5px 0 10px;
}

.RowDir {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

label {
  margin: 9px;
  font-size: 10pt;
}

input,
select,
datalist {
  width: 80%;
  height: 30px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  padding: 0;
}
.physicianId {
  width: 30%;
}
.physicianName {
  width: 100%;
}
.DataEntryButtons {
  margin-top: 20px;
}
h5 {
  padding: 2px;
}
.error-message {
  color: red;
  font-size: 12px;
}

.errorBorder {
  border: 2px solid red;
  background: rgb(243, 53, 53);
}
.MessageBox {
  position: absolute;
  left: 0;
}
.toggle {
  margin-right: 20px !important;
}
</style>
