<template>
  <div>
    <router-link
      :to="{ name: 'NewPatient' }"
      type="button"
      class="btn btn-outline-primary ms-2"
      @click="AddNew"
      v-if="ShowNewPatientBtn"
    >
      بیمار جدید
    </router-link>

    <!-- Button to Trigger Merge Modal -->
    <button v-if="ShowNewPatientBtn" type="button" class="btn btn-outline-danger" @click="showMergModal">
      یکی کردن
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["ShowNewPatientBtn"], 

  methods: {
    ...mapActions(["clearSelectedPatient"]),

    AddNew() {
      this.clearSelectedPatient(); 
      this.$emit("AddNewPatient");
    },

    showMergModal() {
      this.$emit("showingMergModal");
    },

  },
};
</script>

<style scoped>
button {
  width: 100px;
  height: 40px;
  margin-left: 10px;
  font-size: 10pt;
  font-weight: bold;
}
</style>
