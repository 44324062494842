import GepMap from "../../../pages/Reporting/Map/GepMap.vue";
import store from "../../../Store/store"; 

export default [
  {
    path: "/Dashboard/GepMap",
    name: "GepMap",
    component: GepMap,
    beforeEnter: (to, from, next) => {
      store.dispatch("SetRSideActiveBtn", "GepMap");
      next();
    },
  },
];
