// TheNavbar.js

export default {
    state: {
      NavActiveBtn: null,
      NavActiveTab: null,
    },
    mutations: {
      SetNavActiveBtn(state, buttonName) {
        state.NavActiveBtn = buttonName;
      },
      SetNavActiveTab(state, buttonName) {
        state.NavActiveTab = buttonName;
      },
    },
    actions: {
      SetNavActiveBtn({ commit }, buttonName) {
        commit("SetNavActiveBtn", buttonName);
      },
      SetNavActiveTab({ commit }, buttonName) {
        
        commit("SetNavActiveTab", buttonName);
      },
    },
    getters: {
      NavActiveBtn: state => state.NavActiveBtn,
      NavActiveTab: state => state.NavActiveTab,
    },
  };
  