<template>
  <MultiSelectionbox
    :items="locations"
    placeholderText="انتخاب کنید"
    @change="handleLocationsSelection"
    :ResetItems="RemoveLocations"
    @ResetValues="ResetLocItems"
  >
  </MultiSelectionbox>
</template>

<script>
import MultiSelectionbox from "../../../../../UI/MultiSelectionbox.vue";
import { mapActions, mapGetters } from "vuex";
import api from "../../../../../../Api/TheApi";

export default {
  components: {
    MultiSelectionbox,
  },
  props: {
    RemoveLocations: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["selectedLocations", "RSideActiveBtn"]),
  },
  data() {
    return {
      locations: [],
    };
  },
  mounted() {
    this.fetchLocations();
  },
  watch: {
    RSideActiveBtn: "fetchLocations",
  },
  methods: {
    ...mapActions([
      "addSelectedLocations",
      "clearSelectedLocations",
      "addSelectedProvinces",
      "clearSelectedProvinces",
    ]),
    fetchLocations() {
      if (!this.RSideActiveBtn) return;
      const url = `/${this.RSideActiveBtn}`;
      api
        .get(url)
        .then((response) => {
          this.locations = response.data;
        })
        .catch((error) => {
          console.error("Error fetching locations:", error);
        });
    },

    handleLocationsSelection(selectedProvince) {
      if (!Array.isArray(selectedProvince)) {
        return [selectedProvince];
      }
      this.addSelectedLocations(selectedProvince);

      this.fetchProvinces(selectedProvince);

      this.$emit("selected", { value: selectedProvince });
    },

    fetchProvinces(selectedProvince) {
      this.clearSelectedLocations();

      api
        .get("/Province", {
          params: { selectedProvinces: selectedProvince },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.length > 0 &&
            response.data.some((item) => !!item)
          ) {
            this.clearSelectedProvinces()
            this.addSelectedProvinces(response.data);
          } else {
            this.clearSelectedProvinces();
          }
        })
        .catch((error) => {
          console.error("Error fetching organs:", error);
        });
    },
    ResetLocItems() {
      this.$emit("RemoveLocation");
    },
  },
};
</script>
