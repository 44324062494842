<template>
    <div class="content">
    <the-snippet v-show="loading"></the-snippet>
      <zanjan-map  v-show="!loading && !ShowMsgBox" ref="SvgMapRef" @ShoTooltip="showTooltip" @HidTooltip="hideTooltip" :tooltipText="tooltipText" :tooltipX="tooltipX" :tooltipY="tooltipY" :colorRanges="colorRanges"/>
    </div>
  </template>
  
  <script>
  import { onMounted, ref, computed, watch } from "vue";
  import { useStore } from "vuex";
  import { jenks } from "simple-statistics";
  import TheSnippet from "../../../components/UI/TheSnippet.vue";
  import ZanjanMap from "../Map/ZanjanProvince.vue";
  
  export default {
    components: { TheSnippet,ZanjanMap },
    setup() {
      const store = useStore();
      const ShowMsgBox = ref(false)
      const loading = computed(() => store.getters.TheLoading);
      const RSideActiveBtn = computed(() => store.getters.RSideActiveBtn);
  
      const tooltipText = ref("");
      const tooltipX = ref(0);
      const tooltipY = ref(0);
      let colorRanges =  ref([]);
  
      const TodayUpdateMap = () => {
        const provinces = Object.values(store.getters.TodayGeoMap);
        const data = provinces.map((province) => Math.abs(province.total_count || province.crude_asr_inc || province.Iran_asr || province.Segi_asr || province.who_asr));
        let numClasses = 1;
        if (data.length > 7) {
          numClasses = 6;
        } else {
          numClasses = 4;
        }
  
        const breakpoints = jenks(data, numClasses);
  
        colorRanges.value = [];
        for (let i = 0; i < numClasses; i++) {
          let color;
          switch (i) {
            case 0:
              color = "#6DD2ED";
              break;
            case 1:
              color = "#71E2BF";
              break;
            case 2:
              color = "#75F290";
              break;
            case 3:
              color = "#E5AC6F";
              break;
            case 4:
              color = "#CF7878";
              break;
            case 5:
              color = "#D23A3A";
              break;
            case 6:
              color = "#EF0000";
              break;
            default:
              color = "white";
              break;
          }
  
          colorRanges.value.push({
            min: i < breakpoints.length ? breakpoints[i] : 0,
            max: i + 1 < breakpoints.length ? breakpoints[i + 1] : Number.MAX_VALUE,
            color: color,
          });
        }
  
        const svgElement = document.querySelector(".map");
        if (svgElement) {
          provinces.forEach((province) => {
            const label = province.English_Name;
  
            const who_asr = province.total_count || province.crude_asr_inc || province.Iran_asr || province.Segi_asr || province.who_asr;
  
            let color = "white";
            for (let j = 0; j < colorRanges.value.length; j++) {
              const range = colorRanges.value[j];
              if (who_asr < 0) {
                color = "white";
              } else if (who_asr > 0 && who_asr >= range.min && who_asr <= range.max) {
                color = range.color;
                break;
              }
            }
  
            const provincePath = svgElement.querySelector(`.${label}`);
            if (provincePath) {
              provincePath.style.fill = color;
            }
          });
        }
      };
  
      const clearColors = () => {
        const svgElement = document.querySelector(".map");
        if (svgElement) {
          let provincePaths = null;
          if (store.getters.RSideActiveBtn === "Country") provincePaths = svgElement.querySelectorAll(".ProvinceArea");
          else if (store.getters.RSideActiveBtn === "Province") provincePaths = svgElement.querySelectorAll(".CountyArea");
          provincePaths.forEach((provincePath) => {
            provincePath.style.fill = "white";
          });
        }
      };
  
      onMounted(() => {
        if (store.getters.TodayGeoMap && Object.values(store.getters.TodayGeoMap).length !== 0) {
          clearColors();
          TodayUpdateMap();
        }
      });
  
      watch(
        () => store.getters.TodayGeoMap,
        () => {
          if (store.getters.TodayGeoMap && Object.values(store.getters.TodayGeoMap).length !== 0) {
            ShowMsgBox.value=false;
            clearColors();
            TodayUpdateMap();
          } else {
            clearColors();
            ShowMsgBox.value = true;
            colorRanges.value = [];
          }
        }
      );
  
      const showTooltip = (event) => {
        let provinces;
        provinces = Object.values(store.getters.TodayGeoMap);
        const provinceName = event.target.getAttribute("class");
        const provinceData = provinces.find((province) => province.English_Name == provinceName);
        if (provinceData) {
          let who_asr;
          who_asr = provinceData.total_count || provinceData.crude_asr_inc || provinceData.Iran_asr || provinceData.Segi_asr || provinceData.who_asr;
  
          const rect = event.currentTarget.getBoundingClientRect();
          const x = event.clientX - rect.left + 80;
          const y = event.clientY - rect.top + 40;
          tooltipText.value = `${who_asr}`;
          tooltipX.value = `${x}px`;
          tooltipY.value = `${y}px`;
        }
      };
  
      const hideTooltip = (event) => {
        if (event.currentTarget) {
          tooltipText.value = "";
        }
      };
  
      return { loading,ShowMsgBox, RSideActiveBtn,colorRanges, tooltipText, tooltipX, tooltipY, showTooltip, hideTooltip };
    },
  };
  </script>
  
  <style scoped>
.content {
  max-height: 87vh !important;
  max-width: 70vw !important;
}
</style>
