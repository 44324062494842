<template>
    <div class="content">
        <the-snippet v-show="loading"></the-snippet>
        <canvas v-show="!loading && !ShowMsgBox" class="result-plot" ref="resultChart"></canvas>
      <alert-message v-show="ShowMsgBox" @click="this.ShowMsgBox=false"></alert-message>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, watch } from "vue";
  import Chart from "chart.js/auto";
  import chroma from "chroma-js";
  import TheSnippet from "../../../components/UI/TheSnippet.vue";

  
  export default {
    components: { TheSnippet },
    setup() {
      const ShowMsgBox = ref(false);
      const resultChart = ref(null);
      const loading = ref(false); // Simulate the loading state
      let chartInstance = null;
  
      // Temporary data for chart
      const tempData = {
        "Cancer A": 15,
        "Cancer B": 25,
        "Cancer C": 20,
        "Cancer D": 30,
        "Cancer E": 10,
      };
  
      const updateChart = () => {
        const labels = Object.keys(tempData); // Use tempData instead of store
        let CancerPerc = Object.values(tempData);
        const backgroundColors = generateColors(labels.length);
        Chart.defaults.font.family = "Vazirmatn";
        Chart.defaults.font.weight = "bold";
  
        // Sorting data by percentage
        const sortedIndices = CancerPerc.map((_, i) => i).sort((a, b) => CancerPerc[a] - CancerPerc[b]);
        labels.sort((a, b) => CancerPerc[labels.indexOf(a)] - CancerPerc[labels.indexOf(b)]);
        CancerPerc = sortedIndices.map((i) => CancerPerc[i]);
        backgroundColors.sort((a, b) => sortedIndices.indexOf(backgroundColors.indexOf(a)) - sortedIndices.indexOf(backgroundColors.indexOf(b)));
  
        if (chartInstance) {
          addData(chartInstance, labels, CancerPerc);
        } else {
          const ctx = resultChart.value.getContext("2d");
          chartInstance = new Chart(ctx, {
            type: "doughnut",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Values",
                  backgroundColor: backgroundColors,
                  borderColor: backgroundColors.map((color) => chroma(color).alpha(1).css()),
                  borderWidth: 1.2,
                  data: CancerPerc,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                layout: {
                  padding: 20,
                  margin: 10,
                },
                legend: {
                  position: "left",
                  maxWidth: 200,
                  labels: {
                    font: {
                      size: 12,
                    },
                    boxWidth: 15,
                    generateLabels: function (chart) {
                      const data = chart.data;
                      if (data.labels.length && data.datasets.length) {
                        return data.labels.map((label, i) => {
                          const ds = data.datasets[0];
                          const currentValue = ds.data[i];
                          const backgroundColor = ds.backgroundColor[i];
                          const labelContent = `${label}: (${currentValue}%)`;
                          return {
                            text: labelContent,
                            fillStyle: backgroundColor,
                          };
                        });
                      }
                      return [];
                    },
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.label || "";
                      if (label) {
                        label += ": ";
                      }
                      if (context.parsed) {
                        label = `% ${context.parsed.toLocaleString()}`;
                      }
                      return label;
                    },
                  },
                },
              },
            },
          });
        }
      };
  
      // Simulate chart rendering when mounted
      onMounted(() => {
        if (Object.values(tempData).length !== 0) {
          updateChart();
        }
      });
  
      // Watcher for temporary data (simulating the store data watcher)
      watch(
        () => tempData,
        () => {
          if (Object.values(tempData).length !== 0) {
            ShowMsgBox.value = false;
            updateChart();
          } else {
            ShowMsgBox.value = true;
            updateChart();
          }
        }
      );
  
      return { loading, ShowMsgBox, resultChart };
    },
  };
  
  function generateColors(count) {
    const scale = chroma.scale([
      "#0000FF",
      "#00FF00",
      "#FF0000",
      "#FFFF00",
      "#FF00FF",
      "#00FFFF",
    ]);
    const domain = scale.domain([0, 100]);
    const colors = domain.colors(count);
    return colors.map((color) => chroma(color).alpha(0.6).css());
  }
  
  function addData(chart, labels, newData) {
    chart.data.labels = labels;
    chart.data.datasets.forEach((dataset) => {
      dataset.data = newData;
    });
    chart.update();
  }
  </script>
  
  <style scoped>
    .content {
    height: 87vh;
    width: 66vw; 
  }
  </style>
  