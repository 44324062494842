<template>
  <Message-Display ref="messageComponent" />
  <the-snippet v-if="showSnippet" />
  <div v-else class="slide-in">
    <Patient-Info />
    <div class="RowDir">
      <div class="form-group">
        <label for="discharge-date">تاریخ&zwnj;ترخیص:</label>
        <Persian-Date
          :auto-submit="false"
          id="discharge-date"
          v-model="formData.DischargeDate"
          :class="{ errorBorder: messages.errors.DischargeDate }"
          @click="messages.errors.DischargeDate = ''" />
        <span class="error-message" v-if="messages.errors.DischargeDate"> {{ messages.errors.DischargeDate }}</span>
      </div>

      <div class="form-group">
        <label for="DischargeState">وضعیت:</label>
        <select
          id="DischargeState"
          v-model="formData.DischargeState"
          :class="{ errorBorder: messages.errors.DischargeState }"
          @click="messages.errors.DischargeState = ''">
          <option v-for="option in options.DischargeState" :value="option" :key="option">
            {{ option }}
          </option>
        </select>
        <span class="error-message" v-if="messages.errors.DischargeState"> {{ messages.errors.DischargeState }}</span>
      </div>
      <div class="form-group">
        <label for="referral-dist">ارجاع&zwnj;به:</label>
        <select
          id="referral-dist"
          v-model="formData.ReferralTo"
          :class="{ errorBorder: messages.errors.ReferralTo }"
          @click="messages.errors.ReferralTo = ''">
          <option v-for="option in options.ReferralDist" :value="option.value" :key="option.value">
            {{ option.label }}
          </option>
        </select>
        <span class="error-message" v-if="messages.errors.ReferralTo"> {{ messages.errors.ReferralTo }}</span>
      </div>
    </div>
    <hr />
    <div class="RowDir">
      <div v-if="isDisabled && formData.AppointmentLabel">
        <label class="appointmentLbl"
          >نوبت: <span>{{ formData.AppointmentLabel }} </span> بعد</label
        >
        -
        <label class="appointmentLbl"
          >روز: <span> {{ DayLabl }}</span></label
        >
        -
        <label class="appointmentLbl"
          >تاریخ: <span> {{ DayDate }}</span></label
        >
      </div>
      <div class="form-group" v-if="!isDisabled">
        <label for="Next-visit">مراجعه بعدی:</label>
        <select id="Next-visit" v-model="formData.AppointmentLabel" style="width: 150px" @change="CalculateDate">
          <option v-for="option in options.NextVisit" :value="option.value" :key="option.label">
            {{ option.label }}
          </option>
        </select>
        <span @click="clearDate" v-show="formData.AppointmentLabel && !DayDate" style="margin-right: -40px; opacity: 0.5; font-size: 7pt">✖</span>

        <label class="me-5">
          روز: <span style="font-weight: bold; color: blue; font-size: 12pt">{{ `${DayLabl} - ` }} </span>
        </label>
        <label for="discharge-date">تاریخ:</label>
        <Persian-Date
          :auto-submit="false"
          id="discharge-date"
          v-model="formData.AppointmentDateTime"
          :class="{ errorBorder: messages.errors.AppointmentDateTime }"
          @click="messages.errors.AppointmentDateTime = ''" />
        <span class="error-message" v-if="messages.errors.AppointmentDateTime"> {{ messages.errors.AppointmentDateTime }}</span>
      </div>
      <div class="form-group">
        <button type="button" class="btn btn-outline-danger btn-sm" v-if="isDisabled" @click="toggleEdit">تغییر</button>
        <button type="button" class="btn btn-outline-success btn-sm" v-if="!isDisabled" @click="toggleEdit">تایید</button>
        <button type="button" class="btn btn-outline-primary btn-sm" v-if="!isDisabled" @click="CancelEdit">لغو</button>
        <button type="button" class="btn btn-outline-danger btn-sm" v-if="!isDisabled" @click="delAppointment">حذف</button>
      </div>
    </div>
    <hr />
    <div>
      <button type="button" class="btn btn-success" v-if="ShowSubmitBtn" @click="submitForm">ثبت</button>
      <button type="button" class="btn btn-primary" @click="editForm" v-if="ShowEditBtn">ویرایش</button>
      <button type="button" class="btn btn-danger" @click="resetForm">پاک&zwnj;کردن</button>
      <button type="button" @click="PatientListForm" class="btn btn-warning">لیست&zwnj;بیماران</button>
    </div>
  </div>
</template>

<script>
import api from "../../../Api/TheApi.js";
import { mapGetters } from "vuex";
import jalaali from "jalaali-js";

export default {
  emits: ['ShowDischargeListForm'],
  data() {
    return {
      formData: {
        DischargeDate: null,
        DischargeState: null,
        ReferralTo: null,
        AppointmentLabel: null,
        AppointmentDateTime: null,
        OphtPhysician: null,
        RegisterUser: null,
        UpdateUser: null,
      },
      options: {
        DischargeState: [],
        ReferralDist: [
          { label: "منزل", value: "complete-recovery" },
          { label: "چشم پزشکی", value: "relative-recovery" },
          { label: "NICU", value: "self-satistaction" }
        ],
        NextVisit: [
          { label: "یک هفته", value: "یک هفته" },
          { label: "دو هفته", value: "دو هفته" },
          { label: "سه هفته", value: "سه هفته" },
          { label: "چهار هفته", value: "چهار هفته" },
          { label: "یک ماه", value: "یک ماه" },
          { label: "دو ماه", value: "دو ماه" },
          { label: "سه ماه", value: "سه ماه" },
          { label: "شش ماه", value: "شش ماه" },
          { label: "یک سال", value: "یک سال" },
          { label: "دو سال", value: "دو سال" }
        ]
      },
      messages: {
        errors: {}
      },
      showSnippet: true,
      NextDateLbl: null,
      DayLabl: "",
      DayDate: "",
      ShowSubmitBtn: true,
      ShowEditBtn: false,
      isDisabled: false
    };
  },
  computed: {
    ...mapGetters(["SelectedPatient","userID"])
  },
  created() {
    this.fetchItemOptions();
  },
  mounted() {
    if (this.formData.AppointmentLabel) {
      this.isDisabled = true;
    }

    this.fetchUserInfo();
  },

  methods: {
    toggleEdit() {
      this.isDisabled = !this.isDisabled;
      if (this.isDisabled && this.formData.AppointmentLabel) {
        const { data } = api.get(`discharge-detail/${this.SelectedPatient.admissions.AdmissionCode}/`);
        this.formData = { ...this.formData, ...data };
      }
    },

    async fetchItemOptions() {
      try {
        const { data, status } = await api.get(`Discharge/`);
        if (status === 200) {
          this.options.DischargeState = data.DischargeState;
        }
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async fetchUserInfo() {
      if (!this.SelectedPatient.admissions.AdmissionCode) return;
      this.resetForm();

      try {
        this.showSnippet = true;
        const { data, status } = await api.get(`discharge-detail/${this.SelectedPatient.admissions.AdmissionCode}/`);
        if (status === 200) {
          this.formData = { ...this.formData, ...data };
          if (this.formData.AppointmentLabel) {
            this.isDisabled = true;
          }
          if (this.formData.DischargeState) {
            this.ShowSubmitBtn = false;
            this.ShowEditBtn = true;
          } else {
            this.ShowSubmitBtn = true;
            this.ShowEditBtn = false;
          }

          this.formData.AppointmentDateTime = null;
          const [jy, jm, jd] = data.AppointmentDateTime.split("-").map(Number);
          const dayDate = `${jy}-${jm}-${jd}`;
          this.formData.AppointmentDateTime = dayDate;
          this.DayLabl = this.getDayLabelFromDate(dayDate);
          this.DayDate = `${jd}-${jm}-${jy}`;
        }
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },

    async submitForm() {
      this.messages.errors = {};
      this.validateForm();

      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.RegisterUser = this.userID
          this.formData.OphtPhysician = this.SelectedPatient.admissions.opht_physician_name;
          const { status } = await api.post(`discharge-detail/${this.SelectedPatient.admissions.AdmissionCode}/`, { ...this.formData });

          this.showSnippet = false;
          this.$refs.messageComponent.showMessage(status);
        } catch (error) {
          this.showSnippet = false;
          if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
        }
      }
    },
    async editForm() {
      this.messages.errors = {};
      this.validateForm();

      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.UpdateUser = this.userID
          const { status } = await api.put(`discharge-detail/${this.SelectedPatient.admissions.AdmissionCode}/`, { ...this.formData });
          this.$refs.messageComponent.showMessage(status);
          this.showSnippet = false;
        } catch (error) {
          this.showSnippet = false;
          if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
        }
      }
    },

    async CancelEdit() {
      this.formData.AppointmentLabel = null;
      this.formData.AppointmentDateTime = null;
      this.DayLabl = "";
      const { data } = await api.get(`discharge-detail/${this.SelectedPatient.admissions.AdmissionCode}/`);
      this.formData = { ...this.formData, ...data };
    },

    async delAppointment() {
      try {
        const { status } = await api.delete(`appointment/${this.SelectedPatient.admissions.AdmissionCode}/delete/`);
        this.$refs.messageComponent.showMessage(status);
        if (status === 204) this.clearDate();
      } catch (error) {
        if (error.response) this.$refs.messageComponent.showMessage(error.response);
      }
    },

    CalculateDate() {
      let daysToAdd = null;
      switch (this.formData.AppointmentLabel) {
        case "یک هفته":
          daysToAdd = 7;
          break;
        case "دو هفته":
          daysToAdd = 14;
          break;
        case "سه هفته":
          daysToAdd = 21;
          break;
        case "چهار هفته":
          daysToAdd = 28;
          break;
        case "یک ماه":
          daysToAdd = 30;
          break;
        case "دو ماه":
          daysToAdd = 60;
          break;
        case "سه ماه":
          daysToAdd = 90;
          break;
        case "شش ماه":
          daysToAdd = 180;
          break;
        case "یک سال":
          daysToAdd = 365;
          break;
        case "دو سال":
          daysToAdd = 730;
          break;
        default:
          daysToAdd = null;
      }

      if (daysToAdd !== null) {
        const today = jalaali.toJalaali(new Date());
        this.NextDateLbl = this.addDaysToJalaliDate(today, daysToAdd);
      } else {
        this.NextDateLbl = null;
      }
    },

    validateForm() {
      this.messages.errors = {};
      const fields = [
        { field: "DischargeDate", message: "تاریخ ترخیص را وارد کنید" },
        { field: "DischargeState", message: "وضعیت ترخیص را انتخاب کنید" },
        { field: "ReferralTo", message: "ارجاع را انتخاب کنید" }
      ];
      fields.forEach(({ field, message }) => {
        if (!this.formData[field]) this.messages.errors[field] = message;
      });
      return Object.keys(this.messages.errors).length === 0;
    },

    resetForm() {
      Object.assign(this.formData, {
        DischargeDate: null,
        DischargeState: null,
        ReferralTo: null,
        AppointmentLabel: null
      });
    },

    clearDate() {
      this.formData.AppointmentLabel = null;
      this.formData.AppointmentDateTime = null;
      this.DayDate = null;
      this.DayLabl = null;
    },

    showMessage(type, state, message) {
      this.messages[`show${type}`] = state;
      this.messages[type] = message;
    },

    PatientListForm() {
      this.$emit("ShowDischargeListForm");
    },

    addDaysToJalaliDate(date, daysToAdd) {
      const gregorianDate = jalaali.toGregorian(date.jy, date.jm, date.jd);
      const gregorianDateObj = new Date(gregorianDate.gy, gregorianDate.gm - 1, gregorianDate.gd);
      gregorianDateObj.setDate(gregorianDateObj.getDate() + daysToAdd);
      const newJalaliDate = jalaali.toJalaali(gregorianDateObj.getFullYear(), gregorianDateObj.getMonth() + 1, gregorianDateObj.getDate());

      return {
        ...newJalaliDate,
        dayName: this.getJalaliDayName(gregorianDateObj.getDay())
      };
    },
    getJalaliDayName(dayIndex) {
      const jalaliDayNames = ["یکشنبه", "دوشنبه", "سه‌شنبه", "چهارشنبه", "پنجشنبه", "جمعه", "شنبه"];
      return jalaliDayNames[dayIndex];
    },
    getDayLabelFromDate(newVal) {
      const [jy, jm, jd] = newVal.split("-").map(Number);
      const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
      const gregorianDate = new Date(gy, gm - 1, gd);
      const dayIndex = gregorianDate.getDay();
      const dayName = this.getJalaliDayName(dayIndex);
      return dayName;
    }
  },
  watch: {
    NextDateLbl: {
      handler(newVal) {
        if (newVal) {
          this.DayLabl = this.NextDateLbl.dayName;
          this.formData.AppointmentDateTime = `${this.NextDateLbl.jy}-${this.NextDateLbl.jm}-${this.NextDateLbl.jd}`;
        }
      }
    },
    "formData.AppointmentDateTime": function (newVal) {
      if (newVal) {
        this.DayLabl = this.getDayLabelFromDate(newVal);
        const [jy, jm, jd] = newVal.split("-").map(Number);
        this.DayDate = `${jd}-${jm}-${jy}`;
      }
    }
  }
};
</script>

<style scoped>
.btn {
  max-width: 90px;
  text-align: center;
  margin-left: 10px;
  font-size: 10pt;
  font-weight: bold;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin: 0 5px 0 10px;
}

.RowDir {
  display: flex;
}

input,
select {
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
}
.error-message {
  color: red;
  font-size: 12px;
}

.errorBorder {
  border: 2px solid red;
}
.appointmentLbl {
  margin: 10px;
}
.appointmentLbl span {
  margin: 10px;
  color: blue;
  font-weight: bold;
}
</style>
