<template>
  <div class="navbar">
      <div class="logo">
        <router-link :to="{ name: 'Home' }">سامانه ثبت بیماری ROP</router-link>
      </div>
      <ul class="nav-links">
        <li v-if="hasAccess('Home')"><router-link :to="{ name: 'Home' }">صفحه اصلی</router-link></li>
        <li v-if="hasAccess('DataEntry')"><router-link :to="{ name: 'DataEntry' }" >ثبت اطلاعات</router-link></li>
        <li v-if="hasAccess('Dashboard')"><router-link :to="{ name: 'Dashboard' }">داشبورد</router-link></li>
        <li v-if="hasAccess('Setting')"><router-link :to="{ name: 'Setting' }" >تنظیمات</router-link></li>
        <li v-if="hasAccess('Setting')"><router-link :to="{ name: 'Support' }" style="border: none">پشتیبانی</router-link></li>
      </ul>

      <div class="user-section">
        <label for="user-name" class="btn btn-outline-light"><i class="bi bi-person-circle"></i> {{ this.userName }} </label>
        <router-link :to="{ name: 'Login' }" @click="logout" class="btn btn-outline-danger">
          <i class="bi bi-box-arrow-right"></i>
          خروج
        </router-link>
      </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import permissionMixin from '@/Mixin/permissionMixin';


export default {
  mixins: [permissionMixin],
  computed: {
    ...mapGetters(['userName', 'AccessLvl'])
  },
  methods: {
    ...mapActions(['ClearAuthToken']),
    async logout() {
      await this.ClearAuthToken();
      this.$router.push({ name: 'Login' });
    },
  }
};
</script>

<style scoped>
.navbar {
  max-width: 100vw;
  display: flex;
  background-color: #100c44;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
  align-items: center;
  height: 8vh;
}

.logo {
  width: 20vw;
  margin-right: 10px;
}
.logo a {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: rgb(250, 253, 54);
}

.nav-links {
  width: 60vw;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 30px;
}

.nav-links li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s ease;
  padding-left: 20px;
  border-left: 2px solid;
}

.nav-links li a:hover {
  color: #00bcd4;
}
.user-section {
  display: flex;
  position: absolute;
  left: 20px;
}
.btn-outline-light,
.btn-outline-danger {
  border: none;
}
.btn-outline-light:hover {
  background: none;
  color: #00bcd4;
}
.btn-outline-danger:hover {
  background: none;
  color: white;
}
</style>
