import CryptoJS from "crypto-js";

const SECRET_KEY = "your-secret-key"; // Ensure this is securely managed and not hardcoded

const state = {
  auth_token: localStorage.getItem("access_token") || null,
  user_id: decrypt(localStorage.getItem("user_id")) || "",
  user_name: decrypt(localStorage.getItem("user_name")) || "",
  access_levels: decryptArray(localStorage.getItem("access_levels")) || []
};

const mutations = {
  SET_AUTH_TOKEN(state, { token,user_id, user_name, access_Levels }) {
    state.auth_token = token;
    localStorage.setItem("access_token", token);

    const encryptedUserID = encrypt(user_id);
    state.user_id = user_id;
    localStorage.setItem("user_id", encryptedUserID);

    const encryptedUserName = encrypt(user_name);
    state.user_name = user_name;
    localStorage.setItem("user_name", encryptedUserName);

    const encryptedAccessLevels = encryptArray(access_Levels);
    state.access_levels = access_Levels;
    localStorage.setItem("access_levels", encryptedAccessLevels);
  },

  CLEAR_AUTH(state) {
    state.auth_token = null;
    state.access_levels = [];
    state.user_id = "";
    state.user_name = "";
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("access_levels");
  }
};

const actions = {
  setAuthToken({ commit }, accessToken) {
    const base64Url = accessToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const user_id = JSON.parse(jsonPayload).userId.toString();
    const user_name = JSON.parse(jsonPayload).username.toString();
    const access_Levels = JSON.parse(jsonPayload).accessLevels;

    commit("SET_AUTH_TOKEN", { token: accessToken, user_id, user_name, access_Levels });
  },

  ClearAuthToken({ commit }) {
    commit("CLEAR_AUTH");
  }
};

const getters = {
  isAuthenticated: state => !!state.auth_token,
  authToken: state => state.auth_token,
  userID: state => state.user_id,
  userName: state => state.user_name,
  AccessLvl: state => state.access_levels
};

export default {
  state,
  mutations,
  actions,
  getters
};

// Encryption and Decryption Utilities
function encrypt(text) {
  return CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
}

function decrypt(cipherText) {
  if (!cipherText) return null;
  const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}

function encryptArray(array) {
  const jsonString = JSON.stringify(array);
  return encrypt(jsonString);
}

function decryptArray(cipherText) {
  const decryptedText = decrypt(cipherText);
  return decryptedText ? JSON.parse(decryptedText) : [];
}
