<template>
  <div class="card text-center square-border">
    <div
      class="card-header"
      :style="{ 'background-color': EmptyAlert ? 'red' : '' }"
    >
      {{ title }}
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "EmptyAlert"],
};
</script>

<style scoped>
.card {
  margin-bottom:3px;
  padding: 3px;
  border: 1px rgb(55, 174, 230) solid;
}
.card-body {
  padding: 3px;
  margin: 0;
}

.card-header {
  padding: 2px;
  margin: 2px;
  text-align: center;
  font-size: 10pt;
  font-weight: bold;
  background-color: rgb(200, 219, 236);

  border-radius: 5px;
}
.square-border {
  border-radius: 0;
}
</style>
