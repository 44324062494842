import byLocation from "../../../pages/Reporting/charts/byLocation.vue";
import store from "../../../Store/store"; // Import your Vuex store

export default [
  {
    path: "/Dashboard/byLocation",
    name: "byLocation",
    component: byLocation,
    beforeEnter: (to, from, next) => {
      store.dispatch("SetRSideActiveBtn", "byLocation");
      next();
    },
  },
];
