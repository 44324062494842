<template>
  <MessageDisplay v-if="type" :type="type" :message="message" class="MessageBox" @showMessageBox="showMessage" />
</template>

<script>
import MessageDisplay from "../UI/TheMessages.vue";

export default {
  components: { MessageDisplay },
  data() {
    return {
      type: null,
      message: null,
    };
  },
  methods: {
    showMessage(status) {      
      switch (status) {
        case 201:
          this.type = "success";
          this.message = "رکورد جدید با موفقیت ایجاد شد";
          break;
        case 200:
          this.type = "success";
          this.message = "درخواست با موفقیت انجام شد";
          break;
        case 204:
          this.type = "success";
          this.message = "اطلاعات با موفقیت حذف شد";
          break;
        case 409:
          this.type = "warning";
          this.message = "رکورد تکراری است";
          break;
        case 400:
          this.type = "error";
          this.message = "درخواست نامعتبر است - یافت نشد";
          break;
        case 404:
          this.type = "error";
          this.message = "اطلاعات یافت نشد";
          break;
        case 500:
          this.type = "error";
          this.message = "اطلاعات یافت نشد";
          break;
        case 1000:
          this.type = "warning";
          this.message = "اطلاعاتی برای بیمار ثبت نشده است";
          break;
        default:
          this.type = "error";
          this.message = "در ثبت اطلاعات خطا رخ داده است";
      }
      setTimeout(() => {
      this.resetMessage();
    }, 3000);
    },

    resetMessage() {
      this.type = null;
      this.message = null;
    },
  },
};
</script>

<style scoped>
.MessageBox {
  position: absolute;
  left: 0;
}
</style>
