import TheMedhistory from "../../../pages/DataEntry/MedicalHisotry/TheMedhistory.vue";
import MedicalHistory from "../../../pages/DataEntry/MedicalHisotry/MedicalHistory.vue";
import store from '../../../Store/store'; 

export default [
  {
    path: "/DataEntry/MedicalHistory",
    name: "MedicalHistory",
    component: TheMedhistory,
    beforeEnter: (to, from, next) => {
      store.dispatch('SetRSideActiveBtn', 'MedHistory');
      next();
    },
    children: [
      {
        path: "",
        name: "PatientMedicalHistory",
        component: MedicalHistory,
      },
    ],
  },
];