import LocPercent from "../../../pages/Reporting/charts/LocPercent.vue";
import store from "../../../Store/store"; // Import your Vuex store

export default [
  {
    path: "/Dashboard/LocPercent",
    name: "LocPercent",
    component: LocPercent,
    beforeEnter: (to, from, next) => {
      store.dispatch("SetRSideActiveBtn", "LocPercent");
      next();
    },
  },
];
