<template>
  <div class="content">
    <follow-up-list v-if="ShowFollowUpList" @ShowFollowUpForm="ActiveFollowUpForm" :selectedForm="activeForm"></follow-up-list>
    <follow-up-patient v-if="ShowFollowUpForm" @ShowFollowUpListForm="ActiveFollowUpList" :selectedForm="activeForm"></follow-up-patient>
  </div>
</template>

<script>
import FollowUpList from "./FollowUpList.vue";
import FollowUpPatient from "./FollowUpPatient.vue";

export default {
  components: {
    FollowUpList,
    FollowUpPatient
  },
  data() {
    return {
      ShowFollowUpList: true,
      ShowFollowUpForm: false
    };
  },
  methods: {
    ActiveFollowUpList() {
      this.ShowFollowUpList = true;
      this.ShowFollowUpForm = false;
    },
    ActiveFollowUpForm() {
      this.ShowFollowUpList = false;
      this.ShowFollowUpForm = true;
    }
  }
};
</script>
<style scoped>
hr {
  margin: 5px;
}
</style>
