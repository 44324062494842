<template>
  <Message-Display ref="messageComponent" />
  <the-snippet v-if="showSnippet" />
  <div v-else class="slide-in">
    <div class="container">
      <div class="card DemographicCard slide-in">
        <div class="card-header">اطلاعات هویتی</div>
        <div class="card-body">
          <div v-for="(value, label) in demographicInfo" :key="label" class="info-item">
            <span class="field-label">{{ label }}:</span>
            <span class="field-value">{{ value }}</span>
          </div>
        </div>
      </div>

      <div class="card HistoryCard slide-in">
        <div class="card-header">تاریخچه بالینی</div>
        <div class="card-body">
          <div class="form-group">
            <label style="color: blue; font-weight: bold"> تاریخچه بالینی:</label>
            <button @click="fetchMedHistory('infant')" class="btn btn-outline-success btn-sm me-1" v-if="hasAccess('ShowInfantRecord')">نوزاد</button>
            <button @click="fetchMedHistory('parent')" class="btn btn-outline-primary btn-sm me-1" v-if="hasAccess('ShowParentRecord')">مادر</button>
          </div>
          <hr />
          <div v-for="(value, label) in clinicalInfo" :key="label" class="info-item">
            <span class="field-label">{{ label }}:</span>
            <span class="field-value">{{ value }}</span>
          </div>
        </div>
      </div>

      <div class="card MedicalHistoryCard slide-in">
        <div class="card-header">سابقه مراجعه</div>
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th v-for="(label, key) in tableHeaders" :key="key" @click="sortTable(key)">
                  {{ label }} <span v-if="sortKey === key">{{ sortOrder === "asc" ? "🔼" : "🔽" }}</span>
                </th>
                <th>OD-Stage</th>
                <th>OD-Zone</th>
                <th>OS-Stage</th>
                <th>OS-Zone</th>
                <th>ویزیت</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="admission in paginatedAdmissions" :key="admission.AdmissionCode">
                <td>{{ admission.AdmissionCode }}</td>
                <td>{{ admission.AdmissionDate }}</td>
                <td>{{ admission.DischargeDate || "-" }}</td>
                <td>{{ admission.admission_type }}</td>
                <td>{{ admission.admission_place }}</td>
                <td>{{ admission.nicu_physician_name }}</td>
                <td>{{ admission.opht_physician_name }}</td>
                <td v-if="admission.opht_intervention.length > 0">
                  <!-- Displaying first opht intervention details -->
                  {{ admission.opht_intervention[0].ODStage || "-" }}
                </td>
                <td v-else >-</td>
                <td v-if="admission.opht_intervention.length > 0">
                  {{ admission.opht_intervention[0].ODZone || "-" }}
                </td><td v-else >-</td>
                <td v-if="admission.opht_intervention.length > 0">
                  {{ admission.opht_intervention[0].OSStage || "-" }}
                </td><td v-else >-</td>
                <td v-if="admission.opht_intervention.length > 0">
                  {{ admission.opht_intervention[0].OSZone || "-" }}
                </td><td v-else >-</td>
                <td class="table-action">
                  <button
                    class="btn btn-outline-success btn-sm table-action-btn"
                    @click="fetchNICUHistory(admission.AdmissionCode)"
                    v-if="hasAccess('ShowNicuRecord')">
                    NICU
                  </button>
                  <button
                    class="btn btn-outline-primary btn-sm table-action-btn"
                    @click="fetchOphtHistory(admission.AdmissionCode)"
                    v-if="hasAccess('ShowOphtRecord')">
                    چشم
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-controls">
            <button @click="prevPage" :disabled="currentPage === 1" class="btn btn-primary btn-sm paging-btn">قبلی</button>
            <span>صفحه {{ currentPage }} از {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages" class="btn btn-success btn-sm paging-btn">بعدی</button>
            <select v-model="itemsPerPage" @change="updatePagination" class="form-select form-select-sm paging-items">
              <option :value="5">5</option>
              <option :value="10">10</option>
              <option :value="20">20</option>
            </select>
          </div>
        </div>
        <div class="action-buttons">
          <button type="button" class="btn btn-warning btn-sm m-2" @click="PatientListForm">لیست بیماران</button>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <transition name="history-cards" class="Infant-result-card">
        <div v-if="ShowInfantHistoryCard" class="result-container history-cards">
          <div class="result-header">سابقه بالینی نوزاد</div>
          <div class="result-body">
            <div v-for="(value, label) in MedHistData" :key="label">
              <div class="result-field" v-if="InfantMedHistLabel(label) && value !== null && value !== '' && value !== false">
                <label class="field-label">{{ InfantMedHistLabel(label) }}:</label>
                <label class="field-value">
                  <span v-if="Array.isArray(value)">{{ value.join(", ") }}</span>
                  <span v-else>{{ value }}</span>
                </label>
              </div>
            </div>
            <button @click="ShowInfantHistoryCard = false" class="btn btn-outline-danger btn-sm closeBtn">بستن</button>
          </div>
        </div>
      </transition>
      <transition name="history-cards" class="Mother-result-card">
        <div v-if="ShowParentHistoryCard" class="result-container history-cards">
          <div class="result-header">سابقه بالینی مادر</div>
          <div class="result-body">
            <div v-for="(value, label) in MedHistData" :key="label">
              <div class="result-field" v-if="ParentMedHistLabel(label) && value !== null && value !== '' && value !== false">
                <label class="field-label">{{ ParentMedHistLabel(label) }}:</label>
                <label class="field-value">
                  <span v-if="Array.isArray(value)">{{ value.join(", ") }}</span>
                  <span v-else>{{ value }}</span>
                </label>
              </div>
            </div>
            <button @click="ShowParentHistoryCard = false" class="btn btn-outline-danger btn-sm closeBtn">بستن</button>
          </div>
        </div>
      </transition>
      <!-- Medical History Cards -->
      <transition name="history-cards" class="NICU-result-card">
        <div v-if="ShowNicuCard" class="result-container history-cards">
          <div class="result-header">مداخلات NICU</div>
          <div class="result-body">
            <div v-for="(value, label) in NicuData" :key="label">
              <div class="result-field" v-if="NicuInterventionLabel(label, NicuData) && value !== null && value !== 0 && value !== '' && value !== false">
                <label class="field-label">{{ label }}</label>
                <label class="field-value">
                    <span>{{ NicuInterventionLabel(label, NicuData) }}</span>
                </label>
              </div>
            </div>
            <button @click="ShowNicuCard = false" class="btn btn-outline-danger btn-sm closeBtn">بستن</button>
          </div>
        </div>
      </transition>

      <transition name="history-cards" class="Opht-result-card">
        <div v-if="ShowOphtCard" class="result-container history-cards">
          <div class="result-header">معاینه چشم پزشکی</div>
          <div class="eye-body">
            <div class="eye-section">
              <h4 class="eye-title">چشم راست (OD)</h4>
              <div v-for="(value, label) in OphtData" :key="label">
                <div
                  v-if="label.startsWith('OD') && OphtalmologyLabel(label) && value !== null && value !== 0 && value !== '' && value !== false"
                  class="result-field">
                  <label class="field-label">{{ OphtalmologyLabel(label) }}:</label>
                  <label class="field-value">
                    <span v-if="Array.isArray(value)">{{ value.join(", ") }}</span>
                    <span v-else>{{ value }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="eye-section">
              <h4 class="eye-title">چشم چپ (OS)</h4>
              <div v-for="(value, label) in OphtData" :key="label">
                <div
                  v-if="label.startsWith('OS') && OphtalmologyLabel(label) && value !== null && value !== 0 && value !== '' && value !== false"
                  class="result-field">
                  <label class="field-label">{{ OphtalmologyLabel(label) }}:</label>
                  <label class="field-value">
                    <span v-if="Array.isArray(value)">{{ value.join(", ") }}</span>
                    <span v-else>{{ value }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button @click="ShowOphtCard = false" class="btn btn-outline-danger btn-sm closeBtn">بستن</button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import api from "../../../Api/TheApi.js";
import { mapGetters } from "vuex";
import permissionMixin from "@/Mixin/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      patient: {},
      MedHistData: {},
      NicuData: {},
      OphtData: {},
      currentPage: 1,
      itemsPerPage: 10,
      sortKey: "",
      sortOrder: "asc",
      ShowInfantHistoryCard: false,
      ShowParentHistoryCard: false,
      ShowNicuCard: false,
      ShowOphtCard: false,
      message: null,
      lastAppointment: {},
      insurance_name: null,
      showSnippet: true
    };
  },
  computed: {
    ...mapGetters(["SelectedPatient"]),
    demographicInfo() {
      return {
        "کدملی": this.patient.NewbornNationalCode,
        "پرونده": this.patient.id,
        "نام": this.patient.NewbornFirstName,
        "نام خانوادگی": this.patient.NewbornLastName,
        "نام پدر": this.patient.FatherFirstName,
        "جنسیت": this.patient.sex_name,
        "سن": `${this.patient.age_in_weeks} هفته`,
        "بیمه": this.insurance_name,
        "استان": this.patient.province_name,
        "شهرستان": this.patient.county_name,
        "آدرس": this.patient.Address,
        "تلفن": this.patient.Mobile,
        "موبایل": this.patient.HomePhone
      };
    },
    clinicalInfo() {
      // Safeguard against missing appointment data
      const appointment = this.lastAppointment || {};
      return {
        "آخرین پذیرش": this.patient.Last_admission_date,
        "نوبت بعدی": appointment.AppointmentDateTime || "-",
        "وضعیت": appointment.Status || "-",
        "پیگیری": appointment.Audited || "-"
      };
    },
    tableHeaders() {
      return {
        AdmissionCode: "کد",
        AdmissionDate: "پذیرش",
        DischargeDate: "ترخیص",
        admission_type: "نوع",
        admission_place: "بخش",
        nicu_physician_name: "پزشک NICU",
        opht_physician_name: "چشم پزشک"
      };
    },
    patientAdmissions() {
      return this.patient?.admissions || [];
    },
    sortedAdmissions() {
      return [...this.patientAdmissions].sort((a, b) => {
        if (!this.sortKey) return 0;
        return this.sortOrder === "asc" ? (a[this.sortKey] < b[this.sortKey] ? -1 : 1) : a[this.sortKey] > b[this.sortKey] ? -1 : 1;
      });
    },
    paginatedAdmissions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.sortedAdmissions.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.patientAdmissions.length / this.itemsPerPage);
    }
  },
  mounted() {
    this.fetchUserInfo();
  },
  methods: {
    async fetchUserInfo() {
      try {
        this.showSnippet = true;
        const code = this.SelectedPatient.NewbornNationalCode ? this.SelectedPatient.NewbornNationalCode : this.SelectedPatient.id;
        const type = this.SelectedPatient.NewbornNationalCode ? 'national-code' : 'record-code';
        const { data } = await api.get(`medical-record-detail/${code}/${type}/`);
        this.patient = data;
        this.paginatedAdmissions = data.admissions || [];

        this.insurance_name = this.paginatedAdmissions[0].insurance_type_name || null;
        this.lastAppointment = this.patient.appointments.length > 0 ? this.patient.appointments[0] : {};
        this.hasOphtData = this.paginatedAdmissions.some(admission => admission.opht_intervention && admission.opht_intervention.length > 0);
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    sortTable(key) {
      this.sortOrder = this.sortKey === key && this.sortOrder === "asc" ? "desc" : "asc";
      this.sortKey = key;
    },
    getSortClass(key) {
      return this.sortKey === key ? this.sortOrder : "";
    },
    updatePagination() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
    },
    showMessage(type, state, message) {
      this.messages[`show${type}`] = state;
      this.messages[type] = message;
    },

    async fetchMedHistory(type) {
      try {
        const { status, data } = await api.get(`medical-history-detail/${this.patient.id}`);
        if (status === 200) {
          this.MedHistData = { ...data };
          if (type === "infant") this.ShowInfantHistoryCard = true;
          else this.ShowParentHistoryCard = true;
        }
      } catch (error) {
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async fetchNICUHistory(admission_code) {
      try {
        const { status, data } = await api.get(`nicu-detail/${admission_code}`);
        if (status === 200) {
          this.NicuData = { ...data };
          this.ShowNicuCard = true;
        }
      } catch (error) {
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async fetchOphtHistory(admission_code) {
      try {
        const { status, data } = await api.get(`ophthalmology-detail/${admission_code}`);
        if (status === 200) {
          this.OphtData = { ...data };
          this.ShowOphtCard = true;
        }
      } catch (error) {
        if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    InfantMedHistLabel(key) {
      const labels = {
        CongenitalAnomalyType: "نوع ناهنجاری مادرزادی",
        ComplicationsLowWeightType: "عوارض وزن کم",
        BreastFeedingType: "نوع تغذیه",
        IntravenousNutritionType: "تغذیه وریدی",
        ApgarScore: "امتیاز آپگار",
        MultipleBirth: "تولد چندگانه",
        ChildNuminMBirth: "تعداد فرزندان در تولد چندگانه",
        WeightatBirth: "وزن هنگام تولد",
        CPRHistory: "سابقه CPR",
        FeedingPowderedMilk: "تغذیه با شیر خشک"
      };
      return labels[key] || null;
    },
    ParentMedHistLabel(key) {
      const labels = {
        PregnancyType: "نوع بارداری",
        DeliveryType: "نوع زایمان",
        IllnessHistoryType: "تاریخچه بیماری",
        PregnancyTime: "مدت بارداری",
        PregnancyTermination: "خاتمه بارداری",
        AbortionHistory: "سابقه سقط جنین",
        PregnancyComplicationType: "نوع عارضه بارداری"
      };
      return labels[key] || null;
    },
    NicuInterventionLabel(key, NicuData) {


      // Define corresponding days and hours keys for each intervention
      const daysKey = key + "Days";
      const hoursKey = key + "Hours";
      const timesKey = key + "Times";

      // Check if both days and hours exist in NicuData
      const days = NicuData[daysKey] || 0;
      const hours = NicuData[hoursKey] || 0;
      const times = NicuData[timesKey] || 0;

      // If both exist, return the combined string
      if (days || hours) {
        return `${days} روز ${hours} ساعت`;
      }
      else if (times) return `${times} بار`;
      else return null;
    },
    OphtalmologyLabel(key) {
      const labels = {
        ODStage: "Stage",
        ODZone: "Zone",
        ODFCR: "FCR",
        ODSPH: "SPH",
        ODCYL: "CYL",
        ODPlusDisease: "Plus Disease",
        ODThresholdDisease: "Threshold Disease",
        ODExtraretinalNVE: "Extraretinal NVE",
        ODConjusctivitiesIn: "التهاب ملتحمه داخلی",
        ODConjusctivitiesOut: "التهاب ملتحمه خارجی",
        ODRecommendations: "توصیه‌ها",
        ODOtherFindings: "یافته‌های دیگر",

        OSStage: "Stage",
        OSZone: "Zone",
        OSFCR: "FCR",
        OSSPH: "SPH",
        OSCYL: "CYL",
        OSPlusDisease: "Plus Disease",
        OSThresholdDisease: "Threshold Disease",
        OSExtraretinalNVE: "Extraretinal NVE",
        OSConjusctivitiesIn: "التهاب ملتحمه داخلی",
        OSConjusctivitiesOut: "التهاب ملتحمه خارجی",
        OSRecommendations: "توصیه‌ها",
        OSOtherFindings: "یافته‌های دیگر"
      };
      return labels[key] || key;
    },

    PatientListForm() {
      this.$emit("ShowPatientListForm");
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  min-width: 90vw;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.card {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-left: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.DemographicCard {
  width: 18%;
}
.HistoryCard {
  width: 19%;
}
.card-header {
  background-color: #007bff;
  color: white;
  padding: 5px;
  font-size: 12pt;
  font-weight: bold;
  text-align: center;
}
.MedicalHistoryCard {
  width: 66%;
}
.card-body {
  padding: 10px;
  background-color: #ffffff;
  color: #333;
}

.Infant-result-card,
.Mother-result-card,
.NICU-result-card {
  width: 20%;
}
.Opht-result-card {
  width: 38%;
}

/* Base styles for the card */
.history-cards {
  transform: translateY(5px);
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
}

.OphthCards {
  display: flex;
  margin: 3px;
}
.closeBtn {
  display: flex;
  position: absolute;
  top: 1%;
  left: 5px;
  padding: 2px;
  width: 30px;
  font-size: 8pt;
  font-weight: bold;
  text-align: center;
}
/* Entering phase: when the card is added */
.history-cards-enter-active {
  opacity: 0;
  transform: translateY(0); /* Move into place */
}

.history-cards-enter,
.history-cards-leave-to {
  opacity: 1;
  transform: translateY(20px); /* Start or end above */
}

/* Leaving phase: when the card is removed */
.history-cards-leave-active {
  opacity: 0;
  transform: translateY(20px); /* Move back above */
}

.info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
}

.result-container {
  background-color: #fff;
  padding: 2px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 2px;
}

.result-header {
  font-size: 11pt;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 2px;
  text-align: center;
  border-bottom: 2px solid #007bff;
  padding: 2px;
  margin: 0 10% 0 10%;
}

.result-body {
  display: flex;
  flex-direction: column;
  background-color: #f4f8f8;
  gap: 3px;
}

.eye-body {
  display: flex;
  gap: 5px;
}

.eye-section {
  background-color: #f4f8f8;
  padding: 2px;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  flex: 1;
}

.eye-title {
  font-size: 10pt;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 5px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
  text-align: center;
}

.result-field {
  display: flex;
  justify-content: flex-start;
  padding: 3px 0;
  border-bottom: 1px solid #dee2e6;
}


.field-label {
  justify-content: flex-end;
  font-weight: bold;
  color: #333;
  min-width: 40%;
  text-align: right;
}

.field-value {
  color: #666;
  margin-left: 3px;
  min-width: 60%;
  text-align: left;
  padding-left: 5px;
}

.table {
  border-collapse: collapse;
  font-size: 9pt;
}

.table th,
.table td {
  text-align: center;
  border: 1px solid #dee2e6;
  padding: 3px;
  justify-content: center;
  align-items: center;
}

.table th {
  background-color: #f1f1f1;
  color: #007bff;
  cursor: pointer;
  border: 1px solid #ccc;
  justify-content: center;
}

.table tr:hover {
  background-color: #f9f9f9;
}

.table-action {
  width: 100px;
}
.table-action-btn {
  font-size: 9pt;
  width: 40px;
  margin-left: 3px;
  padding: 0;
}
.sort-icon {
  font-size: 0.8em;
  margin-left: 5px;
  color: #007bff;
}

.sort-icon.asc {
  color: #28a745;
}

.sort-icon.desc {
  color: #dc3545;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9pt;
  gap: 10px;
  margin-top: 20px;
}

pre {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  white-space: pre-wrap;
}

.paging-btn {
  width: 40px;
  padding: 2px;
}
.paging-items {
  width: 100px;
}

/* Define the keyframes for the slide-in animation */
@keyframes slide-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(-20px); /* Starts 20px below its original position */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Ends at its original position */
  }
}

/* Apply the animation to elements with the class 'slide-in' */
.slide-in {
  animation: slide-in-from-bottom 0.5s ease-out;
}
</style>
