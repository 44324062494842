<template>
  <div class="container slide-in">
    <Message-Display ref="messageComponent"/>
    
    <div class="searchBox">
      <div v-for="(label, key) in searchFields" :key="key" class="input-block">
        <input :type="key === 'NationalCode' ? 'number' : 'text'" required v-model="search[key]" />
        <span class="placeholder">{{ label }}</span>
        <span class="clear-icon" @click="clearInput(key)" v-if="search[key]">✖</span>
      </div>
      <button @click="SearchUser" class="btn btn-primary">جستجو</button>
    </div>

    <hr />

    <div class="newUser">
      <div v-for="(label, key) in formFields" :key="key" class="fromData-group">
        <label>{{ label }}:</label>
        <input :type="key === 'password' ? 'password' : key === 'email' ? 'email' : key === 'national_code' || key === 'phone' ? 'number' : 'text'" 
               v-model="formData[key]" required @input="key === 'role_name' ? fetchRoles() : null" :list="key === 'role_name' ? 'rolesList' : null" />
        <datalist v-if="key === 'role_name'" id="rolesList">
          <option v-for="role in roleList" :key="role.id" :value="role.role_name"></option>
        </datalist>
      </div>

      <div class="fromData-group">
        <label>super user:</label>
        <select v-model="formData.is_superuser_display">
          <option>بلی</option>
          <option>خیر</option>
        </select>
      </div>

      <div class="fromData-group">
        <label>is staff:</label>
        <select v-model="formData.is_staff_display">
          <option>بلی</option>
          <option>خیر</option>
        </select>
      </div>

      <div class="fromData-group">
        <label>وضعیت کاربر:</label>
        <select v-model="formData.is_active_display">
          <option>فعال</option>
          <option>غیرفعال</option>
        </select>
      </div>
    </div>

    <hr />

    <div class="OperationBtn">
      <button v-if="ShowSubmitBtn" @click="CreateUser" class="btn btn-success">ثبت</button>
      <button v-if="ShowEditBtn" @click="UpdateUser" class="btn btn-primary">ویرایش</button>
      <button v-if="ShowDelBtn" @click="DeleteUser" class="btn btn-danger">حذف</button>
      <button @click="ResetFields" class="btn btn-warning">پاک کردن</button>
    </div>
  </div>
</template>

<script>
import api from '../../Api/TheApi';

export default {
  data() {
    return {
      search: { NationalCode: null, FirstName: null, LastName: null },

      formData: { username: '', password: '', first_name: '', last_name: '', national_code: '',
      phone: '', email: '', is_superuser_display: '', is_staff_display: '', role_name: '', is_active_display: '' },
      
      ShowSubmitBtn: true,
      ShowEditBtn: false,
      ShowDelBtn: false,
      roleList: [],

      searchFields: {
        NationalCode: 'کد ملی',
        FirstName: 'نام',
        LastName: 'نام خانوادگی'
      },

      formFields: {
        username: 'نام کاربری',
        password: 'رمز ورود',
        first_name: 'نام',
        last_name: 'نام خانوادگی',
        national_code: 'کد ملی',
        phone: 'شماره تماس',
        email: 'ایمیل',
        role_name: 'پست سازمانی'
      }
    };
  },
  mounted() {
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      try {
        const { data } = await api.get('/roles');
        this.roleList = data;
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async SearchUser() {
      try {        
        const { data, status } = await api.get('/users', { params: this.search });
        this.$refs.messageComponent.showMessage(status)
        if (data.length > 0) {
          Object.assign(this.formData, data[0]);
          this.toggleButtons({ submit: false, edit: true, delete: true });
        }
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async CreateUser() {
      try {
        const { status } = await api.post('/users', this.formData);
        if (status === 201) this.resetForm(status);
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async UpdateUser() {
      try {
        const { status } = await api.put('/users', this.formData);
        if (status === 200) this.resetForm(status);
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    async DeleteUser() {
      try {
        const { status } = await api.delete('/users', { params: this.search });
        if (status === 204) this.resetForm(status);
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },
    resetForm(status) {
      this.$refs.messageComponent.showMessage(status);
      this.ResetFields();
    },
    ResetFields() {
      Object.keys(this.formData).forEach(key => (this.formData[key] = ''));
      this.toggleButtons({ submit: true, edit: false, delete: false });
    },
    toggleButtons({ submit, edit, delete: del }) {
      this.ShowSubmitBtn = submit;
      this.ShowEditBtn = edit;
      this.ShowDelBtn = del;
    },
    clearInput(field) {
      this.search[field] = '';
    },
  }
};
</script>

<style scoped>
.container {
  max-width: 90vw;
}
.searchBox, .newUser, .OperationBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.fromData-group {
  display: flex;
  justify-content: center;
  width: 34%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
input, select {
  width: 200px;
  height: 30px;
  border-radius: 5px;
  margin-left: 10px;
}
label {
  width: 20%;
  text-align: left;
  margin-left: 5px;
}
button {
  width: 100px;
  height: 40px;
  margin: 5px;
}
.OperationBtn {
  gap: 10px;
}
</style>
