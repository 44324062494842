import MedicalRecord from "../../../pages/DataEntry/MedicalRecord/TheMedRecord.vue";
import MedicalRecordDetail from "../../../pages/DataEntry/MedicalRecord/MedRecordPatient.vue";
import store from '../../../Store/store'; // Import your Vuex store

export default [
    {
      path: "/DataEntry/AdmissionHistory",
      name: "MedicalRecord",
      component: MedicalRecord,
      beforeEnter: (to, from, next) => {
        store.dispatch('SetRSideActiveBtn', 'MedicalRecord');
        next();
      },
      children: [
        {
          path: "details",
          name: "MedicalRecordDetails",
          component: MedicalRecordDetail,
        },
      ],
    },
  ];
  