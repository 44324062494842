<template>
  <Message-Display ref="messageComponent" />
  <the-snippet v-if="showSnippet" />

  <div v-else class="slide-in">
    <div class="RowDir">
      <div class="form-group">
        <label style="width: 180px !important">نوزاد اطلاعات هویتی ندارد:</label>
        <label class="toggle-switchy" for="without-identity" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="red">
          <input checked type="checkbox" id="without-identity" v-model="formData.WithoutIdentity" />
          <span class="toggle">
            <span class="switch"></span>
          </span>
        </label>
      </div>
      <div class="d-flex slide-in"  v-if="formData.WithoutIdentity">
        <label for="without-identity-discription">توضیحات:</label>
        <textarea
          type="text"
          id="without-identity-discription"
          v-model="formData.WithoutIdentityDesc"
          :class="{ errorBorder: messages.errors.WithoutIdentityDesc }"
          @click="this.messages.errors.WithoutIdentityDesc = ''" 
          style="width:800px; border-radius: 5px"/>
        <span class="error-message" v-if="messages.errors.WithoutIdentityDesc">{{ messages.errors.WithoutIdentityDesc }}</span>
      </div>
    </div>
    <hr />
    <div v-if="!formData.WithoutIdentity">
      <div class="slide-in">
        <div>
          <div class="data-entry-form">
            <h5>نوزاد</h5>
            <div class="data-section">
              <div class="RowDir">
                <div class="form-group">
                  <label for="infant-national-code">کد&zwnj;ملی : </label>
                  <input
                    type="text"
                    id="infant-national-code"
                    v-model="formData.NewbornNationalCode"
                    :class="{ errorBorder: messages.errors.NewbornNationalCode }"
                    @keydown="validateNumericInput"
                    @click="this.messages.errors.NewbornNationalCode = ''" />
                  <span class="error-message" v-if="messages.errors.NewbornNationalCode">{{ messages.errors.NewbornNationalCode }}</span>
                </div>
                <div class="form-group">
                  <label for="infant-first-name">نام: </label>
                  <input
                    type="text"
                    id="infant-first-name"
                    v-model="formData.NewbornFirstName"
                    :class="{ errorBorder: messages.errors.NewbornFirstName }"
                    @click="this.messages.errors.NewbornFirstName = ''" />
                  <span class="error-message" v-if="messages.errors.NewbornFirstName">{{ messages.errors.NewbornFirstName }}</span>
                </div>
                <div class="form-group">
                  <label for="infant-last-name">نام&zwnj;خانوادگی:</label>
                  <input
                    type="text"
                    id="infant-last-name"
                    v-model="formData.NewbornLastName"
                    :class="{ errorBorder: messages.errors.NewbornLastName }"
                    @click="this.messages.errors.NewbornLastName = ''" />
                  <span class="error-message" v-if="messages.errors.NewbornLastName">{{ messages.errors.NewbornLastName }}</span>
                </div>
                <div class="form-group">
                  <label for="infant-gender">جنسیت:</label>
                  <select
                    id="infant-gender"
                    v-model="formData.NewbornSex"
                    :class="{ errorBorder: messages.errors.NewbornSex }"
                    @click="this.messages.errors.NewbornSex = ''">
                    <option disabled value="">انتخاب کنید</option>
                    <option v-for="option in options.genderOptions" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.NewbornSex">{{ messages.errors.NewbornSex }}</span>
                </div>
              </div>

              <div class="RowDir">
                <div class="form-group">
                  <label for="infant-birth-date">تاریخ&zwnj;تولد:</label>
                  <Persian-Date
                    id="infant-birth-date"
                    class="PersianDate"
                    simple
                    v-model="formData.NewbornBirthDate"
                    popover="bottom-right"
                    :class="{ errorBorder: messages.errors.NewbornBirthDate }"
                    @click="this.messages.errors.NewbornBirthDate = ''" />
                  <span class="error-message" v-if="messages.errors.NewbornBirthDate">{{ messages.errors.NewbornBirthDate }}</span>
                </div>
                <div class="form-group">
                  <label for="infant-nationality">ملیت:</label>
                  <select
                    id="infant-nationality"
                    v-model="formData.NewbornNationality"
                    :class="{ errorBorder: messages.errors.NewbornNationality }"
                    @click="this.messages.errors.NewbornNationality = ''">
                    <option v-for="option in options.nationalityOptions" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.NewbornNationality">{{ messages.errors.NewbornNationality }}</span>
                </div>
                <div class="form-group">
                  <label for="infant-religion">مذهب:</label>
                  <select
                    id="infant-religion"
                    v-model="formData.ReligionType"
                    :class="{ errorBorder: messages.errors.ReligionType }"
                    @click="this.messages.errors.ReligionType = ''">
                    <option v-for="option in options.religionOptions" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.ReligionType">{{ messages.errors.ReligionType }}</span>
                </div>
                <div class="form-group">
                  <label for="infant-ethnicity">قومیت:</label>
                  <select
                    id="infant-ethnicity"
                    v-model="formData.EthnicityType"
                    :class="{ errorBorder: messages.errors.EthnicityType }"
                    @click="this.messages.errors.EthnicityType = ''">
                    <option v-for="option in options.ethnicityOptions" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.EthnicityType">{{ messages.errors.EthnicityType }}</span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="data-entry-form">
            <h5>پدر</h5>
            <div class="data-section">
              <div class="RowDir">
                <div class="form-group">
                  <label for="father-first-name">نام: </label>
                  <input
                    type="text"
                    id="father-first-name"
                    v-model="formData.FatherFirstName"
                    :class="{ errorBorder: messages.errors.FatherFirstName }"
                    @click="this.messages.errors.FatherFirstName = ''" />
                  <span class="error-message" v-if="messages.errors.FatherFirstName">{{ messages.errors.FatherFirstName }}</span>
                </div>

                <div class="form-group">
                  <label for="father-birth-date">تاریخ&zwnj;تولد:</label>
                  <Persian-Date
                    id="father-birth-date"
                    v-model="formData.FatherBirthDate"
                    :class="{ errorBorder: messages.errors.FatherBirthDate }"
                    @click="this.messages.errors.FatherBirthDate = ''" />

                  <span class="error-message" v-if="messages.errors.FatherBirthDate">{{ messages.errors.FatherBirthDate }}</span>
                </div>
                <div class="form-group">
                  <label for="father-education">تحصیلات:</label>
                  <select
                    id="father-education"
                    v-model="formData.FatherEducation"
                    :class="{ errorBorder: messages.errors.FatherEducation }"
                    @click="this.messages.errors.FatherEducation = ''">
                    <option disabled value="">انتخاب کنید</option>
                    <option v-for="option in options.educationOptions" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.FatherEducation">{{ messages.errors.FatherEducation }}</span>
                </div>
                <div class="form-group">
                  <label for="father-occupation">شغل:</label>
                  <select
                    id="father-occupation"
                    v-model="formData.FatherOccupation"
                    :class="{ errorBorder: messages.errors.FatherOccupation }"
                    @click="this.messages.errors.FatherOccupation = ''">
                    <option disabled value="">انتخاب کنید</option>
                    <option v-for="option in options.occupationOptions" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.FatherOccupation">{{ messages.errors.FatherOccupation }}</span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="data-entry-form">
            <h5>مادر</h5>
            <div class="data-section">
              <div class="RowDir">
                <div class="form-group">
                  <label for="mother-national-code">کد&zwnj;ملی : </label>
                  <input
                    type="text"
                    id="mother-national-code"
                    v-model="formData.MotherNationalCode"
                    :class="{ errorBorder: messages.errors.MotherNationalCode }"
                    @keydown="validateNumericInput"
                    @click="this.messages.errors.MotherNationalCode = ''" />
                  <span class="error-message" v-if="messages.errors.MotherNationalCode">{{ messages.errors.MotherNationalCode }}</span>
                </div>
                <div class="form-group">
                  <label for="mother-first-name">نام: </label>
                  <input
                    type="text"
                    id="mother-first-name"
                    v-model="formData.MotherFirstName"
                    :class="{ errorBorder: messages.errors.MotherFirstName }"
                    @click="this.messages.errors.MotherFirstName = ''" />
                  <span class="error-message" v-if="messages.errors.MotherFirstName">{{ messages.errors.MotherFirstName }}</span>
                </div>
                <div class="form-group">
                  <label for="mother-last-name">نام&zwnj;خانوادگی:</label>
                  <input
                    type="text"
                    id="mother-last-name"
                    v-model="formData.MotherLastName"
                    :class="{ errorBorder: messages.errors.MotherLastName }"
                    @click="this.messages.errors.MotherLastName = ''" />
                  <span class="error-message" v-if="messages.errors.MotherLastName">{{ messages.errors.MotherLastName }}</span>
                </div>

                <div class="form-group">
                  <label for="mother-birth-date">تاریخ&zwnj;تولد:</label>
                  <Persian-Date
                    id="mother-birth-date"
                    class="PersianDate"
                    v-model="formData.MotherBirthDate"
                    :class="{ errorBorder: messages.errors.MotherBirthDate }"
                    @click="this.messages.errors.MotherBirthDate = ''" />
                  <span class="error-message" v-if="messages.errors.MotherBirthDate">{{ messages.errors.MotherBirthDate }}</span>
                </div>
              </div>
              <div class="RowDir">
                <div class="form-group">
                  <label for="mother-education">تحصیلات:</label>
                  <select
                    id="mother-education"
                    v-model="formData.MotherEducation"
                    :class="{ errorBorder: messages.errors.MotherEducation }"
                    @click="this.messages.errors.MotherEducation = ''">
                    <option disabled value="">انتخاب کنید</option>
                    <option v-for="option in options.educationOptions" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.MotherEducation">{{ messages.errors.MotherEducation }}</span>
                </div>
                <div class="form-group">
                  <label for="mother-occupation">شغل:</label>
                  <select
                    id="mother-occupation"
                    v-model="formData.MotherOccupation"
                    :class="{ errorBorder: messages.errors.MotherOccupation }"
                    @click="this.messages.errors.MotherOccupation = ''">
                    <option disabled value="">انتخاب کنید</option>
                    <option v-for="option in options.occupationOptions" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.MotherOccupation">{{ messages.errors.MotherOccupation }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="data-entry-form">
          <h5>ازدواج</h5>
          <div class="data-section">
            <div class="familyMarriage familyMarriageRow">
              <label class="familyMarriageLabel">ازدواج&zwnj;فامیلی:</label>
              <div class="familyMarriageOption">
                <input
                  type="radio"
                  id="family-marriage-yes"
                  v-model="formData.FamilyMarriage"
                  value="Yes"
                  :class="{ errorBorder: messages.errors.FamilyMarriage }"
                  @click="this.messages.errors.FamilyMarriage = ''" />
                <label for="family-marriage-yes">بلی</label>
              </div>
              <div class="familyMarriageOption">
                <input
                  type="radio"
                  id="family-marriage-no"
                  v-model="formData.FamilyMarriage"
                  value="No"
                  :class="{ errorBorder: messages.errors.FamilyMarriage }"
                  @click="this.messages.errors.FamilyMarriage = ''" />
                <label for="family-marriage-no">خیر</label>
              </div>
              <span class="error-message" v-if="messages.errors.FamilyMarriage">{{ messages.errors.FamilyMarriage }}</span>
            </div>
          </div>
          <div class="familyMarriage familyMarriageRow">
            <label class="familyMarriageLabel">سابقه&zwnj;خانوادگی:</label>
            <div class="familyMarriageOption">
              <input
                type="radio"
                id="family-history-yes"
                v-model="formData.FamilyHistory"
                value="Yes"
                :class="{ errorBorder: messages.errors.FamilyHistory }"
                @click="this.messages.errors.FamilyHistory = ''" />
              <label for="family-history-yes">بلی</label>
            </div>
            <div class="familyMarriageOption">
              <input
                type="radio"
                id="family-history-no"
                v-model="formData.FamilyHistory"
                value="No"
                :class="{ errorBorder: messages.errors.FamilyHistory }"
                @click="this.messages.errors.FamilyHistory = ''" />
              <label for="family-history-no">خیر</label>
            </div>
            <span class="error-message" v-if="messages.errors.FamilyHistory">{{ messages.errors.FamilyHistory }}</span>
          </div>
        </div>
        <hr />
        <div class="data-entry-form">
          <h5>سکونت</h5>
          <div class="data-section">
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="village-resident"
                data-size="md"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="blue">
                <input type="checkbox" id="village-resident" v-model="formData.VillageResident" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label ms-2">ساکن روستا:</span>
              </label>
            </div>
            <div class="RowDir">
              <div class="d-flex flex-column">
                <div class="AddressBar">
                  <label for="province">استان:</label>
                  <select
                    id="province"
                    v-model="formData.Province"
                    :class="{ errorBorder: messages.errors.Province }"
                    @click="this.messages.errors.Province = ''">
                    <option disabled value="">انتخاب کنید</option>
                    <option v-for="province in options.provinceOptions" :value="province" :key="province">
                      {{ province }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.Province">{{ messages.errors.Province }}</span>
                  <label for="County">شهرستان:</label>
                  <select
                    id="County"
                    v-model="formData.County"
                    :class="{ errorBorder: messages.errors.County }"
                    @click="this.messages.errors.County = ''">
                    <option disabled value="">انتخاب کنید</option>
                    <option v-for="county in options.countyOptions" :value="county" :key="county">
                      {{ county }}
                    </option>
                  </select>
                  <span class="error-message" v-if="messages.errors.County">{{ messages.errors.County }}</span>
                </div>
                <div class="AddressBar">
                  <label for="telephone-number">تلفن: </label>
                  <input
                    type="text"
                    id="telephone-number"
                    v-model="formData.HomePhone"
                    :class="{ errorBorder: messages.errors.HomePhone }"
                    @click="this.messages.errors.HomePhone = ''" 
                    @keydown="validateNumericInput"/>
                  <span class="error-message" v-if="messages.errors.HomePhone">{{ messages.errors.HomePhone }}</span>
                  <label for="mobile-number">موبایل: </label>
                  <input
                    type="text"
                    id="mobile-number"
                    v-model="formData.Mobile"
                    :class="{ errorBorder: messages.errors.Mobile }"
                    @click="this.messages.errors.Mobile = ''" 
                    @keydown="validateNumericInput"/>
                  <span class="error-message" v-if="messages.errors.Mobile">{{ messages.errors.Mobile }}</span>
                </div>
              </div>
              <div class="AddressBar">
                <label for="address" style="width: 15%">آدرس:</label>
                <textarea
                  type="text"
                  id="Address"
                  v-model="formData.Address"
                  :class="{ errorBorder: messages.errors.Address }"
                  @click="this.messages.errors.Address = ''" />
                <span class="error-message" v-if="messages.errors.Address">{{ messages.errors.Address }}</span>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div class="DataEntryButtons">
      <button class="btn btn-success btn-bg" v-if="ShowSubmitBtn" @click="submitAdmissionForm">پذیرش</button>
      <button class="btn btn-primary btn-bg" v-if="ShowSubmitBtn" @click="submitForm">ثبت</button>
      <button v-if="ShowEditBtn && hasAccess('EditDemographic')" class="btn btn-info btn-bg" @click="editForm">ویرایش</button>
      <button @click="ResetForm" class="btn btn-danger">پاک کردن</button>
      <router-link to="/DataEntry/Admission" type="button" @click="PatientListForm" class="btn btn-warning"> لیست بیماران </router-link>
    </div>
  </div>
</template>

<script>
import api from "../../../Api/TheApi.js";
import { mapActions, mapGetters } from "vuex";
import permissionMixin from "@/Mixin/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      formData: {
        WithoutIdentity: false,
        WithoutIdentityDesc: null, 
        NewbornNationalCode: null,
        NewbornFirstName: null,
        NewbornLastName: null,
        NewbornSex: null,
        NewbornBirthDate: null,
        NewbornNationality: "ایرانی",
        ReligionType: "شیعه",
        EthnicityType: "آذری",
        FatherFirstName: null,
        FatherBirthDate: null,
        FatherEducation: null,
        FatherOccupation: null,
        MotherNationalCode: null,
        MotherFirstName: null,
        MotherLastName: null,
        MotherBirthDate: null,
        MotherEducation: null,
        MotherOccupation: null,
        FamilyMarriage: "No",
        FamilyHistory: "No",
        VillageResident: false,
        Province: "زنجان",
        County: "زنجان",
        HomePhone: null,
        Mobile: null,
        Address: null,
        RegisterUser: null,
        UpdateUser: null
      },
      options: {
        genderOptions: [],
        nationalityOptions: [],
        religionOptions: [],
        ethnicityOptions: [],
        educationOptions: [],
        occupationOptions: [],
        provinceOptions: [],
        countyOptions: []
      },
      messages: {
        errors: {}
      },
      showSnippet: true,
      ShowSubmitBtn: true,
      ShowEditBtn: false,
      isItemOptionsLoaded: null
    };
  },

  computed: {
    ...mapGetters(["SelectedPatient", "userID"])
  },
  async created() {
    await this.fetchItemOptions();
    await this.fetchCounties();
  },
  mounted() {
    if (this.SelectedPatient.NewbornNationalCode || this.SelectedPatient.id) {
      this.fetchPatientInfo();
    }
  },

  methods: {
    ...mapActions(["setSelectedPatient"]),

    async fetchItemOptions() {
      try {
        const { data } = await api.get("Demographic/");
        this.options = { ...this.options, ...data };
      } catch (error) {
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },

    async fetchCounties() {
      try {
        const { data } = await api.get("County/", {
          params: { SelectedProvince: this.formData.Province }
        });
        if (data.length) {
          this.options.countyOptions = data;
          this.showSnippet = false;
        }
      } catch (error) {
        this.showSnippet = false;
        this.$refs.messageComponent.showMessage(error.response.status);
      }
    },

    validateNumericInput(event) {
      if (
        ["ArrowLeft", "ArrowRight", "Backspace", "Delete", "Tab", "Enter"].includes(event.key) ||
        /^[0-9]$/.test(event.key) ||
        (event.ctrlKey && (event.key === "v" || event.key === "c"))
      ) {
        return;
      }
      event.preventDefault();
    },

    validateForm() {
      const fields = {
        // NewbornLastName: "نام خانوادگی را وارد کنید",
        NewbornBirthDate: "تاریخ تولد را وارد کنید",
        // NewbornSex: "جنسیت را انتخاب کنید",
        // FatherFirstName: "نام پدر را وارد کنید",
        // MotherNationalCode: "کد ملی مادر را وارد کنید",
        // MotherFirstName: "نام مادر را وارد کنید",
        // Province: "استان را انتخاب کنید",
        // County: "شهرستان را انتخاب کنید",
        // Mobile: "موبایل را وارد کنید"
      };
      this.messages.errors = Object.entries(fields).reduce((acc, [key, message]) => {
        if (!this.formData[key] || (key.includes("NationalCode") && this.formData[key].length !== 10)) acc[key] = message;
        return acc;
      }, {});
    },

    async fetchPatientInfo() {
      try {
        let url;
        if (this.SelectedPatient.NewbornNationalCode)
          url = await api.get(`demographic-detail/${this.SelectedPatient.NewbornNationalCode}/national-code/`);
        else if (this.SelectedPatient.id) url = await api.get(`demographic-detail/${this.SelectedPatient.id}/record-code/`);
        this.showSnippet = true;
        const { data } = url;
        console.log(data);
        
        this.formData = { ...this.formData, ...data };
        this.ShowSubmitBtn = !this.ShowSubmitBtn;
        this.ShowEditBtn = !this.ShowEditBtn;
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (this.SelectedPatient.NewbornNationalCode) {
          this.$refs.messageComponent.showMessage(error.response.status);
        }
      }
    },

    async submitForm() {
      this.messages.errors = {};
      if (this.formData.WithoutIdentity === false) this.validateForm();
      let url;
      if (this.formData.WithoutIdentity === false) {
        this.validateForm();
        url = `demographic-detail/${this.formData.NewbornNationalCode}/national-code/`;
      } else {
        url = `demographic-detail/`;
      }
      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.RegisterUser = this.userID;
          const { status } = await api.post(url, { ...this.formData });
          if (status === 201) {
            this.$refs.messageComponent.showMessage(status);
            this.ResetForm();
            this.showSnippet = false;
            return true;
          }
        } catch (error) {
          this.showSnippet = false;
          this.$refs.messageComponent.showMessage(error.response.status);
        }
      }
      return false;
    },

    async submitAdmissionForm() {
      this.messages.errors = {};
      let url;
      if (this.formData.WithoutIdentity === false ) {
        this.validateForm();
        url = `demographic-detail/${this.formData.NewbornNationalCode}/national-code/`;
      } else {
        url = `demographic-detail/`;
      }
      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.RegisterUser = this.userID;
          const postResponse = await api.post(url, { ...this.formData });
          if (postResponse.status === 201) {
            let new_patient_info;
            if (this.formData.WithoutIdentity === false && this.formData.NewbornNationalCode != null)
              new_patient_info = `patient-info/${this.formData.NewbornNationalCode}/national-code/`;
            else {
              const record_code = postResponse.data.RecordCode;
              new_patient_info = `patient-info/${record_code}/record-code/`;
            }
            const getResponse = await api.get(new_patient_info);
            if (getResponse.status === 200) {
              this.showSnippet = false;
              this.setSelectedPatient(getResponse.data);
              this.$emit("ShowAdmission");
            } else {
              this.showSnippet = false;
              this.$refs.messageComponent.showMessage(getResponse.status);
            }
          }
          this.showSnippet = false;
        } catch (error) {
          this.showSnippet = false;
          if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
        }
      }
    },

    async editForm() {
      this.messages.errors = {};
      let url;
      if (this.SelectedPatient.id) {
        url = `demographic-detail/${this.SelectedPatient.id}/record-code/`;
      } else {
        this.validateForm();
        url = `demographic-detail/${this.formData.NewbornNationalCode}/national-code/`;
      }

      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.UpdateUser = this.userID;
          const { status } = await api.put(url, { ...this.formData });
          if (status === 200) {
            this.showSnippet = false;
            this.$refs.messageComponent.showMessage(status);
            return true;
          }
        } catch (error) {
          this.showSnippet = false;
          if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
          return false;
        }
      }
    },

    ResetForm() {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = key.includes("WithoutIdentityDesc")
          ? this.formData.WithoutIdentityDesc : null
          // key.includes("Name") || key.includes("Date")
          //   ? ""
          //   : key.includes("Gender") || key.includes("Nationality")
          //   ? "ایرانی"
          //   : key.includes("Religion")
          //   ? "شیعه"
          //   : key.includes("Ethnicity")
          //   ? "آذری"
          //   : key.includes("Province") || key.includes("County")
          //   ? "زنجان"
          //   : "";
      });
      this.messages.errors = {};
    },

    PatientListForm() {
      this.$emit("showPateintlist");
    }
  },
  watch: {
    "formData.Province": {
      handler(newVal) {
        if (newVal) this.fetchCounties();
      }
    },
    "formData.WithoutIdentity": {
      handler(newVal) {
        if (newVal == true) {this.ResetForm(); this.formData.WithoutIdentity=newVal;}
      }
    }
  }
};
</script>

<style scoped>
.data-entry-form {
  display: flex;
  width: 90vw;
  flex-direction: row;
}

.data-section {
  display: flex;
  flex-direction: column;
  padding: 5px;
  right: 0;
}

h5 {
  display: flex;
  width: 4vw;
  justify-content: center;
  align-items: center;
  color: #0065fd;
  font-size: 11pt;
  font-weight: bold;
  border-left: 1px solid #ccc;
}

.RowDir {
  display: flex;
  min-width: 80vw;
  text-align: right;
  padding: 5px;
}

.form-group {
  display: flex;
  width: 23%;
  justify-content: center;
  align-items: center;
  text-align: right;
}

label {
  width: 30%;
  display: flex;
  justify-content: left;
  padding: 0 10px;
  font-weight: bold;
}

input,
select {
  width: 65%;
}

.familyMarriage {
  display: flex;
  align-items: center;
}

.familyMarriageLabel {
  width: 50%;
}

.familyMarriageOption {
  display: flex;
  align-items: center;
}

.familyMarriageOption input[type="radio"] {
  display: none;
}

.familyMarriageOption label {
  cursor: pointer;
  padding: 5px 35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: center;
}

.familyMarriageOption label:hover {
  background-color: #f0f0f0;
}

.familyMarriageOption input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.familyMarriageOption input[type="radio"]:checked + label:hover {
  background-color: #0056b3;
}

.AddressBar {
  display: flex;
  margin: 5px 5px 0 0;
  border-radius: 5px;
}

#Address {
  width: 500px;
  border: solid 1px #ccc;
  border-radius: 4px;
}
.DataEntryButtons .btn {
  align-content: center;
  padding: 4px;
  margin: 0 5px 0 5px;
  width: 110px;
}

hr {
  padding: 0;
  margin: 5px;
}

.error-message {
  color: red;
}

.errorBorder {
  border: 3px solid red;
}

.MessageBox {
  position: absolute;
  left: 0;
}
</style>
