import byROP from "../../../pages/Reporting/charts/byROP.vue";
import store from "../../../Store/store"; // Import your Vuex store

export default [
  {
    path: "/Dashboard/byROP",
    name: "byROP",
    component: byROP,
    beforeEnter: (to, from, next) => {
      store.dispatch("SetRSideActiveBtn", "byROP");
      next();
    },
  },
];
