<template>
  <div class="container p-0">
    <div class="multi-select-container">
      <select
        v-model="selectedItem"
        @change="addItem"
        class="form-select p-0 text-center"
      >
        <option disabled value="">{{ placeholderText }}</option>
        <option v-for="item in items" :key="item" :value="item">
          {{ item }}
        </option>
      </select>
    </div>

    <div class="added-items d-flex flex-wrap">
      <div
        v-for="(addedItem, index) in addedItems"
        :key="index"
        class="added-item d-flex align-items-center"
      >
        <span class="selected-item-badge badge bg-light text-dark">{{
          addedItem
        }}</span>
        <button
          class="close-button btn btn-danger"
          @click="removeAddedItem(index)"
        >
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholderText: {
      type: String,
      default: "یک مورد را انتخاب کنید",
    },
    ResetItems: {
      type: Boolean,
    },
  },
  watch: {
    ResetItems(newValue) {
      if (newValue) {
        this.removeAddedItem(0);
        this.addedItems = [];
        this.$emit("ResetValues");
      }
    },
  },
  data() {
    return {
      selectedItem: "",
      addedItems: [],
    };
  },
  methods: {
    addItem() {
      if (this.selectedItem && !this.addedItems.includes(this.selectedItem)) {
        this.addedItems.push(this.selectedItem);
        this.selectedItem = "";
        this.$emit("change", this.addedItems);
      }
    },
    removeAddedItem(index) {
      this.addedItems.splice(index, 1);
      this.$emit("change", this.addedItems);
    },
  },
};
</script>

<style scoped>
select,
option {
  font-size: 9pt;
}

.multi-select-container {
  display: flex;
  border: 1px solid #ccc;
}

.added-items {
  margin-top: 3px;
}

.added-item {
  padding: 0;
  margin: 2px;
  position: relative;
  border: 1px solid #2b1a1a;
  font-size: 10pt;
}

.selected-item-badge {
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  height: 100%;
  font-size: 8pt;
}

.close-button {
  padding: 0;
  flex-shrink: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 0;
}
</style>
