import AgePyramid from "../../../pages/Reporting/charts/AgePyramid.vue";
import store from "../../../Store/store"; // Import your Vuex store

export default [
  {
    path: "/Dashboard/AgePyramid",
    name: "AgePyramid",
    component: AgePyramid,
    beforeEnter: (to, from, next) => {
      store.dispatch("SetRSideActiveBtn", "AgePyramid");
      next();
    },
  },
];
