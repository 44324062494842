<template>
    <div class="content">
        <the-snippet v-show="loading"></the-snippet>
      <apexchart
        v-show="!loading && !ShowMsgBox"
        ref="chart"
        type="treemap"
        :options="chartOptions"
        :series="chartSeries"
      ></apexchart>
      <alert-message v-show="ShowMsgBox" @click="this.ShowMsgBox=false"></alert-message>
    </div>
  </template>
  
  <script>
  import { ref, watch, computed } from "vue";
  import VueApexCharts from "vue3-apexcharts";
  import TheSnippet from "../../../components/UI/TheSnippet.vue";
  
  export default {
    components: {
      apexchart: VueApexCharts,
      TheSnippet
    },
    setup() {
    //   const { refs } = getCurrentInstance();
  
      // Temporary data instead of store
      const ShowMsgBox = ref(false);
      const chartSeries = ref([]);
  
      // Simulate loading state
      const loading = ref(false);
  
      // Simulate fetching temporary data
      const tempProvinceData = {
        "Province A": 30,
        "Province B": 20,
        "Province C": 25,
        "Province D": 10,
        "Province E": 15,
      };
  
      const chartOptions = computed(() => ({
        legend: {
          show: false,
        },
        chart: {
          type: "treemap",
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: 0,
          style: {
            fontSize: "15px",
            fontFamily: "Vazirmatn",
            fontWeight: "bold",
            colors: ["#000"],
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return "% " + value;
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "Vazirmatn",
          },
        },
        colors: [
          "#3B93A5", "#F7B844", "#ADD8C7", "#EC3C65", "#CDD7B6",
          "#C1F666", "#D43F97", "#1E5D8C", "#421243", "#7F94B0",
          "#EF6537", "#C0ADDB",
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
      }));
  
      const updateChart = () => {
        const data = Object.entries(tempProvinceData).map(([key, value]) => ({
          x: key,
          y: value,
        }));
        chartSeries.value = [{ data }];
      };
  
      watch(
        () => tempProvinceData,
        (newValue) => {
          if (Object.keys(newValue).length > 0) {
            ShowMsgBox.value = false;
            updateChart();
          } else {
            ShowMsgBox.value = true;
          }
        },
        { immediate: true }
      );
  
      return {
        loading,
        ShowMsgBox,
        chartOptions,
        chartSeries,
      };
    },
  };
  </script>
  
  <style scoped>
    .content {
    height: 87vh;
    width: 66vw; 
  }
  </style>
  