<template>
  <Main-Navbar></Main-Navbar>
  <div class="support-page container text-center py-5 slide-in">
    <section class="hero-section bg-primary text-white rounded py-4 mb-4">
      <h1>مرکز پشتیبانی</h1>
      <p>ما اینجا هستیم تا به هر سوال یا نگرانی شما پاسخ دهیم. هر زمان نیاز داشتید با ما تماس بگیرید!</p>
    </section>

    <section class="contact-info row justify-content-center mb-4">
      <div class="col-md-4 col-sm-6 contact-item p-4 bg-light rounded shadow-sm m-2">
        <i class="bi bi-telephone-fill display-4 text-primary mb-3"></i>
        <h2>پشتیبانی تلفنی</h2>
        <p>با شماره <strong>09124415396</strong> تماس بگیرید</p>
        <small>شنبه تا پنج شنبه، ساعت ۹ صبح تا ۲ بعدظهر در دسترس است</small>
      </div>

      <div class="col-md-4 col-sm-6 contact-item p-4 bg-light rounded shadow-sm m-2">
        <i class="bi bi-envelope-fill display-4 text-primary mb-3"></i>
        <h2>پشتیبانی ایمیل</h2>
        <p>ایمیل خود را به <strong>rop.registry@gmail.com</strong> بفرستید</p>
        <small>معمولاً ظرف ۲۴ ساعت پاسخ می‌دهیم</small>
      </div>
    </section>

    <section class="social-media">
      <h2 class="mb-3">ارتباط با ما</h2>
      <div class="d-flex justify-content-center gap-3">
        <a href="https://facebook.com" target="_blank" class="text-primary fs-3"><i class="bi bi-facebook"></i></a>
        <a href="https://twitter.com" target="_blank" class="text-primary fs-3"><i class="bi bi-twitter"></i></a>
        <a href="https://instagram.com" target="_blank" class="text-primary fs-3"><i class="bi bi-instagram"></i></a>
        <a href="https://linkedin.com" target="_blank" class="text-primary fs-3"><i class="bi bi-linkedin"></i></a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "SupportPage"
};
</script>

<style scoped>
.hero-section h1 {
  font-size: 2.5rem;
}

.hero-section p {
  font-size: 1.1rem;
}

.contact-info .contact-item {
  max-width: 300px;
}

.social-media h2 {
  font-size: 1.5rem;
}

.social-media a:hover {
  color: #333;
}
</style>
