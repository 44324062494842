<template>
  <div class="layout-container">
    <Main-Navbar></Main-Navbar>
    <div class="main-container">
      <aside class="sidebar">
        <First-RightSidebar class="first-sidebar"></First-RightSidebar>
        <Second-RightSidebar class="second-sidebar"></Second-RightSidebar>
      </aside>
      <main class="content">
        <router-view></router-view>
      </main>
      <export-sidebar class="export-sidebar"></export-sidebar>
    </div>
  </div>
</template>

<script>
import FirstRightSidebar from "../../components/Layout/RightSideBar/DashboardSidebar/FirstSidebar/FirstRightSidebar.vue";
import SecondRightSidebar from "../../components/Layout/RightSideBar/DashboardSidebar/SecondSidebar/SecondRightSidebar.vue";
import ExportSidebar from "./ExportSidebar.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    FirstRightSidebar,
    SecondRightSidebar,
    ExportSidebar,
  },
  computed: {
    ...mapGetters(["RSideActiveBtn"]),
  },
};
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container {
  display: flex;
  flex: 1;
  gap: 1rem;
  width: 100%;
}

.sidebar {
  display: flex;
  width: 25vw; 
  height: 90vh;
  gap: 0.5rem;
}

.sidebar .first-sidebar {
  width: 5vw; 
}

.sidebar .second-sidebar {
  width: 20vw; 
}

.content {
  width: 67vw; 
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}
.export-sidebar {
  height: 90vh;
}
/* Ensure layout consistency in all screen sizes */
@media screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .sidebar {
    flex-direction: row;
    width: 100%;
  }

  .first-sidebar,
  .second-sidebar {
    flex: 0 0 10%; /* First sidebar stays at 10% */
    width: 10%;
  }

  .second-sidebar {
    flex: 0 0 20%; /* Second sidebar stays at 20% */
    width: 20%;
  }

  .content {
    flex: 0 0 70%;
    width: 70%;
  }
}
</style>
