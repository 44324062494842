<template>
  <div>
    <MessageDisplay ref="messageComponent" />
    <ReusableModal :isVisible="showModalBox" @confirm="deleteRecord" @cancel="cancelDelete" :Modaltext="modaltext"> </ReusableModal>
    <discharge-list @ShowDischargeForm="ActiveDischargeForm" v-if="ShowDischargeList" @ShowMsgBox="showMsgBox" @ShowModal="ShowModal" :confirmDel="confirmDelete" @resetDelete="resetConfirmDel"></discharge-list>
    <discharge-patient v-if="ShowDischargeForm" @ShowDischargeListForm="ActiveDischargeList"></discharge-patient>
  </div>
</template>

<script>
import DischargeList from "./DischargeList.vue";
import DischargePatient from "./DischargePatient.vue";
import ReusableModal from "../../../components/UI/TheModal.vue";

export default {
  components: {
    ReusableModal,
    DischargePatient,
    DischargeList
  },
  data() {
    return {
      ShowDischargeList: true,
      ShowDischargeForm: false,
      showModalBox: false,
      confirmDelete: false,
      isVisible: false,
      modaltext: 'اطلاعات ترخیص حذف خواهد شد!',
    };
  },
  methods: {
    showMsgBox(payload) {
      if (payload == true) {
        this.$refs.messageComponent.showMessage(204)
      } else {
        this.$refs.messageComponent.showMessage(400)
      }
    },
    ActiveDischargeList() {
      this.ShowDischargeList = true;
      this.ShowDischargeForm = false;
    },
    ActiveDischargeForm() {
      this.ShowDischargeList = false;
      this.ShowDischargeForm = true;
    },
    ShowModal() {
      this.showModalBox = true;
    },

    deleteRecord() {
      this.confirmDelete = true;
      this.showModalBox = false;
    },
    resetConfirmDel() {
      this.confirmDelete = false;
    },
    cancelDelete() {
      this.showModalBox = false;
    },
  }
};
</script>
