import TheNICU from "../../../pages/DataEntry/NICU/NicuUnit.vue";
import NICUInterventions from "../../../pages/DataEntry/NICU/NicuInterventions.vue";
import store from '../../../Store/store'; // Import your Vuex store

export default [
    {
      path: "/DataEntry/NICU",
      name: "NICU",
      component: TheNICU,
      beforeEnter: (to, from, next) => {
        store.dispatch('SetRSideActiveBtn', 'NicuVisit');
        next();
      },
      children: [
        {
          path: "Interventions",
          name: "NicuInterventions",
          component: NICUInterventions,
        },
      ],
    },
  ];
  