import OphthalmologyUnit from "../../../pages/DataEntry/Clinic/OphthalmologyUnit.vue";
import OphtPysicianVisit from "../../../pages/DataEntry/Clinic/OphtPysicianVisit.vue";
import store from '../../../Store/store'; // Import your Vuex store

export default [
    {
      path: "/DataEntry/Ophthalmology",
      name: "Ophthalmology",
      component: OphthalmologyUnit, 
      beforeEnter: (to, from, next) => {
        store.dispatch('SetRSideActiveBtn', 'OphtVisit');
        next();
      },
      children: [
        {
          path: "Visit",
          name: "OphthalmologyVisit",
          component: OphtPysicianVisit,
        },
      ],
    },
  ];
  