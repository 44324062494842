import TheAppointment from "../../../pages/DataEntry/Appointment/TheAppointment.vue";
import SettingAppointment from "../../../pages/DataEntry/Appointment/SetAppointment.vue";
import store from "../../../Store/store"; // Import your Vuex store

export default [
  {
    path: "/DataEntry/Appointment",
    name: "Appointment",
    component: TheAppointment,
    beforeEnter: (to, from, next) => {
      store.dispatch("SetRSideActiveBtn", "Appointment");
      next();
    },
    children: [
      {
        path: "Register",
        name: "SettingAppointment",
        component: SettingAppointment,
      },
    ],
  },
];
