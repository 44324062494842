<template>
  <Message-Display ref="messageComponent" />
  <the-snippet v-if="showSnippet" />
  <div v-else class="slide-in">
    <Patient-Info />
    <div class="content">
      <div class="data-entry-form">
        <h5>نوزاد</h5>
        <div>
          <div class="RowDir">
            <div class="form-group">
              <label class="toggle-switchy" for="multiple-birth" data-size="sm" data-style="Rounded" data-text="true" data-label="left" data-color="">
                <input type="checkbox" id="multiple-birth" v-model="formData.MultiBirth" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">چندقلویی:</span>
              </label>
              <select
                v-if="formData.MultiBirth"
                id="birth-number"
                v-model="formData.ChildNum"
                class="small-input"
                :class="{ errorBorder: messages.errors.ChildNum }"
                @click="messages.errors.ChildNum = ''">
                <option value="" disabled hidden>انتخاب کنید</option>
                <option v-for="option in options.TurnsOpts" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.ChildNum">{{ messages.errors.ChildNum }}</span>
            </div>
            <div class="form-group">
              <label for="weight-birth">وزن هنگام تولد:</label>
              <input
                type="Number"
                id="weight-birth"
                v-model="formData.WeightAtBirth"
                placeholder="گرم"
                class="small-input"
                :class="{ errorBorder: messages.errors.WeightAtBirth }"
                @click="messages.errors.WeightAtBirth = ''" />
              <span class="error-message" v-if="messages.errors.WeightAtBirth">{{ messages.errors.WeightAtBirth }}</span>
            </div>
            <div class="form-group">
              <label for="head-size">دور سر:</label>
              <input
                type="Number"
                id="head-size"
                v-model="formData.HeadSize"
                placeholder="سانتی متر"
                class="small-input"
                :class="{ errorBorder: messages.errors.HeadSize }"
                @click="messages.errors.HeadSize = ''" />
              <span class="error-message" v-if="messages.errors.HeadSize">{{ messages.errors.HeadSize }}</span>
            </div>
            <div class="form-group">
              <label for="height-at-birth">قد:</label>
              <input
                type="Number"
                id="height-at-birth"
                v-model="formData.HeightAtBirth"
                placeholder="سانتی متر"
                class="small-input"
                :class="{ errorBorder: messages.errors.HeightAtBirth }"
                @click="messages.errors.HeightAtBirth = ''" />
              <span class="error-message" v-if="messages.errors.HeightAtBirth">{{ messages.errors.HeightAtBirth }}</span>
            </div>
            <div class="form-group">
              <label for="breastfeeding-type">شیردهی:</label>
              <select
                id="breastfeeding-type"
                v-model="formData.BreastFeedingType"
                :class="{ errorBorder: messages.errors.BreastFeedingOpts }"
                @click="messages.errors.BreastFeedingOpts = ''">
                <option value="" disabled hidden>انتخاب کنید</option>
                <option v-for="option in options.BreastFeedingOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.BreastFeedingOpts">{{ messages.errors.BreastFeedingOpts }}</span>
            </div>
            <div class="form-group">
              <label for="apgar-score">آپگار:</label>
              <select id="apgar-score" v-model="formData.Min1ApgarScore" class="small-input">
                <option value="" disabled>دقیقه 1</option>
                <option v-for="option in options.ApgarScoreOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <select v-model="formData.Min5ApgarScore" class="small-input">
                <option value="" disabled>دقیقه 5</option>
                <option v-for="option in options.ApgarScoreOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.ApgarScore">{{ messages.errors.ApgarScore }}</span>
            </div>
            <div class="form-group">
              <label class="toggle-switchy" for="cpr-history" data-size="sm" data-style="Rounded" data-text="true" data-label="left" data-color="red">
                <input type="checkbox" id="cpr-history" v-model="formData.HasCPRHistory" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">احیا تولد:</span>
              </label>
              <select
                v-if="formData.HasCPRHistory"
                v-model="formData.CPRInterventions"
                :class="{ errorBorder: messages.errors.CPRInterventions }"
                @click="messages.errors.CPRInterventions = ''">
                <option v-for="option in options.CPRInterventionsOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="congenital-anomaly"
                data-size="sm"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="red">
                <input type="checkbox" id="congenital-anomaly" v-model="formData.HasCongenitalAnomaly" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">نقص مادرزادی:</span>
              </label>
              <div class="SegmentedCheckbox">
                <Segmented-Checkbox
                  v-if="formData.HasCongenitalAnomaly"
                  class="slide-in"
                  InputType="checkbox"
                  ref="segmentedCheckbox"
                  :options="options.CongenitalAnomalyOpts"
                  @inputData="UpdateCongenitalValues"
                  :value="formData.CongenitalAnomalies"
                  v-model="formData.CongenitalAnomalies" />
              </div>
              <span class="error-message" v-if="messages.errors.CongenitalAnomalies">{{ messages.errors.CongenitalAnomalies }}</span>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <div class="SegmentedCheckbox">
                <label
                  class="toggle-switchy"
                  for="diagnosis-diseases"
                  data-size="sm"
                  data-style="Rounded"
                  data-text="true"
                  data-label="left"
                  data-color="orange">
                  <input type="checkbox" id="diagnosis-diseases" v-model="formData.HasComplicationLowWeight" />
                  <span class="toggle">
                    <span class="switch"></span>
                  </span>
                  <span class="label">تشخیص حین درمان:</span>
                </label>
                <Segmented-Checkbox
                  id="complication-low-weight"
                  class="slide-in"
                  v-if="formData.HasComplicationLowWeight"
                  InputType="checkbox"
                  ref="segmentedCheckbox"
                  :options="options.ComplicationsLowWeightOpts"
                  @inputData="UpdateComplicationsValues"
                  :value="formData.ComplicationsLowWeight"
                  v-model="formData.ComplicationsLowWeight" />
              </div>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <div class="SegmentedCheckbox">
                <label class="HistoryLbl">تغذیه وریدی:</label>
                <Segmented-Checkbox
                  id="intravenous-nutrition"
                  InputType="checkbox"
                  ref="segmentedCheckbox"
                  :options="options.IntravenousNutritionOpts"
                  @inputData="UpdateIntraValues"
                  :value="formData.IntravenousNutritionType"
                  v-model="formData.IntravenousNutritionType"></Segmented-Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="data-entry-form">
        <h5>مادر</h5>
        <div class="data-section">
          <div class="RowDir">
            <div class="form-group">
              <label for="blood-type">گروه خونی:</label>
              <select
                class="small-input"
                id="blood-type"
                v-model="formData.BloodGroup"
                :class="{ errorBorder: messages.errors.BloodGroup }"
                @click="messages.errors.BloodGroup = ''">
                <option value=""></option>
                <option value="">انتخاب کنید</option>
                <option v-for="option in options.BloodGroupOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.BloodGroup">{{ messages.errors.BloodGroup }}</span>
            </div>
            <div class="form-group">
              <label for="pregnancy-type">نوع حاملگی:</label>
              <select
                id="pregnanacy-type"
                v-model="formData.PregnancyType"
                :class="{ errorBorder: messages.errors.PregnancyType }"
                @click="messages.errors.PregnancyType = ''">
                <option value="" disabled hidden>انتخاب کنید</option>
                <option v-for="(option, index) in options.PregnancyOpts" :value="option" :key="index">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.PregnancyType">{{ messages.errors.PregnancyType }}</span>
            </div>

            <div class="form-group">
              <label for="delivery-type">نوع زایمان:</label>
              <select
                id="pregnanacy-type"
                v-model="formData.DeliveryType"
                :class="{ errorBorder: messages.errors.DeliveryType }"
                @click="messages.errors.DeliveryType = ''">
                <option value="" disabled hidden>انتخاب کنید</option>
                <option v-for="option in options.DeliveryOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <select
                v-if="formData.DeliveryType === 'سزارین'"
                id="caesarean-reason"
                v-model="formData.CaesareanReason"
                :class="{ errorBorder: messages.errors.CaesareanReason }"
                @click="messages.errors.CaesareanReason = ''">
                <option value="" disabled hidden>انتخاب کنید</option>
                <option v-for="option in options.CaesareanReasonOpts" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.DeliveryType">{{ messages.errors.DeliveryType }}</span>
            </div>
            <div class="form-group">
              <label for="pregnancy-termination-age">سن ختم حاملگی:</label>
              <input
                type="number"
                v-model="formData.PregnancyTerminationWeek"
                placeholder="هفته"
                class="small-input"
                :class="{ errorBorder: messages.errors.PregnancyTerminationWeek }"
                @click="messages.errors.PregnancyTerminationWeek = ''" />
              <input
                type="number"
                v-model="formData.PregnancyTerminationDay"
                placeholder="روز"
                class="small-input"
                :class="{ errorBorder: messages.errors.PregnancyTerminationDay }"
                @click="messages.errors.PregnancyTerminationDay = ''" />
              <span class="error-message" v-if="messages.errors.PregnancyTerminationDay">{{ messages.errors.PregnancyTerminationDay }}</span>
            </div>
            <div class="form-group">
              <label for="pregnancy-time">نوبت حاملگی:</label>
              <select
                id="pregnancy-time"
                v-model="formData.PregnancyTurn"
                class="small-input"
                :class="{ errorBorder: messages.errors.PregnancyTurn }"
                @click="messages.errors.PregnancyTurn = ''">
                <option value="" disabled hidden>انتخاب کنید</option>
                <option v-for="option in options.TurnsOpts" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.PregnancyTurn">{{ messages.errors.PregnancyTurn }}</span>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <label for="number-live-child">تعداد نوزاد زنده:</label>
              <input
                type="Number"
                id="number-live-child"
                v-model="formData.LiveChildNumber"
                placeholder="تعداد"
                class="small-input"
                :class="{ errorBorder: messages.errors.LiveChildNumber }"
                @click="messages.errors.LiveChildNumber = ''" />
              <span class="error-message" v-if="messages.errors.LiveChildNumber">{{ messages.errors.LiveChildNumber }}</span>
            </div>
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="caesarean-history"
                data-size="sm"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="red">
                <input type="checkbox" id="caesarean-history" v-model="formData.CaesareanHistory" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">سابقه سزارین:</span>
              </label>
              <select
                v-if="formData.CaesareanHistory"
                v-model="formData.CaesareanNumbers"
                class="small-input"
                :class="{ errorBorder: messages.errors.CaesareanNumbers }"
                @click="messages.errors.CaesareanNumbers = ''">
                <option value="" disabled>تعداد</option>
                <option v-for="option in options.TurnsOpts" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.CaesareanNumbers">{{ messages.errors.CaesareanNumbers }}</span>
            </div>
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="abortion-history"
                data-size="sm"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="red">
                <input type="checkbox" id="abortion-history" v-model="formData.AbortionHistory" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">سابقه سقط:</span>
              </label>
              <select v-if="formData.AbortionHistory" v-model="formData.AbortionNumbers" class="small-input">
                <option v-for="option in options.TurnsOpts" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="messages.errors.AbortionReason">{{ messages.errors.AbortionReason }}</span>
            </div>
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="last-child-death"
                data-size="sm"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="red">
                <input type="checkbox" id="last-child-death" v-model="formData.LastChildDeath" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">مرگ نوزاد قبلی:</span>
              </label>
            </div>
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="death-delivery-history"
                data-size="sm"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="red">
                <input type="checkbox" id="death-delivery-history" v-model="formData.DeathDeliveryHistory" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">سابقه مرده زایی:</span>
              </label>
              <select v-if="formData.DeathDeliveryHistory" v-model="formData.DeathDeliveryNumbers" class="small-input">
                <option v-for="option in options.TurnsOpts" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <div class="SegmentedCheckbox">
                <label style="white-space: nowrap;">عوامل خطر بارداری:</label>
                <Segmented-Checkbox
                  InputType="checkbox"
                  ref="segmentedCheckbox"
                  :options="options.PregnancyRisksOpts"
                  @inputData="UpdatePregnancyRisksValues"
                  :value="formData.PregnancyRisks"
                  v-model="formData.PregnancyRisks" />
              </div>
            </div>
          </div>
          <div class="RowDir">
            <div class="form-group">
              <label
                class="toggle-switchy"
                for="has-delivery-risk"
                data-size="sm"
                data-style="Rounded"
                data-text="true"
                data-label="left"
                data-color="red">
                <input type="checkbox" id="has-delivery-risk" v-model="formData.HasDeliveryRisk" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">عوامل خطر زایمانی:</span>
              </label>
              <div class="SegmentedCheckbox">
                <Segmented-Checkbox
                  v-if="formData.HasDeliveryRisk"
                  class="slide-in"
                  InputType="checkbox"
                  ref="segmentedCheckbox"
                  :options="options.DeliveryRiskOpts"
                  @inputData="UpdateDeliveryRiskValues"
                  :value="formData.DeliveryRisks"
                  v-model="formData.DeliveryRisks" />
              </div>
              <span class="error-message" v-if="messages.errors.DeliveryRisks">{{ messages.errors.DeliveryRisks }}</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="data-entry-form">
        <h5>پدر</h5>
        <div class="data-section">
          <div class="RowDir">
            <div class="form-group">
              <div class="SegmentedCheckbox">
                <label class="HistoryLbl">سابقه بیماری:</label>
                <Segmented-Checkbox
                  InputType="checkbox"
                  ref="segmentedCheckbox"
                  :options="options.IllnessHistoryTypeOpts"
                  @inputData="UpdateFatherIllnessValues"
                  :value="formData.FatherIllnessHistory"
                  v-model="formData.FatherIllnessHistory"></Segmented-Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="DataEntryButtons">
      <button type="button" class="btn btn-success submit-button" v-if="ShowSubmitBtn" @click="submitForm">ثبت</button>
      <button type="button" class="btn btn-primary edit-button" v-if="ShowEditBtn && hasAccess('EditMedHistory')" @click="editForm">ویرایش</button>
      <button type="button" @click="resetForm" class="btn btn-info">پاک کردن</button>
      <router-link :to="{ name: 'Admission' }" v-if="RSideActiveBtn == 'Admission'" type="button" @click="returnForm" class="btn btn-warning">
        لیست بیماران
      </router-link>
      <router-link :to="{ name: 'MedicalHistory' }" v-if="RSideActiveBtn == 'MedHistory'" type="button" @click="returnForm" class="btn btn-warning">
        لیست بیماران
      </router-link>
    </div>
  </div>
</template>

<script>
import api from "../../../Api/TheApi.js";
import { mapGetters } from "vuex";
import SegmentedCheckbox from "../../../components/UI/SegmentedCheckbox.vue";
import permissionMixin from "@/Mixin/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  components: {
    SegmentedCheckbox
  },
  computed: {
    ...mapGetters(["RSideActiveBtn", "SelectedPatient", "userID"])
  },
  data() {
    return {
      segmentedCheckboxKey: 0,

      formData: {
        MultiBirth: false,
        ChildNum: null,
        WeightAtBirth: null,
        HeightAtBirth: null,
        HeadSize: null,
        BreastFeedingType: null,
        Min1ApgarScore: null,
        Min5ApgarScore: null,
        HasCPRHistory: false,
        CPRInterventions: null,

        HasCongenitalAnomaly: false,
        CongenitalAnomalies: [],

        HasComplicationLowWeight: false,
        ComplicationsLowWeight: [],

        IntravenousNutritionType: [],

        BloodGroup: null,
        PregnancyType: "باروری طبیعی",
        DeliveryType: "زایمان طبیعی",
        CaesareanReason: null,
        PregnancyTerminationWeek: null,
        PregnancyTerminationDay: null,
        PregnancyTurn: null,
        LiveChildNumber: null,
        CaesareanHistory: false,
        CaesareanNumbers: null,
        AbortionHistory: false,
        AbortionNumbers: null,
        LastChildDeath: false,
        DeathDeliveryHistory: false,
        DeathDeliveryNumbers: null,
        HasPregnancyRisks: false,
        PregnancyRisks: [],
        HasDeliveryRisk: false,
        DeliveryRisks: [],

        FatherIllnessHistory: [],
        RegisterUser: null,
        UpdateUser: null
      },

      options: {
        CongenitalAnomalyOpts: [],
        CPRInterventionsOpts: [],
        IntravenousNutritionOpts: [],
        ApgarScoreOpts: [],
        ComplicationsLowWeightOpts: [],
        BreastFeedingOpts: [],
        BloodGroupOpts: [],
        PregnancyOpts: [],
        DeliveryOpts: [],
        CaesareanReasonOpts: [],
        DeliveryRiskOpts: [],
        AbortionReasonOpts: [],
        DeathDilveryOpts: [],
        PregnancyRisksOpts: [],
        IllnessHistoryTypeOpts: [],
        HasDeliveryRiskOpts: [],
        TurnsOpts: [
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" },
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" }
        ],
        AbortionHistory: [
          { label: "بلی", value: "True" },
          { label: "خیر", value: "False" }
        ]
      },
      messages: {
        errors: {}
      },
      showSnippet: true,
      ShowSubmitBtn: true,
      ShowEditBtn: false
    };
  },

  mounted() {
    this.fetchOptions();
    if (this.SelectedPatient.id) this.fetchUserInfo();
  },
  methods: {
    UpdateCongenitalValues(newVal) {this.formData.CongenitalAnomalies = newVal;},
    UpdateComplicationsValues(newVal) {this.formData.ComplicationsLowWeight = newVal;},
    UpdateIntraValues(newVal) {this.formData.IntravenousNutritionType = newVal;},
    
    UpdatePregnancyRisksValues(newVal) {this.formData.PregnancyRisks = newVal;},
    UpdateDeliveryRiskValues(newVal) {
      this.formData.DeliveryRisks = newVal;
    },
    UpdateFatherIllnessValues(newVal) {
      this.formData.FatherIllnessHistory = newVal;
    },

    async fetchOptions() {
      try {
        const { data } = await api.get("MedicalHistory/");
        this.options = { ...this.options, ...data };
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        if (error.response) this.$refs.messageComponent.showMessage(error.response);
      }
    },

    async fetchUserInfo() {
      this.resetForm();
      try {
        this.showSnippet = true;
        const { status, data } = await api.get(`medical-history-detail/${this.SelectedPatient.id}/`);
        if (status === 200) {
          this.formData = { ...this.formData, ...data };
          this.ShowSubmitBtn = false;
          this.ShowEditBtn = true;
          this.showSnippet = false;
        }
      } catch {
        this.showSnippet = false;
        this.ShowSubmitBtn = true;
        this.ShowEditBtn = false;
      }
    },

    async submitForm() {
      this.messages.errors = {};
      // this.validateForm();
      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.RegisterUser = this.userID;
          const { status } = await api.post(`medical-history-detail/${this.SelectedPatient.id}/`, {
            ...this.formData
          });
          this.showSnippet = false;
          this.$refs.messageComponent.showMessage(status);
        } catch (error) {
          this.showSnippet = false;
          if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
        }
      }
    },

    async editForm() {
      this.messages.errors = {};
      // this.validateForm();
      if (Object.keys(this.messages.errors).length === 0) {
        try {
          this.showSnippet = true;
          this.formData.UpdateUser = this.userID;
          const { status } = await api.put(`medical-history-detail/${this.SelectedPatient.id}/`, {
            ...this.formData
          });
          this.showSnippet = false;
          this.$refs.messageComponent.showMessage(status);
        } catch (error) {
          this.showSnippet = false;
          if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
        }
      }
    },

    validateForm() {
      this.messages.errors = {};
      const fields = [
        { field: "WeightAtBirth", message: "وزن هنگام تولد را وارد کنید" },
        // { field: "PregnancyType", message: "نوع حاملگی را انتخاب کنید" },
        // { field: "PregnancyTurn", message: "نوبت حاملگی را انتخاب کنید" },
        // { field: "DeliveryType", message: "نوع زایمان را وارد کنید" },
      ];
      fields.forEach(({ field, message }) => {
        if (!this.formData[field]) this.messages.errors[field] = message;
      });

      if (this.formData.MultiBirth && !this.formData.ChildNum) {
        this.messages.errors.ChildNum = "قل چندم را انتخاب کنید";
      }
      // if (this.formData.HasCongenitalAnomaly && !this.formData.HasCongenitalAnomalyType) {
      //   this.messages.errors.HasCongenitalAnomalyType = "آنومالی مادرزادری را انتخاب کنید";
      // }
      return Object.keys(this.messages.errors).length === 0;
    },

    showMessage(type, state, message) {
      this.messages[`show${type}`] = state;
      this.messages[type] = message;
    },

    removeErrorMsg(field) {
      this.errors[field] = "";
    },

    resetForm() {
      this.formData = {
        MultiBirth: false,
        ChildNum: null,
        WeightAtBirth: null,
        HeightAtBirth: null,
        HeadSize: null,
        BreastFeedingType: null,
        Min1ApgarScore: null,
        Min5ApgarScore: null,
        HasCPRHistory: false,
        CPRInterventions: null,

        HasCongenitalAnomaly: false,
        CongenitalAnomalies: [],

        HasComplicationLowWeight: false,
        ComplicationsLowWeight: [],

        IntravenousNutritionType: [],

        BloodGroup: null,
        PregnancyType: "باروری طبیعی",
        DeliveryType: "زایمان طبیعی",
        CaesareanReason: null,
        PregnancyTerminationWeek: null,
        PregnancyTerminationDay: null,
        PregnancyTurn: null,
        LiveChildNumber: null,
        CaesareanHistory: false,
        CaesareanNumbers: null,
        AbortionHistory: false,
        AbortionNumbers: null,
        LastChildDeath: false,
        DeathDeliveryHistory: false,
        DeathDeliveryNumbers: null,
        HasPregnancyRisks: false,
        PregnancyRisks: [],
        HasDeliveryRisk: false,
        DeliveryRisks: [],

        FatherIllnessHistory: [],
      };
      if (!this.showSnippet) this.$refs.segmentedCheckbox.reset();
      this.messages.errors = {};
    },

    returnForm() {
      if (this.RSideActiveBtn == "Admission") {
        this.$emit("ShowAdmissionPatientList");
      } else if (this.RSideActiveBtn == "MedHistory") {
        this.$emit("ShowMedHistPatientList");
      }
    }
  },
  watch: {
    "SelectedPatient.id"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchUserInfo();
      }
    },
    "formData.MultiBirth"(newVal) {
      if (!newVal) this.formData.ChildNum = null;
    },
    "formData.HasCPRHistory"(newVal) {
      if (!newVal) this.formData.CPRInterventions = null;
    },
    "formData.HasCongenitalAnomaly"(newVal) {
      if (!newVal) this.formData.CongenitalAnomalies = [];
    },
    "formData.HasComplicationLowWeight"(newVal) {
      if (!newVal) this.formData.ComplicationsLowWeight = [];
    },
    "formData.DeliveryType"(newVal) {
      if (newVal != 'سزارین') this.formData.CaesareanReason = null;
    },
    "formData.CaesareanHistory"(newVal) {
      if (!newVal) this.formData.CaesareanNumbers = null;
    },
    "formData.AbortionHistory"(newVal) {
      if (!newVal) this.formData.AbortionNumbers = null;
    },
    "formData.DeathDeliveryHistory"(newVal) {
      if (!newVal) this.formData.DeathDeliveryNumbers = null;
    },
    "formData.HasPregnancyRisks"(newVal) {
      if (!newVal) this.formData.PregnancyRisks = [];
    },
    "formData.HasDeliveryRisk"(newVal) {
      if (!newVal) this.formData.DeliveryRisks = [];
    },
  }
};
</script>

<style scoped>
hr {
  margin: 10px;
}
h5 {
  display: flex;
  width: 4vw;
  justify-content: center;
  align-items: center;
  color: #0065fd;
  font-size: 13pt;
  font-weight: bold;
  border-left: 1px solid #ccc;
}
.data-entry-form {
  display: flex;
  width: 90vw;
  flex-direction: row;
}
.data-section {
  display: flex;
  flex-direction: column;
  padding: 5px;
  right: 0;
}
.RowDir {
  display: flex;
  min-width: 85vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
}

.form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px 0 10px;
  max-width: 85vw;
}

label {
  display: flex;
  text-align: left;
  font-weight: bold;
  font-size: 9pt;
}

input,
select {
  max-width: 130px;
  height: 30px;
  border: 1pt solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
}

.small-input {
  max-width: 70px;
}

.DataEntryButtons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn {
  margin-left: 3px;
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  min-width: 80px;
}

.SegmentedCheckbox {
  display: flex;
  justify-content: right;
  align-items: center;
}

.toggle-switchy {
  min-height: 30px !important;
}
.toggle-switchy[data-label="left"] > input + .toggle + .label {
  margin-right: 0 !important;
}
.toggle {
  margin-right: 2px !important;
}
.error-message {
  color: red;
  font-size: 12px;
  font-weight: bold;
}
.errorBorder {
  border: 2px solid red;
  background: rgb(240, 95, 95);
}
</style>
