<template>
  <div>
    <!-- Render Sidebar Items -->
    <div v-for="item in sidebarItems" :key="item.name" class="sidebar-link-wrapper">
      <router-link
        v-if="hasAccess(item.permission)"
        :to="{ name: item.route }"
        class="card text-center square-border sidebar-link"
        :class="{ active: RSideActiveBtn === item.name }"
        @click="selectRSideActiveBtn(item.name)">
        <div class="card-body">
          <span class="sidebar-label">{{ item.label }}</span>
        </div>
      </router-link>
    </div>
    <hr />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import permissionMixin from '@/Mixin/permissionMixin';

export default {
  mixins: [permissionMixin],
  computed: {
    ...mapGetters(["RSideActiveBtn", "AccessLvl"]),
    sidebarItems() {
      return [
        {
          name: "byLocation",
          route: "byLocation",
          label: "مکان",
          permission: "NICU",
        },
        {
          name: "byROP",
          route: "byROP",
          label: "ROP",
          permission: "NICU",
        },
        {
          name: "AgePyramid",
          route: "AgePyramid",
          label: "هرم سنی",
          permission: "NICU",
        },
        {
          name: "DistPercent",
          route: "DistPercent",
          label: "توزیع",
          permission: "NICU",
        },
        {
          name: "LocPercent",
          route: "LocPercent",
          label: "درصد",
          permission: "NICU",
        },
        {
          name: "GeoMap",
          route: "GepMap",
          label: "نقشه",
          permission: "NICU",
        },
        {
          name: "Table",
          label: "جدول",
          permission: "NICU",
        },
      ];
    }
  },
  methods: {
    ...mapActions(["SetRSideActiveBtn"]),

    selectRSideActiveBtn(section) {
      this.SetRSideActiveBtn(section);
    },
  }
};
</script>

<style scoped>
/* Sidebar general styling */
.sidebar-link-wrapper {
  margin-bottom: 10px;
}

.sidebar-link {
  text-decoration: none;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding-top:5px;
  
}

.card.text-center {
  cursor: pointer;
  border: none;
  transition: transform 0.2s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.card-body {
  display: flex;
  justify-content: center; /* Center the label */
  align-items: center; /* Center the label */
  background-color: #ffffff;
  color: #333;
  width: 5vw;
  height: 50px; /* Set height to make label centered vertically */
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease, color 0.3s ease;
  border:1px solid rgb(219, 217, 217);
}

.card-body:hover {
  background-color: blue;
  color: #ffffff;
}

.sidebar-label {
  font-size: 11pt;
  font-weight: 700;
  text-align: center;
}

/* Active state for card */
.card.active .card-body {
  background-color: blue;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.card.active .card-body:hover {
  color: #e60000;
}
</style>
