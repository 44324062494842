import { createStore } from "vuex";
import theLogin from './Modules/Login/auth';
import UserIdentification from './Modules/Login/auth';

import Navbar from "./Modules/TheNavbar";
import FirstRightSidebar from "./FirstRightSidebar";

import demographics from './Modules/Public/NewPatient';

import SelectedPatientInfo from './Modules/Admission/PatientInfo';

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Navbar,
    theLogin,
    UserIdentification,
    FirstRightSidebar,
    demographics,
    SelectedPatientInfo,
  },
});


export default store;
