<template>
  <Main-Navbar></Main-Navbar>
  <div>
    <div class="main-container">
      <div class="sidebar">
        <First-RightSidebar></First-RightSidebar>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import FirstRightSidebar from "../../../components/Layout/RightSideBar/DataEntrySidebar/DataEntrySidebar.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    FirstRightSidebar,
  },
  computed: {
    ...mapGetters(["RSideActiveBtn"]),
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: row;
  width: 100vw !important;
}
.sidebar {
  max-width: 10vw !important;
}
.content {
  min-width: 89vw !important;
  max-width: 89vw !important;
  padding: 3px !important;
  margin-right: 3px !important;
  margin-left: 3px !important;
}
</style>
