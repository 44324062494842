<template>
  <div>
    <MessageDisplay ref="messageComponent" />
    <ReusableModal :isVisible="showModalBox" @confirm="deleteRecord" @cancel="cancelDelete" :Modaltext="ModalText"></ReusableModal>
    <nicu-list
      @ShowNicuVisitForm="ActiveIntervention"
      v-if="ShowPatientList"
      @ShowMsgBox="showMsgBox"
      @ShowModal="ShowModal"
      :confirmDel="confirmDelete"
      @resetDelete="resetConfirmDel"></nicu-list>
    <nicu-interventions v-if="ShowIntervention" @ShowPatientListForm="ActivePatientList"></nicu-interventions>
  </div>
</template>
<script>
import NicuInterventions from "./NicuInterventions.vue";
import NicuList from "./NicuList.vue";

import ReusableModal from "../../../components/UI/TheModal.vue";

export default {
  components: {
    NicuList,
    ReusableModal,
    NicuInterventions,
  },
  data() {
    return {
      ShowPatientList: true,
      ShowIntervention: false,

      showModalBox: false,
      confirmDelete: false,
      isVisible: false,
      ModalText:'اطلاعات nicu بیمار حذف خواهد شد!',
    };
  },
  methods: {
    ActivePatientList() {
      this.ShowPatientList = true;
      this.ShowIntervention = false;
    },
    ActiveIntervention() {
      this.ShowPatientList = false;
      this.ShowIntervention = true;
    },
    showMsgBox(payload) {
      if (payload == true) {
        this.$refs.messageComponent.showMessage(204)
      } else {
        this.$refs.messageComponent.showMessage()
      }
    },
    ShowModal() {
      this.showModalBox = true;
    },

    deleteRecord() {
      this.confirmDelete = true;
      this.showModalBox = false;
    },
    resetConfirmDel() {
      this.confirmDelete = false;
    },
    cancelDelete() {
      this.showModalBox = false;
    }
  }
};
</script>
