<template>
  <div v-if="isVisible" class="modal">
    <div class="modal-content">
      <div v-if="isVisible" :class="['message', msgType === 'success' ? 'success' : 'error']">
        <h5>{{ Modaltext }}</h5>
        <h4>اطمینان دارید؟</h4>
      </div>
      <div class="modal-actions">
        <button @click="confirm" class="action-button confirm-button">تایید</button>
        <button @click="cancel" class="action-button cancel-button">لغو</button>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    Modaltext: {
      type: String,
      required: true,
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
.modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-in-out;
}

@keyframes slideIn {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 15px;
  border-radius: 5px;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 15px;
  border-radius: 5px;
}

.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: inherit;
  margin-left: 10px;
  float: right;
}
.action-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}
.confirm-button {
  background-color: #28a745;
  color: white;
}

.confirm-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #dc3545;
  color: white;
}

.cancel-button:hover {
  background-color: #c82333;
}
h5{
  color:blue;
}

h4 {
  color: rgb(255, 0, 0);
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
</style>
