<template>
  <AgeRangeSlider
    :type="SliderType"
    :min="SliderMin"
    :max="SliderMax"
    :step="SliderStep"
    :skin="sliderSkin"
    @RangeSlider="AgeGroups"
    :ResetRange="ResetAge"
    @RemoveRange="RemoveAge"
  ></AgeRangeSlider>
</template>

<script>
import AgeRangeSlider from "../../../../../UI/DualRangSlider.vue";

export default {
  components: {
    AgeRangeSlider,
  },
  props: ["ResetAge"],
  data() {
    return {
      SliderType: "double",
      SliderMin: "0",
      SliderMax: "120",
      SliderStep: "5",
      sliderSkin: "modern",
    };
  },
  methods: {
    AgeGroups(values) {
      this.$emit("selected", values);
    },
    RemoveAge() {
      this.$emit("ResetAgeGroups");
    },
  },
};
</script>
