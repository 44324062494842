<template>
  <div>
    <Main-Navbar></Main-Navbar>
    <!-- Slider -->
    <div class="slider slide-in">
      <div
        id="carouselExampleAutoplaying"
        class="carousel carousel-dark slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="slider-item carousel-item active">
            <div class="content-wrapper">
              <router-link :to="{ name: 'Home' }" class="large-box BoxA"></router-link>
              <div class="small-boxes">
                <div class="box-container">
                  <router-link
                    v-for="(box, index) in filteredBoxes"
                    :key="index"
                    :to="{ name: box.name }"
                    :class="['box', box.class]"
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import permissionMixin from "@/Mixin/permissionMixin";

export default {
  mixins: [permissionMixin],
  computed: {
    ...mapGetters(["NavActiveTab"]),
    filteredBoxes() {
      const boxes = [
        { name: "Admission", class: "BoxA1", permission: "Admission" },
        { name: "MedicalHistory", class: "BoxA2", permission: "MedHistory" },
        { name: "NICU", class: "BoxA3", permission: "NICU" },
        { name: "Ophthalmology", class: "BoxA4", permission: "Ophthalmology" },
        { name: "Discharge", class: "BoxA5", permission: "Discharge" },
        { name: "FollowUp", class: "BoxA6", permission: "FollowUp" },
        { name: "Appointment", class: "BoxA7", permission: "Appointment" },
        { name: "MedicalRecord", class: "BoxA8", permission: "MedRecords" },
      ];

      return boxes.filter(box => this.hasAccess(box.permission));
    }
  },
  methods: {
    ...mapActions([
      "SetRSideActiveBtn",
      "SetNavActiveBtn",
      "SetNavActiveTab",
      "SetLoading"
    ]),
    GoTo(section, Tab, index) {
      this.SetLoading("true");
      const URLPath = section + Tab + index;
      this.$router.push({ name: URLPath });
      this.SetRSideActiveBtn(section);
      this.SetNavActiveBtn(Tab);
      this.SetNavActiveTab(index);
    }
  }
};
</script>

<style scoped>
.slider {
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 92vh;
  width: 100%;
  background-image: url("../../assets/images/SliderBackground.png");
  background-size: cover;
}

.content-wrapper {
  display: flex;
  width: 100%;
}

.large-box {
  background-color: #fff;
  width: 35%;
  height: 50vh;
  padding: 20;
  margin: 5%;
  margin-top: 2%;
  border: 1px solid black;
  border-radius: 10%;
  cursor: pointer;
}

.small-boxes {
  width: 50%;
  font-size: 20pt;
}

h5 {
  text-align: center;
  font-weight: bold;
  color: rgb(36, 24, 211);
  justify-content: center;
  margin-top: 20%;
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  width: 60vw;
}

.box {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 15px;
  max-width: 200px;
  min-width: 200px;
  height: 20vh;
  margin: 20px;
  border-radius: 20%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.BoxA {
  background-image: url("../../assets/images/Welcome.png");
  background-size: cover;
}

.BoxA1 {
  background-image: url("../../assets/images/Admission.png");
}

.BoxA2 {
  background-image: url("../../assets/images/MedHistory.png");
}

.BoxA3 {
  background-image: url("../../assets/images/NICU.png");
}

.BoxA4 {
  background-image: url("../../assets/images/Opht.png");
}

.BoxA5 {
  background-image: url("../../assets/images/Discharge.png");
}

.BoxA6 {
  background-image: url("../../assets/images/Audit.png");
}

.BoxA7 {
  background-image: url("../../assets/images/Appointment.png");
}

.BoxA8 {
  background-image: url("../../assets/images/MedicalRecord.png");
}
</style>
