<template>
  <div :class="['sidebar', { 'sidebar-collapsed': isCollapsed }]">
    <!-- Render Sidebar Items -->
    <div v-for="item in sidebarItems" :key="item.name" class="sidebar-link-wrapper">
      <router-link
        v-if="hasAccess(item.permission)"
        :to="{ name: item.route }"
        class="card text-center square-border sidebar-link"
        :class="{ active: RSideActiveBtn === item.name }"
        @click="selectRSideActiveBtn(item.name)">
        <div class="card-body">
          <i :class="item.icon" class="sidebar-icon"></i>
          <span v-if="!isCollapsed" class="sidebar-label">{{ item.label }}</span>
        </div>
      </router-link>
    </div>
    <hr />

    <!-- Toggle Button -->
    <button @click="toggleSidebar" class="toggle-button">
      <svg v-if="isCollapsed" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="toggle-icon">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
      <svg v-else fill="none" viewBox="0 0 24 24" stroke="currentColor" class="toggle-icon">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
      </svg>
    </button>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import permissionMixin from '@/Mixin/permissionMixin';

export default {
  mixins: [permissionMixin],
  data() {
    return {
      isCollapsed: false
    };
  },
  computed: {
    ...mapGetters(["RSideActiveBtn", "AccessLvl"]),
    sidebarItems() {
      return [
        {
          name: "Admission",
          route: "Admission",
          label: "پذیرش",
          icon: "bi bi-door-open",
          permission: "Admission",
        },
        {
          name: "MedHistory",
          route: "MedicalHistory",
          label: "تاریخچه",
          icon: "bi bi-journal-medical",
          permission: "MedHistory",
        },
        {
          name: "NicuVisit",
          route: "NICU",
          label: "NICU",
          icon: "bi bi-hospital",
          permission: "NICU",
        },
        {
          name: "OphtVisit",
          route: "Ophthalmology",
          label: "چشم‌پزشکی",
          icon: "bi bi-eyeglasses",
          permission: "Ophthalmology",
        },
        {
          name: "Discharge",
          route: "Discharge",
          label: "ترخیص",
          icon: "bi bi-box-arrow-in-right",
          permission: "Discharge",
        },
        {
          name: "Appointment",
          route: "Appointment",
          label: "نوبت",
          icon: "bi bi-calendar",
          permission: "Appointment",
        },
        {
          name: "FollowUp",
          route: "FollowUp",
          label: "پیگیری",
          icon: "bi bi-clock-history",
          permission: "FollowUp",
        },

        {
          name: "MedicalRecord",
          route: "MedicalRecord",
          label: "سوابق",
          icon: "bi bi-card-list",
          permission: "MedRecords",
        }
      ];
    }
  },
  methods: {
    ...mapActions(["SetRSideActiveBtn"]),

    selectRSideActiveBtn(section) {
      this.SetRSideActiveBtn(section);
    },

    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
    },
  }
};
</script>
<style scoped>
.sidebar {
  height: 90vh;
  padding: 10px;
  width: 10vw;
  border-right: 1px solid #ddd;
  background-color: #f8f9fa;
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.sidebar-collapsed {
  width: 60px;
}

.sidebar-link-wrapper {
  margin-bottom: 10px;
}

.sidebar-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.card.text-center {
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  border: none;
}

.card-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  color: #333;
  padding: 5px;
  width: 115px;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
.card-body:hover {
  background: blue;
  color: #ffffff;
  justify-content: center;
  align-items: center;
}
.sidebar-collapsed .card-body {
  justify-content: center;
}

.sidebar-icon {
  font-size: 20px;
  margin-right: 10px;
  margin-left: 10px;
  transition: margin-right 0.3s ease;
  text-align: right;
}

.sidebar-collapsed .sidebar-icon {
  margin: 0;
}

.sidebar-label {
  font-size: 14px;
  font-weight: 500;
  transition: opacity 0.3s ease, margin-left 0.3s ease;
  margin-left: 10px;
}

.sidebar-collapsed .sidebar-label {
  opacity: 0;
  margin-left: 0;
  pointer-events: none;
}

.card.active .card-body {
  background-color: blue;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.card.active .card-body:hover {
  color: #e60000;
}

.toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 30px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.toggle-icon {
  font-size: 20px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }
}

@media (max-width: 576px) {
  .sidebar {
    width: 100%;
    height: auto;
  }
  .sidebar-collapsed {
    width: 100%;
  }
}
</style>
