<template>
  <transition name="slide-left">
    <div v-if="isVisible" :class="['message', type === 'success' ? 'success' : type === 'warning' ? 'warning' : 'error']">

      <p>{{ message }}</p>
      <button @click="hideMessage">بستن</button>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
      validator: value => ['success', 'error'].includes(value)
    },
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isVisible: true
    };
  },
  methods: {
    hideMessage() {
      this.isVisible = false;
    }
  },
  mounted() {
    setTimeout(() => {
      this.hideMessage();
    }, 2000); 
  }
};
</script>

<style scoped>
.message {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 30%;
  height: 7%;
  padding: 10px;
  border-radius: 5px;
  left: 0;
  z-index: 999;
}

.success {
  background-color: rgb(96, 248, 96);
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: rgb(245, 51, 51);
  color: #ffffff;
  border: 1px solid #f5c6cb;
}
.warning {
  background-color: rgb(250, 250, 133);
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.message p {
  margin: 0;
  font-size: 16px;
}

.message button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: inherit;
  margin-left: 10px;
}

.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 0.5s;
}

.slide-left-enter, .slide-left-leave-to {
  transform: translateX(-100%);
}
</style>
