<template>
  <Message-Display ref="messageComponent" />
  <search-patient @emitPatientList="SearchPatient" @cancelSearch="fetchPatientList" />
  <the-snippet v-if="showSnippet" />
  <div v-else >
    <table class="content slide-in">
      <thead>
        <tr>
          <th>ردیف</th>
          <th v-for="col in columns" :key="col.key" @click="sortTable(col.key)" :class="getSortClass(col.key)">
            {{ col.label }}
          </th>
          <th>عملیات</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(admission, index) in paginatedPatients" :key="admission.id">
          <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
          <td v-for="col in columns" :key="col.key">{{ admission[col.key] }}</td>
          <td>
            <template v-for="button in getButtons(admission)" :key="button.text">
              <component :is="button.type === 'router-link' ? 'router-link' : 'button'" :to="button.to" :class="button.class" @click="button.click" v-if="hasAccess(button.permission)">
                {{ button.text }}
              </component>
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="RowNumber">
      <div class="form-group">
        <button class="btn btn-primary operation-button" @click="prevPage" :disabled="currentPage === 1">قبلی</button>
        <span>صفحه {{ currentPage }} از {{ totalPages }}</span>
        <button class="btn btn-success operation-button" @click="nextPage" :disabled="currentPage === totalPages">بعدی</button>
      </div>
      <label>تعداد:</label>
      <input type="number" v-model="itemsPerPage" />
      <span>تعداد نمایش داده شده: {{ paginatedPatients.length }} از {{ PatientInfoList.length }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import api from "../../../Api/TheApi.js";
import SearchPatient from "../SearchPatient.vue";
import permissionMixin from "@/Mixin/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  components: { SearchPatient },
  props: ["confirmDel"],
  data() {
    return {
      PatientInfoList: [],
      currentPage: 1,
      itemsPerPage: 7,
      sortKey: "AdmissionCode",
      sortOrder: "desc",
      confirmDelete: false,
      deleteItem: "",
      deleteType: "",
      showSnippet: true,
      columns: [
        { key: "RecordCode", label: "کد پرونده" },
        { key: "AdmissionCode", label: "کد پذیرش" },
        { key: "NewbornFirstName", label: "نام" },
        { key: "NewbornLastName", label: "نام خانوادگی" },
        { key: "FatherFirstName", label: "نام پدر" },
        { key: "MotherFirstName", label: "نام مادر" },
        { key: "AdmissionAge", label: "سن" },
        { key: "admission_type", label: "نوع پذیرش" },
        { key: "admission_place", label: "بخش" },
        { key: "AdmissionDate", label: "تاریخ پذیرش" }
      ]
    };
  },
  computed: {
    paginatedPatients() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.sortedPatients.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.PatientInfoList.length / this.itemsPerPage);
    },
    sortedPatients() {
      return [...this.PatientInfoList].sort((a, b) => {
        const order = this.sortOrder === "asc" ? 1 : -1;
        return a[this.sortKey] < b[this.sortKey] ? -order : order;
      });
    }
  },
  mounted() {
    this.fetchPatientList();
  },
  methods: {
    ...mapActions(["setSelectedPatient"]),

    getButtons(admission) {
      const buttons = [
        {
          text: "پذیرش مجدد",
          to: { name: "Register" },
          type: "router-link",
          class: "btn btn-outline-success operation-button",
          click: () => {
            const identifier = admission.NewbornNationalCode ? admission.NewbornNationalCode : admission.RecordCode;
            const identifierType = admission.NewbornNationalCode ? "national-code" : "record-code";
            this.SelectedPatient(identifier, identifierType, "Admission");
          },
          permission: "ReAdmission"
        },
        {
          text: "ویرایش مشخصات",
          to: { name: "NewPatient" },
          type: "router-link",
          class: "btn btn-outline-primary operation-button",
          click: () => {
            const identifier = admission.NewbornNationalCode ? admission.NewbornNationalCode : admission.RecordCode;
            const identifierType = admission.NewbornNationalCode ? "national-code" : "record-code";
            this.SelectedPatient(identifier, identifierType, "EditPatientInfo");
          },
          permission: "EditDemographic"
        },
        {
          text: "ویرایش پذیرش",
          to: { name: "Register" },
          type: "router-link",
          class: "btn btn-outline-primary operation-button",
          click: () => this.SelectedPatient(admission.AdmissionCode, "admission-code", "EditAdmission"),
          permission: "EditAdmission"
        },
        {
          text: "حذف پذیرش",
          class: "btn btn-outline-danger operation-button",
          click: () => this.ShowDeleteModal(admission.AdmissionCode, "admission"),
          permission: "DelAdmission"
        },
        {
          text: "حذف بیمار",
          class: "btn btn-outline-danger operation-button",
          click: () => {
            const identifier = admission.NewbornNationalCode ? admission.NewbornNationalCode : admission.RecordCode;
            this.ShowDeleteModal(identifier, "demographic");
          },
          permission: "DelPatient"
        }
      ];
      return buttons;
    },

    async fetchPatientList() {
      try {
        this.showSnippet = true;
        const { data } = await api.get(`admission-list/`);
        this.fetchDataForTable(data);
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        this.handleError(error);
      }
    },
    async SearchPatient(payload) {
      try {
        const firstNameLength = payload.FirstName ? payload.FirstName.length : 0;
        const lastNameLength = payload.LastName ? payload.LastName.length : 0;        
        if ((firstNameLength > 2 && lastNameLength === 0) || (firstNameLength === 0 && lastNameLength > 2) || 
        (firstNameLength > 2 && lastNameLength > 2) || (firstNameLength === 0 && lastNameLength === 0) ) {
          this.showSnippet = true;
          const { data } = await api.get(`admission-list/`, { params: payload });
          this.fetchDataForTable(data);
          this.showSnippet = false;
        } 
      } catch (error) {
        this.showSnippet = false;
        this.handleError(error);
      }
    },

    fetchDataForTable(data) {
      this.PatientInfoList = data
        .flatMap(patient => {
          if (patient.admissions === null || patient.admissions.length === 0) {
            return [
              {
                RecordCode: patient.id,
                ...patient,
                AdmissionCode: "یافت نشد",
                AdmissionDate: "یافت نشد",
                admission_type: "یافت نشد",
                admission_place: "یافت نشد"
              }
            ];
          } else {
            return patient.admissions.map(admission => ({
              ...admission,
              RecordCode: patient.id,
              ...patient
            }));
          }
        })
        .filter(patient => patient.AdmissionCode || patient.RecordCode);
      this.currentPage = 1;
      return this.PatientInfoList;
    },
    async SelectedPatient(code, type, form) {
      try {
        const { data } = await api.get(`patient-info/${code}/${type}`);
        await this.setSelectedPatient(data);
        this.$emit(`Show${form}Form`);
      } catch (error) {
        this.handleError(error);
      }
    },

    ShowDeleteModal(code, type) {
      this.deleteItem = code;
      this.deleteType = type;
      this.$emit("ShowModal", type);
    },

    async DeletePatient() {
      if (!this.confirmDelete) return;
      try {
        this.showSnippet = true;
        const { status } = await api.delete(`patient-list/${this.deleteItem}/${this.deleteType}/delete`);
        this.showSnippet = false;
        this.$refs.messageComponent.showMessage(status);
      } catch (error) {
        this.showSnippet = false;
        this.handleError(error);
      } finally {
        this.resetDeleteState();
        this.fetchPatientList();
      }
    },
    resetDeleteState() {
      Object.assign(this, { deleteItem: "", confirmDelete: false });
      this.$emit("resetDelete");
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    sortTable(key) {
      this.sortOrder = this.sortKey === key && this.sortOrder === "asc" ? "desc" : "asc";
      this.sortKey = key;
    },
    getSortClass(key) {
      return this.sortKey === key ? this.sortOrder : "";
    },
    handleError(error) {
      if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
    }
  },
  watch: {
    confirmDel(newVal) {
      if (newVal) {
        this.confirmDelete = newVal;
        this.DeletePatient();
      }
    }
  }
};
</script>

<style scoped>
.operation-button {
  width: 55px;
  padding: 2px;
  height: 34px;
  margin: 0 0 0 3px;
  font-size: 8pt;
  font-weight: bold;
  justify-content: center;
  align-content: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
td,
th {
  background-color: #fff;
  border: 1px solid #eedfdf;
  padding: 5px;
}
th {
  background-color: #f2f2f2;
  cursor: pointer;
  border: 1px solid #adb1ad;
}
th.asc::after {
  content: " 🔼";
}
th.desc::after {
  content: " 🔽";
}
.RowNumber {
  display: flex;
  justify-content: right;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}
.RowNumber .form-group,
.RowNumber button,
.RowNumber span,
.RowNumber label {
  margin: 5px;
}
.RowNumber input {
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
@media (max-width: 1280px) {
  table,
  th,
  td {
    font-size: 9pt;
  }
}
</style>
