import FollowUp from "../../../pages/DataEntry/FollowUp/FollowUp.vue";
import FollowUpPatient from "../../../pages/DataEntry/FollowUp/FollowUpPatient.vue";
import store from "../../../Store/store";

export default [
  {
    path: "/DataEntry/FollowUp",
    name: "FollowUp",
    component: FollowUp,
    beforeEnter: (to, from, next) => {
      store.dispatch("SetRSideActiveBtn", "FollowUp");
      next();
    },
    children: [
      {
        path: "FollowUpPatient",
        name: "FollowUpPatient",
        component: FollowUpPatient
      }
    ]
  }
];
