// store/modules/demographics.js
import axios from 'axios';

const state = {
  sexOptions: [],
  nationalityOptions: [],
  religionOptions: [],
  ethnicityOptions: [],
  educationLevelOptions: [],
  occupationOptions: [],
  provinceOptions: [],
  countyOptions: []
};

const getters = {
  sexOptions: state => state.sexOptions,
  nationalityOptions: state => state.nationalityOptions,
  religionOptions: state => state.religionOptions,
  ethnicityOptions: state => state.ethnicityOptions,
  educationLevelOptions: state => state.educationLevelOptions,
  occupationOptions: state => state.occupationOptions,
  provinceOptions: state => state.provinceOptions,
  countyOptions: state => state.countyOptions
};

const actions = {
  async fetchDemographics({ commit }) {
    try {
      const response = await axios.get('/api/demographics');
      commit('setDemographics', response.data);
    } catch (error) {
      console.error('Error fetching demographics:', error);
    }
  }
};

const mutations = {
  setDemographics(state, data) {
    state.sexOptions = data.sexOptions;
    state.nationalityOptions = data.nationalityOptions;
    state.religionOptions = data.religionOptions;
    state.ethnicityOptions = data.ethnicityOptions;
    state.educationLevelOptions = data.educationLevelOptions;
    state.occupationOptions = data.occupationOptions;
    state.provinceOptions = data.provinceOptions;
    state.countyOptions = data.countyOptions;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
