<template>
  <Message-Display ref="messageComponent" />
  <search-patient @emitPatientList="SearchPatient" @cancelSearch="fetchPatientList"/>
  <the-snippet v-if="showSnippet" />
  <div v-else class="content slide-in">

    <table>
      <thead>
        <tr>
          <th>ردیف</th>
          <th v-for="col in columns" :key="col.key" @click="sortTable(col.key)" :class="getSortClass(col.key)">
            {{ col.label }}
          </th>
          <th>عملیات</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(admission, index) in paginatedPatients" :key="admission.id">
          <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
          <td v-for="col in columns" :key="col.key">{{ admission[col.key] }}</td>
          <td>
            <template v-for="button in getButtons(admission)" :key="button.text">
              <component :is="button.type === 'router-link' ? 'router-link' : 'button'" :to="button.to" :class="button.class" @click="button.click">
                {{ button.text }}
              </component>
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="RowNumber">
      <div class="form-group">
        <button class="btn btn-primary operation-button" @click="prevPage" :disabled="currentPage === 1">قبلی</button>
        <span>صفحه {{ currentPage }} از {{ totalPages }}</span>
        <button class="btn btn-success operation-button" @click="nextPage" :disabled="currentPage === totalPages">بعدی</button>
      </div>
      <label>تعداد:</label>
      <input type="number" v-model="itemsPerPage" />
      <span>تعداد نمایش داده شده: {{ paginatedPatients.length }} از {{ PatientInfoList.length }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import api from "../../../Api/TheApi.js";
import SearchPatient from "../SearchPatient.vue";

export default {
  components: { SearchPatient },
  props: ["confirmDel"],
  data() {
    return {
      showSnippet: true,
      PatientInfoList: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortKey: "AdmissionCode",
      sortOrder: "desc",
      confirmDelete: false,
      deleteItem: "",
      deleteType: "",
      columns: [
        { key: "RecordCode", label: "کد پرونده" },
        { key: "AdmissionCode", label: "کد پذیرش" },
        { key: "NewbornNationalCode", label: "کد ملی" },
        { key: "NewbornFirstName", label: "نام" },
        { key: "NewbornLastName", label: "نام خانوادگی" },
        { key: "age_in_weeks", label: "سن" },
        { key: "AdmissionDate", label: "تاریخ پذیرش" }
      ]
    };
  },
  computed: {
    paginatedPatients() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.sortedPatients.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.PatientInfoList.length / this.itemsPerPage);
    },
    sortedPatients() {
      return [...this.PatientInfoList].sort((a, b) => {
        const order = this.sortOrder === "asc" ? 1 : -1;
        return a[this.sortKey] < b[this.sortKey] ? -order : order;
      });
    }
  },
  methods: {
    ...mapActions(["setSelectedPatient"]),

    getButtons(admission) {
      const buttons = [
        {
          text: "مشاهده",
          to: { name: "NicuInterventions" },
          type: "router-link",
          class: "btn btn-outline-success operation-button",
          click: () => this.SelectedPatient(admission.AdmissionCode)
        },
        {
          text: "ویرایش",
          to: { name: "NicuInterventions" },
          type: "router-link",
          class: "btn btn-outline-primary operation-button",
          click: () => this.SelectedPatient(admission.AdmissionCode)
        },
        { text: "حذف", class: "btn btn-outline-danger operation-button", click: () => this.ShowDeleteModal(admission.AdmissionCode) }
      ];
      return buttons;
    },

    async fetchPatientList() {
      try {
        this.showSnippet = true;
        const { data } = await api.get(`nicu-list/`);
        this.PatientInfoList = data
          .flatMap(patient => {
            return patient.admissions.map(admission => ({
              ...admission,
              RecordCode: patient.id,
              ...patient
            }));
          })
          .filter(patient => patient.AdmissionDate || patient.RecordCode);
        this.currentPage = 1;
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        this.handleError(error);
      }
    },
    async SearchPatient(payload) {
      try {
        this.showSnippet = true;
        const { data } = await api.get(`nicu-list/`, { params: payload });
        this.PatientInfoList = data.flatMap(patient => {
          return patient.admissions.map(admission => ({
            ...admission,
            RecordCode: patient.id,
            ...patient
          }));
        });
        this.showSnippet = false;
      } catch (error) {
        this.showSnippet = false;
        this.handleError(error);
      }
    },
    async SelectedPatient(code) {
      try {
        const { data } = await api.get(`patient-info/${code}/admission-code`);
        await this.setSelectedPatient(data);
        this.$emit(`ShowNicuVisitForm`);
      } catch (error) {
        this.handleError(error);
      }
    },

    ShowDeleteModal(AdmissionCode) {
      Object.assign(this, { deleteItem: AdmissionCode });
      this.$emit("ShowModal");
    },
    async DeletePatient() {
      if (!this.confirmDelete) return;
      try {
        this.showSnippet = true;
        const { status } = await api.delete(`nicu/${this.deleteItem}/delete/`);
        this.showSnippet = false;
        this.$refs.messageComponent.showMessage(status);
      } catch (error) {
        this.showSnippet = false;
        this.handleError(error);
      } finally {
        this.resetDeleteState();
        this.fetchPatientList();
      }
    },
    resetDeleteState() {
      Object.assign(this, { deleteItem: "", confirmDelete: false });
      this.$emit("resetDelete");
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    sortTable(key) {
      this.sortOrder = this.sortKey === key && this.sortOrder === "asc" ? "desc" : "asc";
      this.sortKey = key;
    },
    getSortClass(key) {
      return this.sortKey === key ? this.sortOrder : "";
    },
    handleError(error) {
      if (error.response) this.$refs.messageComponent.showMessage(error.response.status);
    }
  },
  mounted() {
    this.fetchPatientList();
  },
  watch: {
    confirmDel(newVal) {
      if (newVal) {
        this.confirmDelete = newVal;
        this.DeletePatient();
      }
    }
  }
};
</script>

<style scoped>
.operation-button {
  width: 50px;
  padding: 0;
  height: 27px;
  margin: 0 0 0 3px;
  font-size: 8pt;
  font-weight: bold;
  justify-content: center;
  align-content: center;
}
  table {
    width: 99%;
    border-collapse: collapse;
    text-align: center;
  }
td,
th {
  background-color: #fff;
  border: 1px solid #eedfdf;
  padding: 5px;
}
th {
  background-color: #f2f2f2;
  cursor: pointer;
  border: 1px solid #adb1ad;
}
th.asc::after {
  content: " 🔼";
}
th.desc::after {
  content: " 🔽";
}
.RowNumber {
  display: flex;
  justify-content: right;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}
.RowNumber .form-group,
.RowNumber button,
.RowNumber span,
.RowNumber label {
  margin: 5px;
}
.RowNumber input {
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
@media (max-width: 1280px) {
  table,
  th,
  td {
    font-size: 9pt;
  }
}
</style>
