<template>
  <div class="row">
    <div class="col">
      <div v-for="(option, index) in options" :key="index" class="radioOption">
        <input
          type="radio"
          :id="option.id"
          :name="option.name"
          :value="option.value"
          @change="EmitSelected(option.value)"
          :checked="selectedRadio === option.value"
        />
        <label :for="option.id">{{ option.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["StdPopReset"],
  data() {
    return {
      options: [
        { id: "StdCrude", name: "StdPopualtion", value: "Crude", label: "خام" },
        { id: "StdIran", name: "StdPopualtion", value: "Iran", label: "ایران" },
        { id: "StdSegi", name: "StdPopualtion", value: "Segi", label: "Segi" },
        { id: "StdWho", name: "StdPopualtion", value: "WHO", label: "WHO" },
      ],
      selectedRadio: null,
    };
  },
  watch: {
    StdPopReset: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        this.selectedRadio = null; // Reset selected value
      }
    },
  },
  methods: {
    EmitSelected(payload) {
      this.selectedRadio = payload;
      this.$emit("selected", payload);
    },
  },
};
</script>

<style scoped>
.col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px;
}
.radioOption {
  width: 22%;
}
.radioOption input[type="radio"] {
  display: none;
}

.radioOption label {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: center;
}

.radioOption label:hover {
  background-color: #f0f0f0;
}

.radioOption input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.radioOption input[type="radio"]:checked + label:hover {
  background-color: #0056b3;
}
</style>
