// index.js
import { createRouter, createWebHistory } from "vue-router";
import store from "../Store/store";
import TheLogin from "../pages/UserAuth/theLogin.vue";
import TheHome from "../pages/Home/TheHome.vue";

import DataEntry from "../pages/DataEntry/Layout/DataEntry.vue";
import TheAdmissionRoutes from "./Modules/DataEntry/TheAdmission";
import MedicalRecord from "./Modules/DataEntry/MedicalRecord";
import Medhistory from "./Modules/DataEntry/Medhistory";
import TheNICU from "./Modules/DataEntry/NICU";
import TheOphthalmology from "./Modules/DataEntry/Ophthalmology";
import TheDischarge from "./Modules/DataEntry/discharge";
import FollowUp from "./Modules/DataEntry/FollowUp";
import TheAppointment from "./Modules/DataEntry/Appointment";

import TheDashboard from "../pages/Reporting/DashboardLayout.vue";
import byLocation from "./Modules/Dashboard/byLocation";
import byROP from "./Modules/Dashboard/byROP";
import AgePyramid from "./Modules/Dashboard/AgePyramid";
import DistPercent from "./Modules/Dashboard/DistPercent";
import LocPercent from "./Modules/Dashboard/LocPercent";
import GepMap from "./Modules/Dashboard/GeoMap";
import TheSetting from "../pages/UserAuth/UserAuth.vue";
import TheSupport from "../pages/Support/TheSupport.vue";

const routes = [
  { path: "/", redirect: "/Login" },
  { path: "/Login", name: "Login", component: TheLogin },
  {
    path: "/DataEntry",
    name: "DataEntry",
    component: DataEntry,
    children: [...TheAdmissionRoutes, ...MedicalRecord, ...Medhistory, ...TheNICU, ...TheOphthalmology, ...TheDischarge, ...FollowUp, ...TheAppointment],
    meta: { requiresAuth: true }
  },
  { path: "/Home", name: "Home", component: TheHome, meta: { requiresAuth: true } },
  { path: "/Dashboard",
    name: "Dashboard",
    component: TheDashboard, 
    children: [...byLocation, ...byROP,...AgePyramid,...DistPercent,...LocPercent,...GepMap],
    meta: { requiresAuth: true } 
  },
  { path: "/Setting", name: "Setting", component: TheSetting, meta: { requiresAuth: true } },
  { path: "/Support", name: "Support", component: TheSupport, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
