// sidebar.js

const state = {
    RSideActiveBtn: null,
  };
  
  const mutations = {
    SetRSideActiveBtn(state, buttonName) {
      state.RSideActiveBtn = buttonName;
    },
  };
  
  const actions = {
    SetRSideActiveBtn({ commit }, buttonName) {
      commit('SetRSideActiveBtn', buttonName);
    },
  };
  
  const getters = {
    RSideActiveBtn: state => state.RSideActiveBtn,
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  