<template>
  <div>
    <Message-Display ref="messageComponent" />
    <ReusableModal :isVisible="showModalBox" @confirm="deleteRecord" @cancel="cancelDelete" :Modaltext="Modaltext"> </ReusableModal>

    <med-history-list
      v-if="ShowPatientList"
      @ShowMedHistoryForm="ShowMedHis"
      @ShowModal="ShowModal"
      :confirmDel="confirmDelete"
      @resetDelete="resetConfirmDel" />

    <medical-history v-if="ShowMedicalHistory" @ShowMedHistPatientList="ShowPatList" :ShowMedHistory="ShowMedicalHistory"></medical-history>
  </div>
</template>

<script>
import ReusableModal from "../../../components/UI/TheModal.vue";
import MedicalHistory from "./MedicalHistory.vue";
import MedHistoryList from "./medHistoryList";

export default {
  components: {
    ReusableModal,
    MedHistoryList,
    MedicalHistory
  },
  data() {
    return {
      ShowPatientList: true,
      ShowMedicalHistory: false,

      showModalBox: false,
      confirmDelete: false,
      isVisible: false,
      Modaltext: "تاریخچه پزشکی بیمار حذف خواهد شد!"
    };
  },
  methods: {
    ShowPatList() {
      this.ShowPatientList = true;
      this.ShowMedicalHistory = false;
    },
    ShowMedHis() {
      this.ShowPatientList = false;
      this.ShowMedicalHistory = true;
    },

    ShowModal() {
      this.showModalBox = true;
    },

    deleteRecord() {
      this.confirmDelete = true;
      this.showModalBox = false;
    },
    resetConfirmDel() {
      this.confirmDelete = false;
    },
    cancelDelete() {
      this.showModalBox = false;
    }
  }
};
</script>
