<template>
  <div>
    <MessageDisplay ref="messageComponent" />

    <ReusableModal :isVisible="showModalBox" @confirm="deleteRecord" @cancel="cancelDelete" :Modaltext="modaltext"> </ReusableModal>

    <appointment-list
      @ShowAppointmentForm="ActiveAppointmentForm"
      v-if="ShowAppointmentList"
      @ShowMsgBox="showMsgBox"
      @ShowModal="ShowModal"
      :confirmDel="confirmDelete"
      @resetDelete="resetConfirmDel"
    ></appointment-list>
    <set-appointment
      v-if="ShowAppointmentForm"
      @ShowAppointmentListForm="ActiveAppointmentList"
    ></set-appointment>
  </div>
</template>
  
  <script>
import AppointmentList from "./AppointmentList";
import SetAppointment from "./SetAppointment.vue";

import ReusableModal from "../../../components/UI/TheModal.vue";


export default {
  components: {
    ReusableModal,
    SetAppointment,
    AppointmentList,
  },
  data() {
    return {
      ShowAppointmentList: true,
      ShowAppointmentForm: false,

      showModalBox: false,
      confirmDelete: false,
      isVisible: false,
      modaltext: 'اطلاعات نوبت حذف خواهد شد!',
    };
  },
  methods: {
    ActiveAppointmentList() {
      this.ShowAppointmentList = true;
      this.ShowAppointmentForm = false;
    },
    ActiveAppointmentForm() {
      this.ShowAppointmentList = false;
      this.ShowAppointmentForm = true;
    },
    showMsgBox(payload) {
      if (payload == true) {
        this.$refs.messageComponent.showMessage(204)
      } else {
        this.$refs.messageComponent.showMessage()
      }
    },
    ShowModal() {
      this.showModalBox = true;
    },

    deleteRecord() {
      this.confirmDelete = true;
      this.showModalBox = false;
    },
    resetConfirmDel() {
      this.confirmDelete = false;
    },
    cancelDelete() {
      this.showModalBox = false;
    }
    
  },
};
</script>
  