<template>
    <div class="content">
      <the-snippet v-show="loading"></the-snippet>
      <canvas v-show="!loading && !ShowMsgBox" class="result-plot" ref="resultChart"></canvas>
      <alert-message v-show="ShowMsgBox" @click="this.ShowMsgBox=false"></alert-message>
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from "vue";
  import Chart from "chart.js/auto";
  import chroma from "chroma-js";
  import TheSnippet from "../../../components/UI/TheSnippet.vue";
  
  export default {
    components: { TheSnippet },
    setup() {
      const ShowMsgBox = ref(false);
      const resultChart = ref(null);
      const loading = ref(false);
      let chartInstance = null;
  
      // Temporary data
      const provinces = [
        { Province_Name: "Province 1", who_asr: 30 },
        { Province_Name: "Province 2", who_asr: 50 },
        { Province_Name: "Province 3", who_asr: 70 },
        { Province_Name: "Province 4", who_asr: 20 },
        { Province_Name: "Province 5", who_asr: 40 },
      ];
  
      const UpdateChart = () => {
        provinces.sort((a, b) => b.who_asr - a.who_asr);
  
        const labels = provinces.map((province) => province.Province_Name);
        const WhoASR = provinces.map((province) => province.who_asr);
  
        // Define colors for each bar dynamically
        const backgroundColors = generateColors(provinces.length);
        Chart.defaults.font.family = "Vazirmatn";
        Chart.defaults.font.weight = "bold";
  
        if (chartInstance) {
          addData(chartInstance, labels, WhoASR);
        } else {
          const ctx = resultChart.value.getContext("2d");
          chartInstance = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Province",
                  backgroundColor: backgroundColors,
                  borderColor: backgroundColors.map((color) =>
                    chroma(color).alpha(1).css()
                  ),
                  borderWidth: 1.2,
                  data: WhoASR,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              legend: {
                position: "left",
                maxWidth: 200,
                labels: {
                  font: {
                    size: 11,
                  },
                  boxWidth: 20,
                },
              },
              scales: {
                x: {
                  beginAtZero: true,
                },
                y: {
                  beginAtZero: true,
                },
              },
            },
          });
        }
      };
  
      onMounted(() => {
        UpdateChart(); // Use temporary data on mount
      });
  
      return { loading, ShowMsgBox, resultChart };
    },
  };
  
  // Helper functions
  function generateColors(count) {
    const scale = chroma.scale(["red", "orange", "green", "blue"]);
    const colors = scale.colors(count);
    return colors.map((color) => chroma(color).alpha(0.4).css());
  }
  
  function addData(chart, labels, currentData) {
    chart.data.labels = labels;
    chart.data.datasets[0].data = currentData;
    chart.update();
  }
  </script>
  
  <style scoped>
  .content {
    height: 87vh;
    width: 66vw; 
  }
  </style>
  