<template>
  <div class="segmented-controls">
    <label
      v-for="(option, index) in options"
      :key="index"
      :class="['segmented-control', { 'checked': selectedOptions.includes(option) }]"
    >
      <input
        :type="InputType"
        :value="option"
        v-model="selectedOptions"
        @change="emitSelectedOptions"
      />
      <span>{{ option }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    InputType: {
      type: String,
      required: true,
      validator: (value) => ['radio', 'checkbox'].includes(value),
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOptions: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.selectedOptions = newVal;
      
    },
  },
  methods: {
    emitSelectedOptions() {
      this.$emit('inputData', this.selectedOptions);
    },
    reset() {
      this.selectedOptions = [];
    },
  },
};
</script>

<style scoped>
.segmented-controls {
  display: flex;
  flex-wrap: wrap;
}

.segmented-control {
  margin: 2px 2px;
  display: flex;
  align-items: center;
  
}

.segmented-control input {
  display: none;
  
}

.segmented-control span {
  display: inline-block;
  padding: 5px 5px;
  border: 1px solid #b1b1b1;
  cursor: pointer;
  user-select: none;
  white-space: nowrap; /* Prevents line breaks */
  word-wrap: normal;    /* Resets word-wrap behavior */
}


.segmented-control.checked span {
  background-color: #64acf8;
  color: white;
}
</style>
