import { createApp } from "vue";
import App from "./App.vue";
import router from "./Router/index.js";

// Import Bootstrap CSS and JS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap.bundle includes Popper.js

// Import Bootstrap Icons
import "bootstrap-icons/font/bootstrap-icons.css";

// Import custom styles
import "./assets/css/rop-style.css";
import "vazirmatn/Vazirmatn-font-face.css";

// Vuex store
import store from "./Store/store.js";

// Persian Datetime Picker
import VuePersianDatetimePicker from "vue3-persian-datetime-picker";

// Import custom components
import MainNavbar from "./components/Layout/MainNavbar.vue";
import MessageDisplay from "./components/Layout/MessageDisplay.vue";
import TheSnippet from "./components/UI/TheSnippet.vue";
import PatientInfo from "./pages/DataEntry/PatientInfo.vue";

const app = createApp(App);

// Use router, store, and VuePersianDatetimePicker
app.use(router);
app.use(store);
app.use(VuePersianDatetimePicker, {
  name: "Persian-Date",
  props: {
    locale: "fa",
    clearable: true,
    autoSubmit: true,
    color: "#00acc1",
    type: "date",
    format: "jYYYY-jMM-jDD",
    displayFormat: "jYYYY-jMM-jDD",
  },
});

// Register global components
app.component("Main-Navbar", MainNavbar);
app.component("Message-Display", MessageDisplay);
app.component("Patient-Info", PatientInfo);
app.component("the-snippet", TheSnippet);

app.mount("#app");
