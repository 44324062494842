<template>
  <div>
    <input
      ref="rangeSlider"
      type="text"
      :class="['js-range-slider']"
      name="my_range"
      value=""
    />
  </div>
</template>

<script>
import "ion-rangeslider";
import "ion-rangeslider/css/ion.rangeSlider.min.css";
import $ from "jquery";
window.jQuery = $;
window.$ = $;

export default {
  props: [
    "type",
    "skin",
    "min",
    "max",
    "step",
    "applyCustomClass",
    "ResetRange",
  ],
  mounted() {
    this.initializeSlider();
  },
  watch: {
    type: "updateSliderType",
    min: "updateSliderMin",
    max: "updateSliderMax",
    from: "updateSliderFrom",
    step: "updateSliderStep",
    skin: "updateSliderSkin",
    ResetRange(newValue) {
      if (newValue) {
        this.ResetRangeSlider();
      }
    },
  },
  data() {
    return {
      LowValue: null,
      HighValue: null,
    };
  },
  methods: {
    initializeSlider() {
      const rangeSlider = this.$refs.rangeSlider;

      this.slider = $(rangeSlider)
        .ionRangeSlider({
          type: this.type || "double",
          min: parseInt(this.min) || 0,
          max: parseInt(this.max) || 100,
          from: parseInt(this.from) || 0,
          step: parseInt(this.step) || 5,
          grid: true,
          skin: this.skin || "modern",
          onChange: () => {
            this.LowValue = this.slider.result.from;
            this.HighValue = this.slider.result.to;

            this.RangeSliderValues();
          },
        })
        .data("ionRangeSlider");
    },
    updateSliderType() {
      if (this.slider) {
        this.slider.update({ type: this.type });
      }
    },
    updateSliderMin() {
      if (this.slider) {
        this.slider.update({ min: this.min });
      }
    },
    updateSliderMax() {
      if (this.slider) {
        this.slider.update({ max: this.max });
      }
    },
    updateSliderFrom() {
      if (this.slider) {
        this.slider.update({ from: this.from });
      }
    },
    updateSliderStep() {
      if (this.slider) {
        this.slider.update({ step: this.step });
      }
    },
    updateSliderSkin() {
      if (this.slider) {
        this.slider.update({ skin: this.skin });
      }
    },

    RangeSliderValues() {
      let rangeTuple = [];
      if (this.min == this.LowValue && this.max == this.HighValue) {
        rangeTuple = [];
      } else {
        rangeTuple = [this.LowValue, this.HighValue];
      }
      this.$emit("RangeSlider", rangeTuple);
    },
    ResetRangeSlider() {
      this.slider.reset();
      this.$emit("RemoveRange");
    },
  },
};
</script>

<style>
.js-range-slider {
  font-size: 10pt;
}
.irs--modern .irs-bar,
.irs--modern .irs-from,
.irs--modern .irs-to {
  background: #428bca;
  font-size: 10pt;
}
.irs-from,
.irs-to {
  font-size: 10pt;
}

.irs--big .irs-handle {
  border-radius: 0;
  width: 20px;
  height: 25px;
}
</style>
