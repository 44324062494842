<template>
    <Message-Display ref="messageComponent" />

    <div class="modal-overlay">
      <div class="modal-content">
        <h5 class="modal-title">ادغام و یکی کردن اطلاعات</h5>
  
        <!-- Correct Record Section -->
        <div class="row">
          <label for="correctRecordNumber">شماره پرونده صحیح را وارد کنید:</label>
          <input
            type="text"
            v-model="correctRecordNumber"
            @input="debouncedFetchCorrectRecord('correct-record')"
            placeholder="شماره پرونده"
            class="input-field"
            @click="correctErrorMessage=''"
          />
          <input type="text" v-model="correctName" readonly placeholder="نام" class="input-field" />
          <input type="text" v-model="correctFamily" readonly placeholder="نام خانوادگی" class="input-field" />
          <input type="text" v-model="correctFatherName" readonly placeholder="نام پدر" class="input-field" />
          <input type="text" v-model="correctMotherName" readonly placeholder="نام مادر" class="input-field" />
        </div>
  
        <div class="row description">
          <p>این شماره پرونده اصلی است و به درستی ثبت شده است.</p>
          <p v-if="correctErrorMessage" style="color: red; font-weight: 600">{{  correctErrorMessage }}</p>
        </div>
  
        <!-- Mistake Record Section -->
        <div class="row">
          <label for="mistakeRecordNumber">شماره پرونده تکراری و اشتباه را وارد کنید:</label>
          <input
            type="text"
            @input="debouncedFetchCorrectRecord('mistake-record')"
            v-model="mistakeRecordNumber"
            placeholder="شماره پرونده"
            class="input-field"
            @click="mistakeErrorMessage=''"
          />
          <input type="text" v-model="mistakeName" readonly placeholder="نام" class="input-field" />
          <input type="text" v-model="mistakeFamily" readonly placeholder="نام خانوادگی" class="input-field" />
          <input type="text" v-model="mistakeFatherName" readonly placeholder="نام پدر" class="input-field" />
          <input type="text" v-model="mistakeMotherName" readonly placeholder="نام مادر" class="input-field" />
        </div>
  
        <div class="row description">
          <p>این شماره پرونده اشتباه است و حذف خواهد گردید.</p>
          <p v-if="mistakeErrorMessage" style="color: red; font-weight: 600">{{  mistakeErrorMessage }}</p>
          <p v-if="mergeErrorMessage" style="color: red; font-weight: 600">{{  mergeErrorMessage }}</p>
        </div>
  
        <!-- Action Buttons -->
        <div class="buttons">
          <button class="btn btn-success" @click="mergeRecords">ثبت</button>
          <button class="btn btn-danger" @click="cancel">انصراف</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import api from "../../../Api/TheApi.js";
  import { ref } from "vue";
  
  export default {
    setup(props, { emit }) {
      // Correct Record Data
      const correctRecordNumber = ref("");
      const correctName = ref("");
      const correctFamily = ref("");
      const correctFatherName = ref("");
      const correctMotherName = ref("");
  
      // Mistake Record Data
      const mistakeRecordNumber = ref("");
      const mistakeName = ref("");
      const mistakeFamily = ref("");
      const mistakeFatherName = ref("");
      const mistakeMotherName = ref("");
      
      const correctErrorMessage = ref("");
      const mistakeErrorMessage = ref("");
      const mergeErrorMessage = ref("");
      let fetchTimeout = null;
  
      // Fetch the correct or mistake record details based on type
    const fetchCorrectRecord = async (type) => {
      try {
        if (correctRecordNumber.value.trim() !== "" || mistakeRecordNumber.value.trim() !== "") {
          const recordNumber = type === 'correct-record' ? correctRecordNumber.value : mistakeRecordNumber.value;
          const { data } = await api.get(`/record-detail/${recordNumber}`);
          
          if (data) {
            if (type === 'correct-record') {
              correctName.value = data.NewbornFirstName;
              correctFamily.value = data.NewbornLastName;
              correctFatherName.value = data.FatherFirstName;
              correctMotherName.value = data.MotherFirstName;
            } else if (type === 'mistake-record') {
              mistakeName.value = data.NewbornFirstName;
              mistakeFamily.value = data.NewbornLastName;
              mistakeFatherName.value = data.FatherFirstName;
              mistakeMotherName.value = data.MotherFirstName;
            }
          }
        }
      } catch (error) {
        if (type === 'correct-record') {
          correctName.value = "";
          correctFamily.value = "";
          correctFatherName.value = "";
          correctMotherName.value = "";
          correctErrorMessage.value = 'کد پرونده اشتباه است'
        } else if (type === 'mistake-record') {
          mistakeName.value = "";
          mistakeFamily.value = "";
          mistakeFatherName.value = "";
          mistakeMotherName.value = "";
          mistakeErrorMessage.value = 'کد پرونده اشتباه است'
        }
      }
    };

    // Debounced function that delays the fetch request
    const debouncedFetchCorrectRecord = (type) => {
      clearTimeout(fetchTimeout);
      fetchTimeout = setTimeout(() => fetchCorrectRecord(type), 1000);
    };

      // Handle the merge action
      const mergeRecords = async () => {
        try {
          const payload = {
            correctRecord: correctRecordNumber.value,
            mistakeRecord: mistakeRecordNumber.value,
          };
          const { status } = await api.post("/merge-duplicates/", payload);
          if (status==200) mergeErrorMessage.value='فرآیند ادغام و یکی کردن با موفقیت انجام شد'
        } catch (error) {
          mergeErrorMessage.value='در فرآیند ادغام و یکی کردن خطا رخ داده است'
        }
      };
  
      // Close the modal
      const cancel = () => {
        emit("closeModal");
      };
  
      return {
        correctRecordNumber,
        correctName,
        correctFamily,
        correctFatherName,
        correctMotherName,
        mistakeRecordNumber,
        mistakeName,
        mistakeFamily,
        mistakeFatherName,
        mistakeMotherName,
        fetchCorrectRecord,
        mergeRecords,
        cancel,
        debouncedFetchCorrectRecord,
        correctErrorMessage,
        mistakeErrorMessage,
        mergeErrorMessage
      };
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Dark transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px); 
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    width: 700px;
    max-width: 90%;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    position: relative;
    transition: all 0.3s ease;
    animation: modalAppear 0.3s ease;
  }
  
  @keyframes modalAppear {
    from {
      transform: scale(0.95);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 5px;
    margin-bottom: 10px;
    color: #4a4a4a;
    text-align: center;
    border-bottom: 2px solid blueviolet;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .input-field {
    flex: 1;
    margin-left: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .input-field:focus {
    border-color: #007bff;
  }
  
  .description {
    color: #6c757d;
    text-align: right;
    flex-basis: 100%;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-success {
    background-color: #28a745;
    color: white;
    border: none;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  
  .btn-danger {
    background-color: #dc3545;
    color: white;
    border: none;
  }
  
  .btn-danger:hover {
    background-color: #c82333;
  }
  </style>
  