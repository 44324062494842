// Admission patinet info.js

const state = {
    SelectedPatient: {},
  };
  
  const mutations = {
    Set_Selected_Patient(state, data) {
      state.SelectedPatient = {};
      state.SelectedPatient = data;
    },
    CLEAR_SELECTED_PATIENT(state) {
      state.SelectedPatient={}
    }
  };
  
  const actions = {
    async setSelectedPatient({ commit }, data) {
      await commit('Set_Selected_Patient', data);
    },
     async clearSelectedPatient({ commit }) {
      await commit('CLEAR_SELECTED_PATIENT');
    },
  };
  
  const getters = {
    SelectedPatient: (state) => state.SelectedPatient,
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  