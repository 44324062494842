import MainAdmission from "../../../pages/DataEntry/Admission/TheAdmission.vue";
import NewPatient from "../../../pages/DataEntry/Admission/NewPatient.vue";
import AdmissionForm from "../../../pages/DataEntry/Admission/AdmissionForm.vue";
import MedicalHistory from "../../../pages/DataEntry/MedicalHisotry/MedicalHistory.vue";

import store from '../../../Store/store'; // Import your Vuex store


export default [
  {
    path: "/DataEntry/Admission",
    name: "Admission",
    component: MainAdmission,
    beforeEnter: (to, from, next) => {
      store.dispatch('SetRSideActiveBtn', 'Admission');
      next();
    },
    children: [
      {
        path: "NewPatient",
        name: "NewPatient",
        component: NewPatient,
        // beforeEnter: (to, from, next) => {
        //   store.dispatch('setRSideActiveBtn', 'NewPatient');
        //   next();
        // },
      },
      {
        path: "",
        name: "Register",
        component: AdmissionForm,
        // beforeEnter: (to, from, next) => {
        //   store.dispatch('setRSideActiveBtn', 'Register');
        //   next();
        // },
      },
      { path: 'MedicalHistory', name: 'MedicalHistoryPage', component: MedicalHistory },

    ],
  },
];