<template>
  <div  class="content" v-if="hasAccess('SearchPatient')">
    <div class="input-block">
      <input type="number" required v-model="formData.NationalCode" />
      <span class="placeholder"> کد ملی </span>
      <span class="clear-icon" @click="clearInput('NationalCode')" v-show="formData.NationalCode">✖</span>
    </div>
    <div class="input-block">
      <input type="number" required v-model="formData.RecordCode" />
      <span class="placeholder"> کد پرونده </span>
      <span class="clear-icon" @click="clearInput('RecordCode')" v-show="formData.RecordCode">✖</span>
    </div>
    <div class="input-block" v-if="RSideActiveBtn != 'MedHistory' && RSideActiveBtn != 'MedicalRecord' ">
      <input type="number" required v-model="formData.AdmissionCode" />
      <span class="placeholder"> کد پذیرش </span>
      <span class="clear-icon" @click="clearInput('AdmissionCode')" v-show="formData.AdmissionCode">✖</span>
    </div>
    <div class="input-block">
      <input type="text" required v-model="formData.FirstName" />
      <span class="placeholder"> نام </span>
      <span class="clear-icon" @click="clearInput('FirstName')" v-show="formData.FirstName">✖</span>
    </div>
    <div class="input-block">
      <input type="text" required v-model="formData.LastName" />
      <span class="placeholder"> نام خانوادگی </span>
      <span class="clear-icon" @click="clearInput('LastName')" v-show="formData.LastName">✖</span>
    </div>

    <div class="input-block" v-if="RSideActiveBtn === 'Admission' || RSideActiveBtn === 'OphtVisit'">
      <input list="admission-types" required id="Admission-type" v-model="formData.AdmissionType" />
      <span class="placeholder"> پذیرش </span>
      <span class="clear-icon" @click="clearInput('AdmissionType')" v-show="formData.AdmissionType">✖</span>
      <datalist id="admission-types">
        <option value="سرپایی"></option>
        <option value="بستری"></option>
      </datalist>
    </div>

    <div class="input-block" v-if="RSideActiveBtn === 'Admission' || RSideActiveBtn === 'OphtVisit'">
      <input list="Admission-places" required id="Admission-place" v-model="formData.AdmissionPlace" />
      <span class="placeholder"> بخش </span>
      <span class="clear-icon" @click="clearInput('AdmissionPlace')" v-show="formData.AdmissionPlace">✖</span>
      <datalist id="Admission-places">
        <option value="درمانگاه"></option>
        <option value="NICU"></option>
      </datalist>
    </div>
    <div class="input-block" v-if="RSideActiveBtn === 'FollowUp'">
      <input list="Status-type" required id="Status" v-model="formData.Status" />
      <span class="placeholder"> نوبت </span>
      <span class="clear-icon" @click="clearInput('Status')" v-show="formData.Status">✖</span>
      <datalist id="Status-type">
        <option value="تعیین نشده"></option>
        <option value="تعیین شده"></option>
        <option value="لغو شده"></option>
      </datalist>
    </div>
    <div class="input-block" v-if="RSideActiveBtn === 'FollowUp'">
      <input list="audited-type" required id="Audited" v-model="formData.Audited" />
      <span class="placeholder"> پیگیری </span>
      <span class="clear-icon" @click="clearInput('Audited')" v-show="formData.Audited">✖</span>
      <datalist id="audited-type">
        <option value="پیگیری نشده"></option>
        <option value="پیگیری شده"></option>
        <option value="عدم پاسخ"></option>
        <option value="مشخصات اشتباه"></option>
        <option value="عدم مراجعه"></option>
      </datalist>
    </div>
    <div class="input-date" v-if="RSideActiveBtn != 'MedHistory' && RSideActiveBtn != 'MedicalRecord' ">
      
      <label>تاریخ:</label>
      <label class="form-check-label">امروز</label>
      <input
          class="form-check-input"
          type="radio"
          name="AdmissionTime"
          value="day"
          v-model="formData.AdmissionTime"
          @change="setAdmissionDate"
        />
        <label>هفته</label>
        <input
          class="form-check-input"
          type="radio"
          name="AdmissionTime"
          value="week"
          v-model="formData.AdmissionTime"
          @change="setAdmissionDate"
        />
      -
      <Persian-Date id="admission-date" v-model="AdmissionDateJalaali" popover @click="this.formData.AdmissionTime=''" />
    </div>
    <button type="button" class="btn btn-danger btn-sm resetBtn" @click="resetForm">انصراف</button>
  </div>
  <hr />
</template>

<script>
import { mapGetters } from 'vuex';
import jalaali from "jalaali-js";
import permissionMixin from "@/Mixin/permissionMixin";

export default {
  mixins:[permissionMixin],
  emits: ['emitPatientList', 'cancelSearch'],
  computed: {
    ...mapGetters(["RSideActiveBtn"]),
  },
  data() {
    return {
      formData: {   
        FirstName: null,
        LastName: null,
        NationalCode: null,
        RecordCode: null,
        AdmissionCode: null,
        AdmissionType: null,
        AdmissionPlace: null,
        Status: null,
        Audited: null,
        AdmissionDate: null,
        AdmissionTime: null,
      },
      AdmissionDateJalaali: null,
    };
  },
  methods: {
    fetchPatientList() {      
      this.$emit('emitPatientList', {...this.formData})
    },
    resetForm() {
      for (const key in this.formData) {
        if (Object.hasOwn(this.formData, key)) {
          this.formData[key] = null;
        }
      }
      this.AdmissionDateJalaali = '';
      this.$emit('cancelSearch');
    },

    clearInput(payload) {
      this.formData[payload] = null;
      this.fetchPatientList();
    },
   setAdmissionDate() {    
      if (this.formData.AdmissionTime === 'day') {
        const today = new Date();
        const jalaaliDate = jalaali.toJalaali(today);
        this.AdmissionDateJalaali = `${jalaaliDate.jy}-${jalaaliDate.jm}-${jalaaliDate.jd}`;
      } else if (this.formData.AdmissionTime === 'week') {
        const oneWeekBefore = new Date();
        oneWeekBefore.setDate(oneWeekBefore.getDate() - 7);
        const jalaaliDate = jalaali.toJalaali(oneWeekBefore);
        this.AdmissionDateJalaali = `${jalaaliDate.jy}-${jalaaliDate.jm}-${jalaaliDate.jd}`;
      } else {
        this.AdmissionDateJalaali = ''; 
      }
    },
  },

  watch: {
    "formData.NationalCode": {
      handler(newVal) {       
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    "formData.RecordCode": {
      handler(newVal) {       
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    "formData.AdmissionCode": {
      handler(newVal) {       
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    "formData.FirstName": {
      handler(newVal) {
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    "formData.LastName": {
      handler(newVal) {
        if (newVal || newVal === '')this.fetchPatientList()
      }
    },
    "formData.AdmissionType": {
      handler(newVal) {
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    "formData.AdmissionPlace": {
      handler(newVal) {
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    "formData.Status": {
      handler(newVal) {
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    "formData.Audited": {
      handler(newVal) {
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    "formData.AdmissionDate": {
      handler(newVal) {
        if (newVal || newVal === '') this.fetchPatientList()
      }
    },
    AdmissionDateJalaali: {
      handler(newVal) {
        if (newVal === '') {
          this.formData.AdmissionDate = null;
          this.formData.AdmissionTime = null;
          this.fetchPatientList();
        } else {
          const [jy, jm, jd] = newVal.split("-").map(Number);
          const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
          const formattedGregorianDate = `${gy}-${gm}-${gd}`;
          this.formData.AdmissionDate = formattedGregorianDate;
        }
      }
    }
  }
};
</script>
<style scoped>
.content {
  display: flex;
  justify-content: center;
  align-content: center;
}

.resetBtn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 10pt;
}

.input-block input {
  width: 110px !important;
}
.input-date {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 10px;
}
</style>