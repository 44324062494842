<template>
  <Message-Display ref="mesageComponent" />
  <div class="content slide-in">
    <merge-duplicates v-if="showMergModal" @closeModal="closeMergModal" class="merge-modal"/>
    <ReusableModal :isVisible="showModalBox" @confirm="deleteRecord" @cancel="cancelDelete" :Modaltext="Modaltext"> </ReusableModal>

    <Admission-Btns @AddNewPatient="ActiveNewPatientForm" :ShowNewPatientBtn="ShowNewPatientBtn" @showingMergModal="showingMergModal"></Admission-Btns>
    <hr v-if="ShowNewPatientBtn" />
    <Admission-List
      v-if="ShowPatientList"
      @ShowAdmissionForm="ActiveAddForm"
      @ShowEditAdmissionForm="ActiveAddForm"
      @ShowEditPatientInfoForm="ActiveNewPatientForm"
      @ShowMsgBox="showMsgBox"
      @ShowModal="ShowModal"
      :confirmDel="confirmDelete"
      @resetDelete="resetConfirmDel">
    </Admission-List>
    <new-patient v-if="ShowNewPatient" @ShowAdmission="ActiveAddForm" @showPateintlist="ActivePatientList"></new-patient>
    <Admission-form
      v-if="ShowAdmissionForm"
      @NewPatient="ActiveNewPatientForm"
      @ShowMedHistForm="ActiveMedHistoryForm"
      @showPateintlist="ActivePatientList"></Admission-form>
    <Medical-History v-if="ShowMedHistoryForm" @ShowAdmissionPatientList="ActivePatientList"></Medical-History>
  </div>
</template>

<script>
import MergeDuplicates from "./MergeDuplicates.vue"; 

import AdmissionForm from "./AdmissionForm.vue";
import NewPatient from "./NewPatient.vue";
import AdmissionList from "./admissionList.vue";
import AdmissionBtns from "./AdmissionBtns.vue";
import MedicalHistory from "../MedicalHisotry/MedicalHistory.vue";

import ReusableModal from "../../../components/UI/TheModal.vue";

export default {
  components: {
    MergeDuplicates,
    ReusableModal,
    AdmissionForm,
    NewPatient,
    AdmissionList,
    AdmissionBtns,
    MedicalHistory
  },
  data() {
    return {
      showMergModal:false,
      ShowNewPatient: false,
      ShowAdmissionForm: false,
      ShowPatientList: true,
      ShowNewPatientBtn: true,
      ShowMedHistoryForm: false,

      showModalBox: false,
      confirmDelete: false,
      isVisible: false,
      Modaltext: '',
    };
  },
  methods: {
    showingMergModal(){
      this.showMergModal=true;
    },
    closeMergModal() {
      this.showMergModal = false;
    },
    showMsgBox(payload) {
      if (payload == true) {
        this.$refs.mesageComponent.showMessage(204);
      } else {
        this.$refs.mesageComponent.showMessage(400);
      }
    },
    ActiveNewPatientForm() {
      this.ShowNewPatient = true;
      this.ShowAdmissionForm = false;
      this.ShowPatientList = false;
      this.ShowNewPatientBtn = false;
      this.ShowMedHistoryForm = false;
    },
    ActivePatientList() {
      this.ShowNewPatient = false;
      this.ShowAdmissionForm = false;
      this.ShowPatientList = true;
      this.ShowNewPatientBtn = true;
      this.ShowMedHistoryForm = false;
    },
    ActiveAddForm() {
      this.ShowPatientList = false;
      this.ShowAdmissionForm = true;
      this.ShowNewPatient = false;
      this.ShowNewPatientBtn = false;
      this.ShowMedHistoryForm = false;
    },
    ActiveMedHistoryForm() {
      this.ShowPatientList = false;
      this.ShowAdmissionForm = false;
      this.ShowNewPatient = false;
      this.ShowNewPatientBtn = false;
      this.ShowMedHistoryForm = true;
    },

    ShowModal(type) {
      if (type==='admission') this.Modaltext='اطلاعات پذیرش حذف خواهد شد!'
      else this.Modaltext='اطلاعات بیمار حذف خواهد شد!'
      this.showModalBox = true;

    },

    deleteRecord() {
      this.confirmDelete = true;
      this.showModalBox = false;
    },
    resetConfirmDel() {
      this.confirmDelete = false;
    },
    cancelDelete() {
      this.showModalBox = false;
    }
  }
};
</script>

<style scoped>
hr {
  margin: 5px;
}
.merge-modal {
  position: absolute;
  z-index: 9999;
}
</style>
