<template>
  <the-snippet v-if="showSnippet" />
  <div v-else class="body slide-in">
    <div class="d-lg-flex half">
      <div class="bg order-1 order-md-2 LeftImage"></div>
      <div class="contents order-2 order-md-1">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-8">
              <h4><strong>سامانه ثبت بیماری ROP </strong></h4>
              <!-- <p class="mb-4">مرکز آموزشی درمانی آیت اله موسوی | دانشگاه علوم پزشکی زنجان</p> -->
              <div>
                <div class="form-group first">
                  <label for="username">نام کاربری</label>
                  <input type="text" class="form-control" placeholder="نام کاربری خود را وارد کنید" id="username" v-model="username" />
                </div>
                <div class="form-group last mb-3">
                  <label for="password">رمز</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="رمز خود را وارد کنید"
                    id="password"
                    v-model="password"
                    @keydown.enter="login" 
                    @click="message=''"/>
                    
                </div>

                <!-- <div class="d-flex mb-4 ms-5 align-items-center">
                  <input type="checkbox" checked="checked" />
                  <span class="caption me-2">مرا به خاطر بسپار </span>
                  <div class="control__indicator"></div>
                  <span class="me-3"><a href="#" class="forgot-pass">فراموشی رمز</a></span>
                </div> -->
                <button value="ورود" class="btn btn-block btn-primary" @click="login">ورود</button>
                <p v-if="message">{{ message }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="login-footer">
      <p class="footer-font">&nbsp;تمامی حقوق محفوظ است&nbsp; <span style="color: #ffffff">&copy; 1403</span></p>
      <p class="footer-font">&nbsp;پشتیبانی: &nbsp; <span class="contact-info">&phone; 09124415396 - سلیمانی</span></p>
    </footer>
  </div>
</template>

<script>
import api from "../../Api/TheApi.js";
import { mapActions, mapGetters } from "vuex";


export default {
  data() {
    return {
      username: "",
      password: "",
      message: "",
      showSnippet: false,
    };
  },
  computed: {
    ...mapGetters(["authToken", "AccessLvl"])
  },
  methods: {
    ...mapActions(["setAuthToken", "setAccessLvl", "setUserName"]),
    async login() {
      this.showSnippet=true;
      try {
        const { status, data } = await api.post("/auth/jwt/create/", {
          username: this.username,
          password: this.password
        });
        if (status === 200) {
          const accessToken = data.access;
          const refreshToken = data.refresh;

          localStorage.setItem("refresh_token", refreshToken);

          await this.setAuthToken(accessToken);
          this.showSnippet=false;
          this.$router.push({ name: "Home" });
        } else {
          this.message = data.message;
        }
      } catch (error) {
        if (error.response) {
          this.showSnippet=false;
          this.message = "نام کاربری یا رمز عبور اشتباه است";
          // this.message = error.response.data.detail;
        } else {
          this.message = "An error occurred.";
        }
      }
    }
  }
};
</script>
<style scoped>
.body {
  background-image: url("../../assets/images/Login.png");
  width: 100vw !important;
  height: 100vh !important;
  background-size: contain;
  background-repeat: no-repeat;
}
strong {
  font-size: 25pt;
  font-weight: bolder;
  color: #1c0cf5;
}

.col-md-8 {
  margin-top: 25%;
  background: #f6f7fc;
  opacity: 95%;
}

p {
  color: #474747;
  font-weight: 400;
}

a {
  transition: 0.3s all ease;
}

a:hover {
  text-decoration: none !important;
}

.content {
  padding: 7rem 0;
}

h2 {
  font-size: 20px;
}

.half,
.half .container > .row {
  max-height: 50vh;
}

@media (max-width: 991.98px) {
  .half .bg {
    height: 200px;
  }
}

.half .contents,
.half .bg {
  width: 50%;
}

@media (max-width: 1199.98px) {
  .half .contents,
  .half .bg {
    width: 100%;
  }
}

.half .contents .form-control,
.half .bg .form-control {
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 54px;
  background: #fff;
}

.half .bg {
  background-size: cover;
  background-position: center;
}

.half a {
  color: #888;
  text-decoration: underline;
}

.half .btn {
  height: 40px;
  width: 70px;
  margin: 5px;
}

.login-footer {
  display: flex;
  position: absolute;
  bottom: 0;
  background-color:#3672B0;
  color: white;
  text-align: center;
  width: 100%;
  justify-content: space-between;
}
.footer-font {
  margin:15px;
  font-size: 12pt;
  font-weight: bold;
  color: #ffffff;
}
.contact-info {
  font-weight: bold;
  color:#55f1a3
}
.contact-info:hover {
  color:#ff0000
}

</style>
