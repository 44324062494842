<template>
  <Message-Display ref="messageComponent" />
  <the-snippet v-if="showSnippet" />

  <div v-else class="slide-in">
    <Patient-Info />
    <div>
      <div class="data-entry-form">
        <div class="section-lbl">
          <label>مداخلات NICU</label>
        </div>
        <div class="nicu-sections">
          <div class="data-section" >
            <div class="form-group">
              <label class="toggle-switchy" for="Ventilation" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="blue">
                <input type="checkbox" id="Ventilation" v-model="formData.Ventilation" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">Ventilation:</span>
              </label>
              <select v-if="formData.Ventilation" id="VentilationDays" v-model="formData.VentilationDays" @click="removeErrorMsg('VentilationTimes')">
                <option value="" disabled>روز</option>
                <option v-for="option in ItemDays" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <select v-if="formData.Ventilation" id="VentilationHours" v-model="formData.VentilationHours" @click="removeErrorMsg('VentilationTimes')">
                <option value="" disabled>ساعت</option>
                <option v-for="option in ItemHours" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="errors.VentilationTimes">{{ errors.VentilationTimes }}</span>
            </div>
            <div class="form-group">
              <label class="toggle-switchy" for="NCPAP" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="orange">
                <input type="checkbox" id="NCPAP" v-model="formData.NCPAP" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">NCPAP:</span>
              </label>
              <select v-if="formData.NCPAP" id="NCPAPDays" v-model="formData.NCPAPDays" @click="removeErrorMsg('NCPAPTimes')">
                <option value="" disabled>روز</option>
                <option v-for="option in ItemDays" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <select v-if="formData.NCPAP" id="NCPAPHours" v-model="formData.NCPAPHours" @click="removeErrorMsg('NCPAPTimes')">
                <option value="" disabled>ساعت</option>
                <option v-for="option in ItemHours" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="errors.NCPAPTimes">{{ errors.NCPAPTimes }}</span>
            </div>
            <div class="form-group">
              <label class="toggle-switchy" for="Oxyhood" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="green">
                <input type="checkbox" id="Oxyhood" v-model="formData.Oxyhood" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">Oxyhood:</span>
              </label>
              <select v-if="formData.Oxyhood" id="OxyhoodDays" v-model="formData.OxyhoodDays" @click="removeErrorMsg('OxyhoodTimes')">
                <option value="" disabled>روز</option>
                <option v-for="option in ItemDays" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <select v-if="formData.Oxyhood" id="OxyhoodHours" v-model="formData.OxyhoodHours" @click="removeErrorMsg('OxyhoodTimes')">
                <option value="" disabled>ساعت</option>
                <option v-for="option in ItemHours" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="errors.OxyhoodTimes">{{ errors.OxyhoodTimes }}</span>
            </div>
            
          </div>
          <div class="data-section" >
            <div class="form-group">
              <label class="toggle-switchy" for="O2-Incub" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="purple">
                <input type="checkbox" id="O2-Incub" v-model="formData.O2Incubation" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">O2-Incub:</span>
              </label>
              <select v-if="formData.O2Incubation" id="O2IncubationDays" v-model="formData.O2IncubationDays" @click="removeErrorMsg('O2IncubationTimes')">
                <option value="" disabled>روز</option>
                <option v-for="option in ItemDays" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <select v-if="formData.O2Incubation" id="O2IncubationHours" v-model="formData.O2IncubationHours" @click="removeErrorMsg('O2IncubationTimes')">
                <option value="" disabled>ساعت</option>
                <option v-for="option in ItemHours" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="errors.O2IncubationTimes">{{ errors.O2IncubationTimes }}</span>
            </div>
            <div class="form-group">
              <label class="toggle-switchy" for="HFNC" data-size="md" data-style="Rounded" data-text="true" data-label="left" data-color="gray">
                <input type="checkbox" id="HFNC" v-model="formData.HFNC" />
                <span class="toggle">
                  <span class="switch"></span>
                </span>
                <span class="label">HFNC:</span>
              </label>
              <select v-if="formData.HFNC" id="HFNCDays" v-model="formData.HFNCDays" @click="removeErrorMsg('HFNCTimes')">
                <option value="" disabled>روز</option>
                <option v-for="option in ItemDays" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <select v-if="formData.HFNC" id="HFNCHours" v-model="formData.HFNCHours" @click="removeErrorMsg('HFNCTimes')">
                <option value="" disabled>ساعت</option>
                <option v-for="option in ItemHours" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <span class="error-message" v-if="errors.HFNCTimes">{{ errors.HFNCTimes }}</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="data-section slide-in">
        <div class="section-lbl">
          <label>فرآورده های خونی:</label>
        </div>
        <div class="data-section">
          <div class="form-group">
            <label
              class="toggle-switchy"
              for="ّّFFPTransfusion"
              data-size="md"
              data-style="Rounded"
              data-text="true"
              data-label="left"
              data-color="red">
              <input type="checkbox" id="ّّFFPTransfusion" v-model="formData.FFPTransfusion" />
              <span class="toggle">
                <span class="switch"></span>
              </span>
              <span class="label">FFP:</span>
            </label>
            <select v-if="formData.FFPTransfusion" id="FFPTransfusion" v-model="formData.FFPTransfusionTimes" @click="removeErrorMsg('FFPTransfusionTimes')">
              <option value="" disabled hidden>تعداد</option>
              <option v-for="option in ItemTimes" :value="option.value" :key="option.value">
                {{ option.label }}
              </option>
            </select>
            <span class="error-message" v-if="errors.FFPTransfusionTimes">{{ errors.FFPTransfusionTimes }}</span>
          </div>
          <div class="form-group">
            <label
              class="toggle-switchy"
              for="plateletsTransfusion"
              data-size="md"
              data-style="Rounded"
              data-text="true"
              data-label="left"
              data-color="red">
              <input type="checkbox" id="plateletsTransfusion" v-model="formData.plateletsTransfusion" />
              <span class="toggle">
                <span class="switch"></span>
              </span>
              <span class="label">پلاکت:</span>
            </label>
            <select
              v-if="formData.plateletsTransfusion"
              id="plateletsTransfusion"
              v-model="formData.plateletsTransfusionTimes"
              @click="removeErrorMsg('plateletsTransfusionTimes')">
              <option value="" disabled hidden>تعداد</option>
              <option v-for="option in ItemTimes" :value="option.value" :key="option.value">
                {{ option.label }}
              </option>
            </select>
            <span class="error-message" v-if="errors.plateletsTransfusionTimes">{{ errors.plateletsTransfusionTimes }}</span>
          </div>
          <div class="form-group">
            <label
              class="toggle-switchy"
              for="PackcellTransfusion"
              data-size="md"
              data-style="Rounded"
              data-text="true"
              data-label="left"
              data-color="red">
              <input type="checkbox" id="PackcellTransfusion" v-model="formData.PackcellTransfusion" />
              <span class="toggle">
                <span class="switch"></span>
              </span>
              <span class="label">پکسل:</span>
            </label>
            <select
              v-if="formData.PackcellTransfusion"
              id="PackcellTransfusion"
              v-model="formData.PackcellTransfusionTimes"
              @click="removeErrorMsg('PackcellTransfusionTimes')">
              <option value="" disabled hidden>تعداد</option>
              <option v-for="option in ItemTimes" :value="option.value" :key="option.value">
                {{ option.label }}
              </option>
            </select>
            <span class="error-message" v-if="errors.PackcellTransfusionTimes">{{ errors.PackcellTransfusionTimes }}</span>
          </div>
        </div>
      </div>
      <hr />
      <div class="DataEntryButtons">
        <button type="submit-button" class="btn btn-success" v-if="showSubmitBtn" @click="submitForm">ثبت</button>
        <button type="button" v-if="showEditBtn && hasAccess('EditVisitNicu')" @click="editForm" class="btn btn-primary">ویرایش</button>
        <button type="button" @click="resetForm" class="btn btn-info">پاک کردن</button>
        <router-link :to="{ name: 'NICU' }" type="button" @click="PateintlistForm" class="btn btn-warning"> لیست بیماران </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../../Api/TheApi.js";
import permissionMixin from "@/Mixin/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    const createItemArray = (len) => Array.from({ length: len }, (v, i) => ({ label: (i + 1).toString(), value: (i + 1).toString() }));
    const initialFormData = () => ({
      Ventilation: false, VentilationDays: 0, VentilationHours: 0,
      Oxyhood: false, OxyhoodDays: 0, OxyhoodHours: 0,
      NCPAP: false, NCPAPDays: 0, NCPAPHours: 0,
      O2Incubation: false, O2IncubationDays: 0, O2IncubationHours: 0,
      HFNC: false, HFNCDays: 0, HFNCHours: 0,
      FFPTransfusion: false, FFPTransfusionTimes: 0,
      plateletsTransfusion: false, plateletsTransfusionTimes: 0,
      PackcellTransfusion: false, PackcellTransfusionTimes: 0,
      RegisterUser: null, UpdateUser: null
    });

    return {
      formData: initialFormData(),
      ItemDays: createItemArray(31), ItemHours: createItemArray(24), ItemTimes: createItemArray(15),
      showSubmitBtn: true, showEditBtn: false, errors: {}, errorsTimesMsg: "انتخاب نشده است", showSnippet: true,
    };
  },
  computed: {
    ...mapGetters(["RSideActiveBtn", "SelectedPatient", "userID"])
  },
  mounted() { this.fetchUserInfo(); },
  methods: {
    PateintlistForm() { this.$emit("ShowPatientListForm"); },
    async fetchUserInfo() {
      if (!this.SelectedPatient.admissions.AdmissionCode) return;
      this.resetForm();
      try {
        const response = await api.get(`nicu-detail/${this.SelectedPatient.admissions.AdmissionCode}/`);
        if (response.status === 200) {
          this.showSnippet = false;
          Object.assign(this.formData, response.data || {}); 
          this.showSubmitBtn = !response.data; 
          this.showEditBtn = !!response.data;
        }
      } catch {
        this.showSnippet = false;
        this.showSubmitBtn = true;
        this.showEditBtn = false;
      }
    },
    async submitForm() {
      if (this.validateForm()) {
        this.formData.RegisterUser = this.userID;
        this.handleFormSubmission('post', 201);
      }
    },
    async editForm() {
      if (this.validateForm()) {
        this.formData.UpdateUser = this.userID;
        this.handleFormSubmission('put', 200);
      }
    },
    async handleFormSubmission(method, successCode) {
      try {
        this.showSnippet = true;
        const { status } = await api[method](`nicu-detail/${this.SelectedPatient.admissions.AdmissionCode}/`, { ...this.formData });
        if (status === successCode) {this.$refs.messageComponent.showMessage(status); this.$router.push("/DataEntry/NICU");}
      } finally { this.showSnippet = false; }
    },
    resetForm() { Object.assign(this.formData, this.$options.data().formData); },
    validateForm() {
      this.errors = {};
      const interventions = ["Ventilation", "NCPAP", "Oxyhood", "O2Incubation", "HFNC"];
      interventions.forEach(i => {
        if (this.formData[i] && (!this.formData[`${i}Days`] && !this.formData[`${i}Hours`])) this.errors[`${i}Times`] = this.errorsTimesMsg;
      });
      ["FFPTransfusion", "plateletsTransfusion", "PackcellTransfusion"].forEach(i => {
        if (this.formData[i] && !this.formData[`${i}Times`]) this.errors[`${i}Times`] = this.errorsTimesMsg;
      });
      return Object.keys(this.errors).length === 0;
    },
    removeErrorMsg(field) { this.errors[field] = ""; },
    handleError(error) { this.$refs.messageComponent.showMessage(error?.response?.status); }
  },
  watch: {
    "SelectedPatient.admissions.AdmissionCode": "fetchUserInfo",
    "formData": {
      handler(newVal) {
        const resetValues = (key) => { if (!newVal[key]) newVal[`${key}Days`] = newVal[`${key}Hours`] = 0; };
        ["Ventilation", "Oxyhood", "NCPAP", "O2Incubation", "HFNC", "FFPTransfusion", "plateletsTransfusion", "PackcellTransfusion"].forEach(resetValues);
      }, deep: true
    }
  }
};
</script>


<style scoped>
hr {
  margin: 5px;
  color: blue;
}
.data-entry-form {
  display: flex;
  width: 90vw;
}
.data-section {
  display: flex;
  width: 100%;
  margin: 10px 0 10px 0;
}
.section-lbl {
  display: flex;
  flex-direction: column;
  min-width: 12%;
  justify-content: center;
  align-items: center;
  color: #0065fd;
  font-size: 10pt;
  font-weight: bold;
  border-left: 1px solid #ccc;
}
.nicu-sections{
  display: flex;
  flex-direction: column;
}

.RowDir {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 4px;
  justify-content: right;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
}

label {
  margin-right: 5px;
  word-wrap: break-word;
  text-align: left;
  font-size: 10pt;
}

input,
select {
  height: 30px;
  padding: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  background: #fff;
}

.DataEntryButtons {
  display: flex;
  margin-top: 5px;
}
.btn {
  width: 100px;
  padding: 3px;
  margin:5px
}
.toggle-switchy > .label {
  min-width: 70px !important;
  padding-left:5px;
}
.error-message {
  color: red;
  font-size: 10pt;
  font-weight: bold;
}

.errorBorder {
  border: 2px solid red;
}
</style>
